import React, { useState, useEffect } from "react";
import ModulesService from "../../services/ModulesService";
import withRouter from "../withRouter";
import ModuleItem from "./ModuleItem";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { courseTitle, updateCourse } from "../../reduxdata/courseSlice";
import "./ModuleItem.css";

const Module = (props) => {
  const [modules, setModules] = useState([]);
  const navigate = useNavigate();
  const { id: userid } = useParams();
  const dispatch = useDispatch();

  const[courseTitle, setCourseTitle]= useState()
  useEffect(() => {
    const token = localStorage.getItem("token");
    // console.log(token);

    ModulesService.getCourseModuleByCourseId(userid, token)
      .then((response) => response.json())
      .then((data) => {
        // console.log("module response", data);
        setCourseTitle(data?.course_title)
        // Save course_title to localStorage
        if (data?.course_title) {
          localStorage.setItem("course_title", data.course_title);
          // console.log("Course title saved to localStorage:", data.course_title);
        }

        setModules(data?.modules);
        // Store.dispatch(courseTitle({ course_title: data?.course_title }));
        // console.log("c", c, data.course_title);
      });
   
  }, [userid]);

  const handleCourseSelection = (course) => {
    dispatch(
      updateCourse({ courseId: course.course_id, updatedCourse: course })
    );
  };

  return (
    <div>
      <div className="container">
       
        <div className="d-flex align-items-center justify-content-between mb-5 mt-5">
          <button
            className="btn bk-adm back-button"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
         <div className="mt-0 mb-0 text-center flex-grow-1">
         <h4 >{courseTitle}</h4>
         <h5>Select Module to start the test</h5>
         </div>
        </div>

        <div className="table-responsive mt-3 mt-lg-3 mt-md-3">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Module</th>
                <th>Description</th>
                <th>{/* Start Test */}</th>
              </tr>
            </thead>
            <tbody>
              {modules?.map((mod, index) => (
                <ModuleItem
                  key={index}
                  module={mod}
                  keys={index + 1}
                  userid={userid}
                  history={props.history}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Module);
