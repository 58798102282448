// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spacer-row {
  height: 3rem; /* Adjust this value to set the desired space */
}

`, "",{"version":3,"sources":["webpack://./src/components/Resume/Page/Education.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,+CAA+C;AAC/D","sourcesContent":[".spacer-row {\r\n  height: 3rem; /* Adjust this value to set the desired space */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
