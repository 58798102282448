import { createSlice } from "@reduxjs/toolkit";

function loadInfo() {
  var user = localStorage.getItem("user");

  if (user != null) {
    return JSON.parse(user);

    // var token = JSON.parse(user).value.token
  } else {
    return {
      username: undefined,
      token: undefined,
      islogin: false,
      userId: 0,
      testid: 0,
      role: undefined,
      email_verified: false,
      email: undefined,
      contact: undefined,
    };
  }
}
export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: loadInfo(),
    resumeVal:
      { email: undefined, password: undefined } ||
      localStorage.getItem("resumeData"),
  },
  reducers: {
    changeData: (state, action) => {
      state.value = action.payload;
    },
    resumeData: (state, action) => {
      state.resumeVal = action.payload;
      localStorage.setItem("resumeData", JSON.stringify(action.payload));
    },
  },
});

export const { changeData, resumeData } = userSlice.actions;
export default userSlice.reducer;
