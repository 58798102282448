const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;

class ModulesService {
  constructor() {
    // Retrieve the token from localStorage or another source
    this.token = localStorage.getItem("token"); // Adjust as needed
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  getCourseModuleByCourseId(id, token) {
    return fetch(`https://backend.skilledfresher.in/api/courseModule/listBymodule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify({ id }),
    });
  }

  getModuleById(module_id, token) {
    return fetch(
      `https://backend.skilledfresher.in/api/module/getModuleById/${module_id}`,
      {
        // headers: this.getHeaders(),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + token,
        },
      }
    );
  }

  getAllModules(token) {
    return fetch(`https://backend.skilledfresher.in/api/module/list`, {
      // headers: this.getHeaders(),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }
}

export default new ModulesService();
