// import React, { useState, useEffect, createContext, useContext } from "react";

// // Create a Context for online status
// const OnlineStatusContext = createContext(false);

// export const StatusProvider = ({ children }) => {
//   const [isOnline, setIsOnline] = useState(navigator.onLine);

//   useEffect(() => {
//     // Event listeners to track online/offline status
//     const handleOnline = () => setIsOnline(true);
//     const handleOffline = () => setIsOnline(false);

//     window.addEventListener("online", handleOnline);
//     window.addEventListener("offline", handleOffline);

//     return () => {
//       window.removeEventListener("online", handleOnline);
//       window.removeEventListener("offline", handleOffline);
//     };
//   }, []);

//   return (
//     <OnlineStatusContext.Provider value={isOnline}>
//       <Status />
//       {children}
//     </OnlineStatusContext.Provider>
//   );
// };

// // Status component that renders the online/offline status
// const Status = () => {
//   const isOnline = useContext(OnlineStatusContext);
//   const [visible, setVisible] = useState(true);

//   useEffect(() => {
//     if (isOnline) {
//       // If online, hide the toast after 3 seconds
//       const timer = setTimeout(() => {
//         setVisible(false);
//       }, 3000); // Hide after 3 seconds

//       return () => clearTimeout(timer); // Clean up the timeout on unmount or status change
//     } else {
//       // If offline, keep the toast visible
//       setVisible(true);
//     }
//   }, [isOnline]); // Trigger effect when the online status changes

//   if (!visible) return null; // Don't render if not visible

//   return (
//     <div
//       style={{
//         zIndex: "9999",
//         position: "fixed",
//         top: "0", // Position at the top of the screen
//         left: "50%",
//         transform: "translateX(-50%)", // Center the toast horizontally
//         backgroundColor: isOnline ? "green" : "red",
//         color: "white",
//         padding: "10px 20px",
//         borderRadius: "25px",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//         fontWeight: "bold",
//         fontSize: "16px",
//         transition: "opacity 0.5s ease-in-out", // Smooth transition when hiding
//       }}
//     >
//       {isOnline ? "You are online" : "You are offline"}
//     </div>
//   );
// };

import React, { useState, useEffect, createContext, useContext } from "react";
import { FaWifi, FaTimesCircle } from "react-icons/fa";

import { RiWifiOffLine } from "react-icons/ri";

// Create a Context for online status
const OnlineStatusContext = createContext(false);

// StatusProvider Component
export const StatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Event listeners to track online/offline status
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      <Status />
      {children}
    </OnlineStatusContext.Provider>
  );
};

// Status Component
const Status = () => {
  const isOnline = useContext(OnlineStatusContext);
  const [visible, setVisible] = useState(isOnline);

  useEffect(() => {
    if (isOnline) {
      // Show the toast for 3 seconds when online
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timeout
    } else {
      // Always show when offline
      setVisible(true);
    }
  }, [isOnline]);

  if (!visible && isOnline) return null; // Hide online toast after 3 seconds

  return (
    <div
      style={{
        zIndex: "9999",
        position: "fixed",
        top: "12px", // Position near the top
        right: "10px", // Align to the top-right corner
        color: isOnline ? "green" : "red",
        backgroundColor: "white",
        padding: "10px 20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        fontWeight: "bold",
        fontSize: "16px",
        opacity: visible || !isOnline ? 1 : 0, // Show offline or online when visible
        transition: "opacity 0.5s ease-in-out", // Smooth fade-out transition
      }}
    >
      {isOnline ? (
        <>
          <FaWifi />
          &nbsp; You are online
        </>
      ) : (
        <>
          <RiWifiOffLine />
          &nbsp; You are offline
        </>
      )}
    </div>
  );
};

// App Component (for demonstration)
const App = () => {
  return (
    <StatusProvider>
      <div style={{ padding: "20px" }}>
        <h1>React Online/Offline Status Toast</h1>
        <p>Toggle your network connection to see the toast notifications.</p>
      </div>
    </StatusProvider>
  );
};

export default App;
