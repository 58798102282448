import React, { useEffect, useState } from "react";
import "./UseTemp7.css";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaSquare,
  FaPhone,
  FaEnvelope,
  FaMapMarker,
  FaLinkedin,
  FaGithub,
} from "react-icons/fa";
import html2canvas from "html2canvas";
import jspdf, { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";

const UseTemp7 = () => {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));
  const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;

  const downloadPDF = () => {
    const input = document.getElementById("wrapper");

    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // A4 size in mm (210 x 297 mm)

      const pdfWidth = pdf.internal.pageSize.getWidth(); // A4 width: 210 mm
      const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 height: 297 mm
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate the scaling ratio based on the A4 page width
      const scaleRatio = pdfWidth / canvasWidth;

      // Calculate the scaled content height
      const scaledHeight = canvasHeight * scaleRatio;

      // Start with the yOffset for the first page
      let yOffset = 0;

      // If the content exceeds the page height, it will be split across multiple pages
      while (yOffset < scaledHeight) {
        // Create a temporary canvas for the current page slice
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(
          canvasHeight - yOffset,
          pdfHeight / scaleRatio
        );

        const context = pageCanvas.getContext("2d");
        context.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add the image to the PDF (scale to fit the page)
        pdf.addImage(
          pageData,
          "PNG",
          0,
          0,
          pdfWidth,
          (pageCanvas.height * pdfWidth) / canvas.width
        );
        yOffset += pageCanvas.height;

        // If there is more content, add a new page
        if (yOffset < scaledHeight) {
          pdf.addPage();
        }
      }

      // Save the generated PDF with the name 'profile.pdf'
      pdf.save("profile.pdf");
    });
  };

  return (
    <>
      <div className="container">
        {/* <button className="btn  mx-3 db7" onClick={downloadPDF}>
        Download as PDF
      </button> */}
        <div className="button-container7 row">
          <div className="col-12 col-sm-12 col-md-6">
            <button
              className="btn bk7 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <button className="btn  db7 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>

        <div className="scroll-container7">
          <div className="profile-container7" id="profile-7">
            <div className="mainbox7 mt-5">
              <div className="sleft">
                <div
                  className="col-md-8 my-4 main7 offset-md-2 col-8 offset"
                  id="wrapper"
                >
                  <div id="header7">
                    <div>
                      <img
                        src={
                          `https://backend.skilledfresher.in/upload/user_image/${candidate?.candidates[0]?.image}` ||
                          "/path/to/default-image.png"
                        }
                        alt="Candidate"
                        id="image7"
                      />
                    </div>
                    <div>
                      <h2 id="name" className="name7">
                        {candidate?.name}
                      </h2>
                      <p id="underline7" className="pt7"></p>
                      <h5 id="web7">{candidate?.candidates[0]?.job_title}</h5>
                    </div>
                    <div className="contactdetail7">
                      <div>
                        <FaPhone /> <span>{candidate?.contact}</span>
                      </div>
        <div className="d-flex flex-column flex-sm-row align-items-center">
           <FaEnvelope />
           <span className="text-break ms-2">{candidate?.email}</span>
          </div>

                    </div>
                  </div>
                  <div className="row">
                    {candidate?.candidates[0]?.objective && (
                      <div className="col-md-12 col-sm-12 col-12">
                        <h4 id="exp7">Career Objective</h4>
                        <p className="pt7">
                          {candidate?.candidates[0]?.objective}
                        </p>
                      </div>
                    )}
                  </div>
                  {candidate?.experience?.length > 0 && (
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <h4 id="exp7">Experience</h4>
                        {candidate?.experience?.map((item, index) => (
                          <div key={index} id="experience">
                            <div id="exp17">
                              <FaSquare className="me-2" />
                              <b className="me-1">Designation:</b>
                              <span id="head">{item?.designation}</span>
                            </div>
                            <div id="exp17">
                              <FaSquare className="me-2" />
                              <b className="me-1">Company Name:</b>
                              <span id="head">{item?.company_name}</span>
                            </div>
                            <div id="exp17 ">
                              <FaSquare className="me-2" />
                              <b className="me-1">Description:</b>
                              <span id="head" className="">
                                {item?.description}
                              </span>
                            </div>
                            <div id="exp17">
                              <FaSquare className="me-2" />
                              <b className="me-1">Duration:</b>
                              <span id="head">
                                {" "}
                                {item.start_date} to{" "}
                                {item?.end_date || "Present"}{" "}
                              </span>
                              <hr className="hr-4" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {candidate?.project?.length > 0 && (
                    <div className="row mt-3">
                      <div className="col-md-12 col-sm-12 col-12">
                        <h4 id="exp7">Project</h4>
                        {candidate?.project?.map((item, index) => (
                          <div key={index} id="experience">
                            <div id="exp17">
                              <FaSquare className="mr-2" />
                              <span id="head">
                                {" "}
                                <b>Project Title:</b> {item?.project_title}
                              </span>
                              <div className="mt-2">
                                <p className="pt7">
                                  <FaSquare className="mr-2" />
                                  <b className="bold7"> Skills Apply: </b>{" "}
                                  <span id="head">{item?.skill_apply}</span>
                                </p>
                                <p className="pt7">
                                  <FaSquare className="mr-2" />
                                  <b className="bold7">
                                    {" "}
                                    Duration in month:
                                  </b>{" "}
                                  <span id="head">
                                    {" "}
                                    {item?.duration_in_month}
                                  </span>
                                </p>
                                <p className="pt7">
                                  <FaSquare className="mr-2" />
                                  <b className="bold7"> Description:</b>{" "}
                                  <span id="head"> {item?.description}</span>
                                </p>
                                <hr className="hr-4" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {candidate?.educational?.length > 0 && (
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12">
                        <h4 id="exp7">Education</h4>
                        {candidate?.educational?.map((item, index) => (
                          <div key={index} id="experience">
                            <div id="exp17">
                              <FaSquare className="mr-2" />
                              <span id="head">
                                {" "}
                                <b>Institute/University: </b>{" "}
                                {item?.institute_name}/{" "}
                                {item?.board_or_university}
                              </span>
                              <div className="mt-2">
                                <p className="pt7">
                                  <FaSquare className="mr-2" />

                                  <b className="bold7"> Percentage/Grade: </b>
                                  {item?.percentage_or_grade}
                                </p>
                                <p className="pt7">
                                  <FaSquare className="mr-2" />
                                  <b className="bold7"> Passing Year:</b>{" "}
                                  {item?.passing_year}
                                </p>
                                <hr className="hr-4" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="row ">
                    {candidate?.achievement?.length > 0 && (
                      <div className="col-md-6 col-sm-6 col-6">
                        <h4 id="contact7">Achievements</h4>
                        {candidate?.achievement?.map((item, index) => (
                          <div key={index} className="mb-2 mt-2">
                            <FaSquare className="mr-2" />{" "}
                            <span id="space">
                              <b className="me-1">Title:</b>
                              {item?.title}
                            </span>
                            <div>
                              <FaSquare className="mr-2" />{" "}
                              <span id="space">
                                <b className="me-1">Description:</b>

                                {item?.description}
                              </span>
                            </div>
                            <hr className="hr-4" />
                          </div>
                        ))}
                      </div>
                    )}
                    {candidate?.candidates[0]?.skills && (
                      <div className="col-md-6 col-sm-6 col-6">
                        <h4 id="skills7">Skills</h4>
                        <div className="skills7">
                          <div>
                            <p className="pt7">
                              {candidate?.candidates[0]?.skills}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    {candidate?.candidates[0]?.language_known && (
                      <div className="col-md-6 col-sm-6 col-6">
                        <h4 id="contact7">Language</h4>
                        <p className="pt7 mt-1">
                          {candidate?.candidates[0]?.language_known}
                        </p>
                      </div>
                    )}
                    {candidate?.candidates[0]?.interest && (
                      <div className="col-md-6 col-sm-6 col-6">
                        <h4 id="skills7">Hobby</h4>
                        <p className="pt7 mt-1">
                          {candidate?.candidates[0]?.interest}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="row mb-5">
                    <div className="col-md-6 col-sm-6 col-6">
                      <h4 id="contact7">Contact</h4>
                      <div className="mb-2 mt-2">
                        <FaPhone />{" "}
                        <span id="space" className="ms-2">
                          {candidate?.contact}
                        </span>
                      </div>
                      <div className="mb-2 mt-2">
                        <FaEnvelope />{" "}
                        <span id="space" className="ms-2">
                          {candidate?.email}
                        </span>
                      </div>
                      <div className="mb-2 mt-2">
                        <FaMapMarker />{" "}
                        <span id="space" className="ms-2">
                          {candidate?.candidates[0]?.address}
                        </span>
                      </div>
                      {candidate?.candidates[0]?.linkedin_profile && (
                        <div className="mb-2 mt-2">
                          <FaLinkedin />{" "}
                          <span id="space" className="ms-2">
                            {candidate?.candidates[0]?.linkedin_profile}
                          </span>
                        </div>
                      )}
                      {candidate?.candidates[0]?.github_profile && (
                        <div className="mb-2 mt-2">
                          <FaGithub />{" "}
                          <span id="space" className="ms-2">
                            {candidate?.candidates[0]?.github_profile}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <h4 id="contact7">Personal Detail</h4>
                      <div className="mb-2 mt-2 ">
                        <b className="me-1">Name:</b> {candidate?.name}
                      </div>
                      {candidate?.candidates[0]?.father_name && (
                        <div className="mb-2 mt-2">
                          <b className="me-1">Father's Name:</b>{" "}
                          {candidate?.candidates[0]?.father_name}
                        </div>
                      )}
                      {candidate?.candidates[0]?.date_of_birth && (
                        <div className="mb-2 mt-2">
                          <b className="me-1">DOB:</b>{" "}
                          {candidate?.candidates[0]?.date_of_birth}
                        </div>
                      )}
                      {candidate?.candidates[0]?.gender && (
                        <div className="mb-2 mt-2">
                          <b className="me-1">Gender:</b>{" "}
                          {candidate?.candidates[0]?.gender}
                        </div>
                      )}
                      {candidate?.candidates[0]?.marital_status && (
                        <div className="mb-2 mt-2">
                          <b className="me-1">Marital Status:</b>
                          {candidate?.candidates[0]?.marital_status}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="footer4 mt-4">
                    <p className="footer-text4">
                      Powered By{" "}
                      <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-link4"
                      >
                        skillfresher.in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseTemp7;
