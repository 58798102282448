const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER

class AdminService {
  constructor() {
    // Retrieve the token from localStorage or another source
    this.token = localStorage.getItem("token"); // Adjust as needed
  }

  

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  addCourse(course_title, description, active_status, token) {
    const data = {
      course_title,
      description,
      active_status,
    };
    return fetch(`https://backend.skilledfresher.in/api/course/save`, {
      method: "POST",
      // headers: this.getHeaders(),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(data),
    });
  }

  addModule(module_title, description, active_status, token) {
    const data = {
      module_title,
      description,
      active_status,
    };
    return fetch(`https://backend.skilledfresher.in/api/module/save`, {
      method: "POST",
      // headers: this.getHeaders(),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  }

  addCoursesModules(course, module, token) {
    const data = {
      course,
      module,
    };
    return fetch(`https://backend.skilledfresher.in/api/courseModule/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  }

  addQuestion(data, token) {
    return fetch(`https://backend.skilledfresher.in/api/question/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(data),
    });
  }

  getAllUser(token) {
    return fetch(`https://backend.skilledfresher.in/api/user/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }

  deleteCourse(course_id, token) {
    return fetch(`https://backend.skilledfresher.in/api/course/delete/${course_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deleteModule(module_id) {
    return fetch(`https://backend.skilledfresher.in/api/module/delete/${module_id}`, {
      method: "DELETE",
      headers: this.getHeaders(),
    });
  }

  deletecourseModule(course_module_id) {
    return fetch(
      `https://backend.skilledfresher.in/api/courseModule/delete/${course_module_id}`,
      {
        method: "DELETE",
        headers: this.getHeaders(),
      }
    );
  }

  deleteQues(ques_id) {
    return fetch(`https://backend.skilledfresher.in/api/question/delete/${ques_id}`, {
      method: "DELETE",
      headers: this.getHeaders(),
    });
  }

  dleteUser(userid, token) {
    return fetch(`https://backend.skilledfresher.in/api/delete/${userid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }

  updateCourse(course_id, updatedCourse) {
    return fetch(`https://backend.skilledfresher.in/api/course/update/${course_id}`, {
      method: "PUT",
      body: JSON.stringify(updatedCourse),
      headers: this.getHeaders(),
    });
  }

  updateModule(module_id, updatedModule) {
    return fetch(`https://backend.skilledfresher.in/api/module/update/${module_id}`, {
      method: "PUT",
      body: JSON.stringify(updatedModule),
      headers: this.getHeaders(),
    });
  }

  updateCourseModule(course_module_id, updatedCM) {
    return fetch(
      `https://backend.skilledfresher.in/api/coursemodule/update/${course_module_id}`,
      {
        method: "PUT",
        body: JSON.stringify(updatedCM),
        headers: this.getHeaders(),
      }
    );
  }

  updateQuestion(ques_id, updatedQuestions) {
    return fetch(`https://backend.skilledfresher.in/api/question/update/${ques_id}`, {
      method: "PUT",
      body: JSON.stringify(updatedQuestions),
      headers: this.getHeaders(),
    });
  }

  updateUserRole(updatedrole,userid, token) {
    return fetch(`https://backend.skilledfresher.in/api/users/block/${userid}`, {
      method: "PUT",
      body: JSON.stringify(updatedrole),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }

  searchUser(query, token) {
    return fetch(`https://backend.skilledfresher.in/api/search?q=${query}`, {
      // headers: this.getHeaders(),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
  }
}

export default new AdminService();
