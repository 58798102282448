import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import "./Education.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for toast notifications

function Education() {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [educationList, setEducationList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [educationId, setEducationId] = useState(undefined);

  const educationRef = useRef();
  const instituteRef = useRef();
  const passingYearRef = useRef();
  const boardUniversityRef = useRef();
  const gradePercentageRef = useRef();
  const formRef = useRef(null);
  const messageRef = useRef(null);
  const lastRecordRef = useRef(null);

  const fetchEducationList = async () => {
    try {
      setLoading(true);
      const fetchUrl = `${urls.Education_list}`;

      const response = await ResumeService.GetApiCallWithToken(
        fetchUrl,
        logindata.token
      );
      if (
        Array.isArray(response?.data?.education) &&
        response?.data?.education?.length > 0
      ) {
        setEducationId(response?.data?.education[0]?.education_id);
      } else {
      }

      if (response?.data?.education) {
        setEducationList(response?.data?.education);
      } else {
      }
    } catch (error) {
      setMsg("Error fetching education data. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchEducationList();
  }, [logindata.token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const errorsCopy = { ...formErrors }; // Create a copy of errors to update

    // Clear the error for the corresponding field if valid
    if (value.trim()) {
      delete errorsCopy[name];
    } else {
      errorsCopy[name] = `${name} field is required`;
    }

    setFormErrors(errorsCopy); // Update the formErrors state
  };

  const handleEducationSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    const educationDetails = {
      person: logindata.userId,
      education_name: educationRef.current.value.trim(),
      institute_name: instituteRef.current.value.trim(),
      passing_year: passingYearRef.current.value.trim(),
      board_or_university: boardUniversityRef.current.value.trim(),
      percentage_or_grade: gradePercentageRef.current.value.trim(),
    };
    if (!educationDetails.education_name)
      errors.education_name = "Education field is required";
    if (!educationDetails.institute_name)
      errors.institute_name = "Institute field is required";
    if (!educationDetails.passing_year)
      errors.passing_year = "Passing Year field is required";
    if (!educationDetails.board_or_university)
      errors.board_or_university = "Board/University field is required";
    if (!educationDetails.percentage_or_grade)
      errors.percentage_or_grade = "Grade/Percentage field is required";

    // Get input values
    const education = educationRef.current.value.trim();
    const institute = instituteRef.current.value.trim();
    const passingYear = passingYearRef.current.value.trim();
    const boardUniversity = boardUniversityRef.current.value.trim();
    const gradePercentage = gradePercentageRef.current.value.trim();

    // Validation logic
    if (!education) {
      errors.education_name = "Education field is required.";
    }
    if (!institute) {
      errors.institute_name = "Institute field is required.";
    }

    if (!passingYear) {
      errors.passing_year = "Passing year is required.";
    } else if (
      passingYear !== "Running" &&
      !/^(19|20)\d{2}$/.test(passingYear)
    ) {
      errors.passing_year =
        "Please enter a valid year (e.g., 2024) or 'Running'.";
    } else if (
      passingYear !== "Running" &&
      parseInt(passingYear, 10) > new Date().getFullYear()
    ) {
      errors.passing_year = "Passing year cannot be in the future.";
    }

    if (!boardUniversity) {
      errors.board_or_university = "Board/University field is required.";
    } else if (/[\d]/.test(boardUniversity)) {
      errors.board_or_university =
        "Board/University field should not contain digits.";
    }
    if (!gradePercentage) {
      errors.percentage_or_grade = "Grade/Percentage field is required.";
    } else if (
      !/^\d{1,3}(\.\d{1,2})?$/.test(gradePercentage) && // Percentage pattern
      !/^[A-F][+-]?$/.test(gradePercentage) // Grade pattern
    ) {
      errors.percentage_or_grade =
        "Enter a valid percentage (e.g., 75) or grade (e.g., A+).";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      // handleEducationSubmit(educationDetails);
      toast.dismiss();
      setLoading(true);
      try {
        let response;

        let updatedData = [...educationList];

        if (isEditMode && editIndex !== null) {
          response = await ResumeService.PutApiCall(
            `${urls.Update_Education}/${educationId}`,
            educationDetails,
            logindata.token
          );

          fetchEducationList();
          updatedData[editIndex] = { ...response.data.education };
        } else {
          response = await ResumeService.PostApiCallWithToken(
            urls.Add_Education,
            educationDetails,
            logindata.token
          );
          updatedData = [...updatedData, response?.data?.education];
        }
        setEducationList(updatedData);
        fetchEducationList();
        toast.success(
          response.data.message || "Education details saved successfully."
        );

        educationRef.current.value = "";
        instituteRef.current.value = "";
        passingYearRef.current.value = "";
        boardUniversityRef.current.value = "";
        gradePercentageRef.current.value = "";

        localStorage.setItem("educationData", JSON.stringify(updatedData));

        setIsEditMode(false);
        setEditIndex(null);

        setShowForm(false);
        setTimeout(() => {
          setShowForm(true);
        }, 2000);
      } catch (error) {
      } finally {
        setLoading(false);
        setIsEditMode(false);
        setEditIndex(null);
        setFormErrors({});
        setIsFormSubmitted(true);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleEdit = (index) => {
    setIsEditMode(true);
    setEditIndex(index);
    const selectedEducation = educationList[index];
    setEducationId(selectedEducation.education_id);
    educationRef.current.value = selectedEducation.education_name;
    instituteRef.current.value = selectedEducation.institute_name;
    passingYearRef.current.value = selectedEducation.passing_year;
    boardUniversityRef.current.value = selectedEducation.board_or_university;
    gradePercentageRef.current.value = selectedEducation.percentage_or_grade;
  };

  const handleDelete = async (educationId) => {
    if (!educationId) {
      return;
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this education entry?"
    );

    if (isConfirmed) {
      try {
        toast.dismiss();
        setLoading(true);
        const deleteUrl = `${urls.Del_Education}/${educationId}`;

        const response = await ResumeService.DelApiCall(
          deleteUrl,
          logindata.token
        );

        if (response?.status === 200) {
          const updatedList = educationList.filter(
            (education) => education.education_id !== educationId
          );
          setEducationList(updatedList);
          localStorage.setItem("educationData", JSON.stringify(updatedList));

          toast.success(response.data.message);
          setTimeout(() => setMsg(""), 2000);

          // Reset form fields and states after deletion
          educationRef.current.value = "";
          instituteRef.current.value = "";
          passingYearRef.current.value = "";
          boardUniversityRef.current.value = "";
          gradePercentageRef.current.value = "";
          setIsEditMode(false);
          setEditIndex(null);
          setFormErrors({});

          if (messageRef.current) {
            messageRef.current.scrollIntoView({ behavior: "smooth" });
          }
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error("Error deleting education data.");
      } finally {
        setLoading(false);
      }
    }
  };
  const handleCancel = () => {
    // Reset form fields
    educationRef.current.value = "";
    instituteRef.current.value = "";
    passingYearRef.current.value = "";
    boardUniversityRef.current.value = "";
    gradePercentageRef.current.value = "";

    // Exit edit mode
    setIsEditMode(false);
    setEditIndex(null);
    setFormErrors({});
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-2 col-sm-2 col-md-4 mb-3">
            <Sidebar />
          </div>
          <div className="col-lg-8 col-8 col-sm-8 offset-3  col-md-10">
            <div className="row">
              {/* Table Section */}
              {educationList.length > 0 && (
                <div className="col-lg-5 col-md-5">
                  <div className="border border-success rounded p-4 bg-light shadow">
                    {/* <h2 className="text-center">Saved Education</h2> */}
                    <div className="table-responsive table-responsive-resume">
                      <table className="table table-striped">
                        <tbody>
                          {educationList.map((education, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <th>Education</th>
                                <td>{education.education_name}</td>
                              </tr>
                              <tr>
                                <th>Institute</th>
                                <td>{education.institute_name}</td>
                              </tr>
                              <tr>
                                <th>Passing Year</th>
                                <td>{education.passing_year}</td>
                              </tr>
                              <tr>
                                <th>Board/University</th>
                                <td>{education.board_or_university}</td>
                              </tr>
                              <tr>
                                <th>Grade/Percentage</th>
                                <td>{education.percentage_or_grade}</td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="d-flex justify-content-between">
                                    <button
                                      type="button"
                                      className="btn btn-md btn-danger  mt-3"
                                      onClick={() =>
                                        handleDelete(education?.education_id)
                                      }
                                    >
                                      Delete
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-md btn-success mt-3"
                                      onClick={() => handleEdit(index)}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </td>
                              </tr>

                              <tr
                                className="spacer-row"
                                ref={
                                  index === educationList.length - 1
                                    ? lastRecordRef
                                    : null
                                }
                              />
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {/* Form Section */}
              <div 
              // className="col-lg-6 col-md-5 offset-lg-1 mi"
              className={`col-lg-${educationList.length === 0 ? "6" : "6"} col-md-${educationList.length === 0 ? "6" : "5"} offset-lg-${educationList.length === 0 ? "3" : "1"} mi`}
              >
                <div
                  className="border border-success rounded p-4 bg-light shadow mb-4"
                  ref={formRef}
                >
                  <h3 className="text-center">
                    {isEditMode ? "Update Education" : "Add New Education"}
                  </h3>
                  <hr />

                  <form onSubmit={handleEducationSubmit}>
                    <div className="form-group">
                      <label htmlFor="education">
                        Education:<span className="text-danger">*</span>
                      </label>
                      <input
                        name="education"
                        type="text"
                        ref={educationRef}
                        className={`form-control form-control-bg ${
                          formErrors.education_name ? "is-invalid" : ""
                        }`}
                        placeholder="ex. - B.Tech"
                        onChange={handleInputChange}
                      />
                      {formErrors.education_name && (
                        <div className="invalid-feedback">
                          {formErrors.education_name}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="institute">
                        Institute:<span className="text-danger">*</span>
                      </label>
                      <input
                        name="institute"
                        type="text"
                        ref={instituteRef}
                        className={`form-control form-control-bg ${
                          formErrors.institute_name ? "is-invalid" : ""
                        }`}
                        placeholder="ex. - Medicaps Institite of Technology Indore"
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.institute_name && (
                        <div className="invalid-feedback">
                          {formErrors.institute_name}
                        </div>
                      )}
                    </div>

                    <div className="form-group position-relative">
                      <label htmlFor="passing_year">
                        Passing Year:<span className="text-danger">*</span>
                      </label>
                      <input
                        list="yearList"
                        name="passing_year"
                        id="passing_year"
                        ref={passingYearRef}
                        className={`form-control form-control-bg ${
                          formErrors.passing_year ? "is-invalid" : ""
                        }`}
                        placeholder="Select Year"
                        onChange={handleInputChange}
                        required
                      />
                      <datalist id="yearList">
                        <option value="" disabled>
                          Select Passing Year
                        </option>
                        <option value="Running">Running</option>
                        {/* Generate a list of years from 1900 to 2099 */}
                        {Array.from(
                          { length: 200 },
                          (_, index) => 1900 + index
                        ).map((year) => (
                          <option key={year} value={year} />
                        ))}
                      </datalist>
                      {formErrors.passing_year && (
                        <div className="invalid-feedback">
                          {formErrors.passing_year}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="board_university">
                        Board/University:<span className="text-danger">*</span>
                      </label>
                      <input
                        name="board_university"
                        type="text"
                        ref={boardUniversityRef}
                        className={`form-control form-control-bg ${
                          formErrors.board_or_university ? "is-invalid" : ""
                        }`}
                        placeholder="ex. - RGPV"
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.board_or_university && (
                        <div className="invalid-feedback">
                          {formErrors.board_or_university}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="grade_percentage">
                        Grade/Percentage:<span className="text-danger">*</span>
                      </label>
                      <input
                        name="percentage_or_grade"
                        type="text"
                        ref={gradePercentageRef}
                        className={`form-control form-control-bg ${
                          formErrors.percentage_or_grade ? "is-invalid" : ""
                        }`}
                        placeholder="e.g., 75% or A+"
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.percentage_or_grade && (
                        <div className="invalid-feedback">
                          {formErrors.percentage_or_grade}
                        </div>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="btn  btn-md btn-success mt-3"
                    >
                      {isEditMode ? "Update" : "Save"}
                    </button>
                    {isEditMode && (
                      <button
                        type="button"
                        className="btn btn-secondary mt-3 ms-2"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Education;
