// import React, { useState, useEffect } from "react";
// import { useNetworkStatus } from "../components/NetworkContext";
// import { toast } from "react-toastify"; // Import toast function
// import "react-toastify/dist/ReactToastify.css";

// const GlobalMessage = () => {
//   const { networkErrorMessage } = useNetworkStatus();
//   // const [isPromptVisible, setIsPromptVisible] = useState(true);
//   const [isToastVisible, setIsToastVisible] = useState(false);

//   useEffect(() => {
//     if (networkErrorMessage) {
//       const timer = setTimeout(() => {
//         // setIsPromptVisible(false);  // Automatically close the prompt after 5 seconds
//         setIsToastVisible(true);
//       }, 10000); // 5000 milliseconds = 5 seconds

//       return () => clearTimeout(timer); // Clear timer on component unmount
//     }
//   }, [networkErrorMessage]);

//   const handleClose = () => {
//     // setIsPromptVisible(false);
//     setIsToastVisible(false);
//   };

//   return (
//     networkErrorMessage &&
//     isToastVisible && (
//       <div className="prompt-box">
//         <div>
//           <p>{networkErrorMessage}</p>
//         </div>
//       </div>
//     )
//   );
// };

// export default GlobalMessage;

import React, { useEffect } from "react";
import { useNetworkStatus } from "../components/NetworkContext";
import { toast } from "react-toastify"; // Import toast function
import "react-toastify/dist/ReactToastify.css"; // Import the default toast styles

const GlobalMessage = () => {
  const { networkErrorMessage } = useNetworkStatus(); // Assuming you are getting networkErrorMessage from context

  useEffect(() => {
    if (networkErrorMessage) {
      // Show a toast notification when networkErrorMessage is available
      toast.error(networkErrorMessage, {
        position: "top-center", // Customize position
        autoClose: 15000, // Automatically close the toast after 10 seconds
        hideProgressBar: true, // Hide the progress bar
        closeOnClick: true, // Allow closing the toast when clicked
        pauseOnHover: true, // Pause on hover
        draggable: true, // Allow the toast to be draggable
      });
    }
  }, [networkErrorMessage]); // Effect runs when the networkErrorMessage changes

  return null; // No need for JSX to render the toast itself, as react-toastify handles this globally
};

export default GlobalMessage;
