import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom"; // Import useLocation to access the current route
import CourseItem from "./CourseItem";
import CoursesService from "../../services/CoursesService";
import "./CourseItem.css";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    CoursesService.getAllCourses()
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setCourses(data?.course?.course);
        setIsLogin(true);
      })
      .catch((err) => {
        // console.error(err);
      });
  }, []);

  // Use useLocation to determine the current path
  const location = useLocation();
  const isNestedRoute =
    location.pathname.startsWith("/course/") && location.pathname !== "/course"; // Check if we are on a nested route

  // If we are on a nested route, don't show the Courses component content
  if (isNestedRoute) {
    return (
      <>
        {/* Only nested route content should render here */}
        <Outlet />
      </>
    );
  }

  // If not on a nested route, render the main Courses component content
  return (
    <div>
      <div className="container mt-lg-5">
        <div className="text-center mt-5 mb-5">
          <h3>Select Your Skill</h3>
        </div>
        <div className="row">
          {courses?.map((cour, index) => (
            <CourseItem key={index} course={cour} />
          ))}
        </div>
      </div>

      {/* Nested route content will render here */}
      <Outlet />
    </div>
  );
};

export default Courses;
