import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useDispatch, useSelector } from "react-redux";
import "./Information.css";
import { setProfileImage } from "../../../reduxdata/profileSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { FaUserCircle } from "react-icons/fa";

const ShowInfo = () => {
const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;

  const { userId } = useParams();
  const logindata = useSelector((state) => state.user.value);

  const [formData, setFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [infoform, setInfoForm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = useState({});

  const today = new Date().toISOString().split("T")[0];
  // Calculate date 15 years ago
  const maxDateForDOB = new Date();
  maxDateForDOB.setFullYear(maxDateForDOB.getFullYear() - 18);
  const maxDOB = maxDateForDOB.toISOString().split("T")[0];

  const [recordId, setRecordId] = useState(undefined);

  const [userInfo, setUserInfo] = useState({
    name: "",
    date_of_birth: "",
    father_name: "",
    gender: "",
    email: "",
    contact: "",
    address: "",
    linkedin_profile: "",
    github_profile: "",
  });

  const [imageData, setImageData] = useState({
    imageFile: null,
    imagePreviewUrl: null,
  });

  const fetchUserInfo = async () => {
    if (!logindata.userId) {
      return;
    }

    try {
      const uri = `${urls.Show_Info}/${logindata.userId}`;
      const response = await ResumeService.GetApiCallWithToken(
        uri,
        logindata.token
      );

      setRecordId(response?.data?.data?.id);

      setUserInfo({
        name: response?.data?.data?.user?.name || "",
        date_of_birth: response?.data?.data?.date_of_birth || "",
        father_name: response?.data?.data?.father_name || "",
        gender: response?.data?.data?.gender || "",
        email: response?.data?.data?.user?.email || "",
        contact: response?.data?.data?.user?.contact || "",
        address: response?.data?.data?.address || "",
        linkedin_profile: response?.data?.data?.linkedin_profile || "",
        github_profile: response?.data?.data?.github_profile || "",
      });
      setImageData({
        imagePreviewUrl:
          `https://backend.skilledfresher.in/upload/user_image/${response?.data?.data?.image}`||null,
      });
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Failed to fetch user information"
      );
    }
  };

  useEffect(() => {
    if (logindata?.token && logindata?.userId) {
      fetchUserInfo();
    }
  }, [logindata?.token, logindata?.userId]);

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  // Scroll to the bottom when the component loads
  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imagePreviewUrl = URL.createObjectURL(file);
      setImageData({
        imageFile: file,
        imagePreviewUrl: imagePreviewUrl,
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!userInfo.name.trim()) {
      errors.name = "Full name is required.";
    } else if (/\d/.test(userInfo.name)) {
      errors.name = "Full name cannot contain digits.";
    }

    if (!userInfo.date_of_birth)
      errors.date_of_birth = "Date of birth is required.";
    if (!userInfo.father_name.trim()) {
      errors.father_name = "Father's name is required.";
    } else if (/\d/.test(userInfo.father_name)) {
      errors.father_name = "Father's name cannot contain digits.";
    }
    if (!userInfo.gender) errors.gender = "Gender is required.";
    if (!userInfo.contact.trim()) {
      errors.contact = "Mobile number is required.";
    } else if (userInfo.contact.length !== 10) {
      errors.contact = "Mobile number must be exactly 10 digits.";
    } else if (!/^[6-9]\d{9}$/.test(userInfo.contact)) {
      errors.contact = "Invalid mobile number.";
    }
    if (!userInfo.address.trim()) errors.address = "Address is required.";
    if (
      userInfo.linkedin_profile &&
      !/^https?:\/\/.+/.test(userInfo.linkedin_profile)
    )
      errors.linkedin_profile = "Enter a valid linkedIn profile URL.";
    if (
      userInfo.github_profile &&
      !/^https?:\/\/.+/.test(userInfo.github_profile)
    )
      errors.github_profile = "Enter a valid gitHub profile URL.";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = new FormData();
    const id = logindata.userId;
    data.append("person", id);

    const updatedData = {
      person: logindata.userId,
      name: userInfo.name,
      date_of_birth: userInfo.date_of_birth,
      father_name: userInfo.father_name,
      gender: userInfo.gender,
      email: userInfo.email,
      contact: userInfo.contact,
      address: userInfo.address,
      linkedin_profile: userInfo.linkedin_profile || "",
      github_profile: userInfo.github_profile || "",
    };
    if (imageData.imageFile) {
      updatedData.image = imageData.imageFile;
    }

    try {
      setLoading(true);
      const Updateurl = `${urls.Update_Info}/${recordId}`;
      const Updateresponse = await ResumeService.PutApiCall(
        Updateurl,
        updatedData,
        logindata.token
      );
      fetchUserInfo();

      if (Updateresponse.status === 200) {
        toast.success(
          Updateresponse.data.message ||
            "User information updated successfully!"
        );
     
        setErrorMessage("");

        if (imageData.imageFile) {
          const updatedImageUrl = URL.createObjectURL(imageData.imageFile);
          dispatch(setProfileImage(updatedImageUrl));
        }

        navigate("/resumehome/information", {
          state: {
            successMessage:
              Updateresponse.data.message ||
              "User information updated successfully!",
          },
        });
        setInfoForm(false);
        fetchUserInfo();
      } else {
        toast.error(
          Updateresponse?.data?.message || "Failed to update user information"
        );
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Error submitting the form"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-2 col-lg-2 col-2 col-sm-3 ">
            <Sidebar />
          </div>
          {!infoform && (
            <div className="col-md-4 col-7 col-lg-4 offset-1  offset-lg-3 border border-success rounded p-3 bg-light shadow form-container">
           

              <div className="d-flex justify-content-center mb-3">
            {  imageData?.imagePreviewUrl ||imageData.image ? (
                <img
             
                  src={
                    imageData.imagePreviewUrl ||
                    `https://backend.skilledfresher.in/upload/user_image/${imageData.image}`
                  }
                  id="image"
                  className="rounded-circle profile-image"
                  // alt="Profile "
                />
              ) : (
                <FaUserCircle
                               style={{
                                width: "150px",
                              height: "150px",
                                color: "#ccc",
                               }}
                 />
               
              )
            }
       </div>

              <div className="table-responsive table-responsive-resume">
                <table className="table table-resume table-striped  table-rounded">
                  <tbody>
                    <tr>
                      <th className="nowrapi">Name</th>
                      <td>{userInfo.name}</td>
                    </tr>
                    <tr>
                      <th className="nowrapi">Date Of Birth</th>
                      <td>{userInfo.date_of_birth}</td>
                    </tr>
                    <tr>
                      <th className="nowrapi">Father's Name</th>
                      <td>{userInfo.father_name}</td>
                    </tr>
                    <tr>
                      <th className="nowrapi">Gender</th>
                      <td>{userInfo.gender}</td>
                    </tr>
                    <tr>
                      <th className="nowrapi">Email</th>
                      <td>{userInfo.email}</td>
                    </tr>
                    <tr>
                      <th className="nowrapi">Contact</th>
                      <td>{userInfo.contact}</td>
                    </tr>
                    <tr>
                      <th className="nowrapi">Address</th>
                      <td>{userInfo.address}</td>
                    </tr>
                    <tr>
                      <th className="nowrapi">LinkedIn</th>
                      <td>{userInfo.linkedin_profile || "-"}</td>
                    </tr>
                    <tr>
                      <th className="nowrapi">GitHub</th>
                      <td>{userInfo.github_profile || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <button
                className={`btn btn-md ${
                  infoform ? "btn-danger" : "btn-success"
                } mb-2`}
                onClick={() => setInfoForm(!infoform)}
              >
                {infoform ? "Cancel" : "Edit"}
              </button>
            </div>
          )}
          {infoform && !successMessage && (
            <div className="container d-flex col-md-4 col-7  col-lg-4 border border-success rounded p-4 bg-light shadow mi">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <h3 className="mb-1 text-secondary text-center">
                  Profile Info
                </h3>
                <div className="row justify-content-center align-items-center">
                  <div
                    className="position-relative  mb-4 "
                    style={{ width: "150px", height: "150px" }}
                  >
                 { imageData?.imagePreviewUrl || imageData?.image ? (
                    <img
                      src={
                        imageData?.imagePreviewUrl ||
                        `https://backend.skilledfresher.in/upload/user_image/${imageData.image}`
                      }
                      className="rounded-circle profile-image"
                      // alt="Profile"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <FaUserCircle
                                   style={{
                                    width: "150px",
                                  height: "150px",
                                    color: "#ccc",
                                   }}
                   />
                   
                  )}


              


                 
                
                    <label
                      htmlFor="file-upload"
                      className="position-absolute top-50 start-50 translate-middle-info file-upload-icon"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderRadius: "50%",
                        padding: "10px",
                      }}
                    >
                      <i className="fas fa-upload text-white"></i>
                    </label>

                    {/* Hidden File Input */}
                    <input
                      type="file"
                      id="file-upload"
                      name="image"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label>
                      Full Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={userInfo.name || ""}
                      onChange={handleInputChange}
                      className={`form-control form-control-bg ${
                        validationErrors.name ? "is-invalid" : ""
                      }`}
                      required
                    />
                    {validationErrors.name && (
                      <div className="invalid-feedback">
                        {validationErrors.name}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>
                      Date Of Birth<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="date_of_birth"
                      value={userInfo.date_of_birth || ""}
                      onChange={handleInputChange}
                      className={`form-control form-control-bg ${
                        validationErrors.date_of_birth ? "is-invalid" : ""
                      }`}
                      max={maxDOB}
                      required
                    />
                    {validationErrors.date_of_birth && (
                      <div className="invalid-feedback">
                        {validationErrors.date_of_birth}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>
                      Father's Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="father_name"
                      value={userInfo.father_name || ""}
                      onChange={handleInputChange}
                      className={`form-control form-control-bg ${
                        validationErrors.father_name ? "is-invalid" : ""
                      }`}
                      required
                    />
                    {validationErrors.father_name && (
                      <div className="invalid-feedback">
                        {validationErrors.father_name}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>
                      Gender<span className="text-danger">*</span>
                    </label>
                    <select
                      name="gender"
                      value={userInfo.gender || ""}
                      onChange={handleInputChange}
                      className={`form-control form-control-bg ${
                        validationErrors.gender ? "is-invalid" : ""
                      }`}
                      required
                    >
                      {validationErrors.gender && (
                        <div className="invalid-feedback">
                          {validationErrors.gender}
                        </div>
                      )}
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      name="user.email"
                      value={userInfo.email || ""}
                      onChange={handleInputChange}
                      className="form-control disabled-input form-control-bg"
                      required
                      readOnly
                    />
                    {validationErrors.email && (
                      <div className="invalid-feedback">
                        {validationErrors.email}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>
                      Mobile<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={userInfo.contact || ""}
                      name="contact"
                      className={`form-control form-control-bg ${
                        validationErrors.contact ? "is-invalid" : ""
                      }`}
                      onChange={handleInputChange}
                      required
                    />
                    {validationErrors.contact && (
                      <div className="invalid-feedback">
                        {validationErrors.contact}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>
                      Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={userInfo.address || ""}
                      onChange={handleInputChange}
                      className={`form-control form-control-bg ${
                        validationErrors.address ? "is-invalid" : ""
                      }`}
                      required
                    />
                    {validationErrors.address && (
                      <div className="invalid-feedback">
                        {validationErrors.address}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>LinkedIn Profile</label>
                    <input
                      type="url"
                      name="linkedin_profile"
                      value={userInfo.linkedin_profile || ""}
                      onChange={handleInputChange}
                      className={`form-control form-control-bg ${
                        validationErrors.linkedin_profile ? "is-invalid" : ""
                      }`}
                    />
                    {validationErrors.linkedin_profile && (
                      <div className="invalid-feedback">
                        {validationErrors.linkedin_profile}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>GitHub Profile</label>
                    <input
                      type="url"
                      name="github_profile"
                      value={userInfo.github_profile || ""}
                      onChange={handleInputChange}
                      className={`form-control form-control-bg ${
                        validationErrors.github_profile ? "is-invalid" : ""
                      }`}
                    />
                    {validationErrors.github_profile && (
                      <div className="invalid-feedback">
                        {validationErrors.github_profile}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-md btn-success mt-3"
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update"}
                </button>

                <button
                  className="btn btn-md btn-danger mt-3 ms-2"
                  onClick={() => {
                    setInfoForm(!infoform);
                    fetchUserInfo();
                  }}
                >
                  {/* {infoform ? "Cancel" : "Edit"} */}
                  Cancel
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShowInfo;
