import React, { Component } from "react";
import { Link } from "react-router-dom";
import RegisterService from "../../services/RegisterService";
import "./Register.css";
import { toast, ToastContainer } from "react-toastify"; // Import toastify
import "react-toastify/dist/ReactToastify.css";

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      students: [],
      msg: "",
      name: "",
      education: "",
      contact: "",
      email: "",
      password: "",
      confirmPassword: "",
      errors: {},
      loading: false, 
      isRegistered: false,
    };
  }

  validateForm = () => {
    const { name, education, contact, email, password, confirmPassword } =
      this.state;
    const errors = {};
    let isValid = true;

    if (name.trim() === "") {
      errors.name = "Please enter your name";
      isValid = false;
    }
    if (education.trim() === "") {
      errors.education = "Please enter your education";
      isValid = false;
    }
    if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(contact)) {
      errors.contact = "Please enter a valid phone number";
      isValid = false;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      errors.email = "Please enter a valid email";
      isValid = false;
    }
    if (password.length < 6) {
      errors.password = "Password should be more than 6 characters";
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.password = "Password must contain at least one special character";
      isValid = false;
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = "Password and confirm password should match";
      isValid = false;
    }

    return errors;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: { ...this.state.errors, [event.target.name]: "" }, // Clear the error for the specific field
    });
  };

  handleRegister = (event) => {
    event.preventDefault();

    // Validate form
    const errors = this.validateForm();
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    // Set loading to true when the API request starts
    this.setState({ loading: true });

    const { name, education, contact, email, password } = this.state;
    const userData = { name, education, contact, email, password };

    RegisterService.RegisterUser(userData)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ msg: data.message, errors: {}, loading: false });

        if (
          data.message ===
          "Registration successful! A verification link has been sent to your email address. Please check your inbox and click the link to verify your email."
        ) {
          this.setState({
            isRegistered: true,
            name: "",
            education: "",
            contact: "",
            email: "",
            password: "",
            confirmPassword: "",
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          msg: "You are offline. Please check your connection.",
        });
        // console.error(err);
      });
  };

  render() {
    const { errors, loading, msg, isRegistered } = this.state;

    return (
      <div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {!isRegistered && (
                <h3 className="mb-5 text-center">Registration</h3>
              )}
              <center>
                {isRegistered ? (
                  <>
                    <div id="success-message">
                      {msg ===
                      "Registration successful! A verification link has been sent to your email address. Please check your inbox and click the link to verify your email." ? (
                        <div>
                          <h4 className="text-success">
                            Registration successful!
                          </h4>
                          <p
                            className="text-muted"
                            style={{ fontSize: "18px" }}
                          >
                            A verification link has been sent to your email
                            address. Please check your inbox and click the link
                            to verify your email.
                          </p>
                        </div>
                      ) : (
                        <b className="text-danger">{msg}</b>
                      )}
                    </div>
                    <br />
                    <div className="col-lg-3 offset-lg-0 offset-1 offset-md-0 col-md-4 col-4 text-center justify-content-center">
                      {/* <div className="col-lg-4 offset-lg-4 col-5 col-sm-5  offset-3 offset-md-6  offset-sm-6 col-md-8 col-sm-8"> */}{" "}
                      <Link className="lnkbtn" to="/login">
                        <button className="form-control submit-bt">
                          Login
                        </button>
                      </Link>
                    </div>
                    {/* </div> */}
                  </>
                ) : (
                  <>
                    {msg && (
                      <b
                        className={
                          msg.includes("successful")
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {msg}
                      </b>
                    )}
                    <form onSubmit={this.handleRegister}>
                      <div className="row justify-content-center">
                        <div className="col-lg-4">
                          <label
                            className="labels mb-1"
                            style={{ textAlign: "left", display: "block" }}
                          >
                            <b>Name</b>
                          </label>
                          <input
                            type="text"
                            className={`form-control mb-2 ${
                              errors.name ? "error-input" : ""
                            }`}
                            name="name"
                            placeholder="Your Name"
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                          {errors.name && (
                            <b className="text-danger  error-message">
                              {errors.name}
                            </b>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label
                            className="labels mb-1 float-left"
                            style={{ textAlign: "left", display: "block" }}
                          >
                            <b>Education</b>
                          </label>
                          <input
                            type="text"
                            className={`form-control mb-2 ${
                              errors.name ? "error-input" : ""
                            }`}
                            name="education"
                            placeholder="Education"
                            value={this.state.education}
                            onChange={this.handleChange}
                          />
                          {errors.education && (
                            <b className="text-danger error-message">
                              {errors.education}
                            </b>
                          )}
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-lg-4">
                          <label
                            className="labels mb-1 float-left"
                            style={{ textAlign: "left", display: "block" }}
                          >
                            <b>Contact</b>
                          </label>
                          <input
                            type="text"
                            className={`form-control mb-2 ${
                              errors.name ? "error-input" : ""
                            }`}
                            name="contact"
                            placeholder="Contact"
                            value={this.state.contact}
                            onChange={this.handleChange}
                          />
                          {errors.contact && (
                            <b className="text-danger error-message">
                              {errors.contact}
                            </b>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label
                            className="labels mb-1 float-left"
                            style={{ textAlign: "left", display: "block" }}
                          >
                            <b>Email</b>
                          </label>
                          <input
                            type="email"
                            className={`form-control mb-2 ${
                              errors.name ? "error-input" : ""
                            }`}
                            name="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                          {errors.email && (
                            <b className="text-danger error-message">
                              {errors.email}
                            </b>
                          )}
                        </div>
                      </div>
                      <div className="row justify-content-center ">
                        <div className="col-lg-4">
                          <label
                            className="labels mb-1 float-left"
                            style={{ textAlign: "left", display: "block" }}
                          >
                            <b>Password</b>
                          </label>
                          <input
                            type="password"
                            className={`form-control mb-2 ${
                              errors.name ? "error-input" : ""
                            }`}
                            name="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.handleChange}
                          />
                          {errors.password && (
                            <b className="text-danger error-message">
                              {errors.password}
                            </b>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <label
                            className="labels mb-1 float-left"
                            style={{ textAlign: "left", display: "block" }}
                          >
                            <b>Confirm Password</b>
                          </label>
                          <input
                            type="password"
                            className={`form-control mb-2 ${
                              errors.name ? "error-input" : ""
                            }`}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                          />
                          {errors.confirmPassword && (
                            <b className="text-danger error-message">
                              {errors.confirmPassword}
                            </b>
                          )}
                        </div>
                      </div>

                      <br />

                      <div className="col-lg-6 col-md-2 col-sm-3 col-xs-3">
                        <button
                          align="center"
                          type="submit"
                          className="form-control submit-btn"
                          disabled={loading} // Disable the button during loading
                        >
                          {loading ? (
                            <>
                              Register{" "}
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </>
                          ) : (
                            "Register"
                          )}
                        </button>
                      </div>

                      <div className="col-lg-6 col-md-2 col-sm-3 col-xs-3 mt-3">
                        <Link className="lnkbtn" to="/login">
                          <button
                            align="center"
                            className="form-control submit-btnn"
                          >
                            Login
                          </button>
                        </Link>
                      </div>
                    </form>
                  </>
                )}
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
