import React from "react";
import CoursesService from "../../services/CoursesService";
import ModulesService from "../../services/ModulesService";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import withRouter from "../withRouter";
import AdminService from "../../services/AdminService";
import { useSelector } from "react-redux";
import QuestionsService from "../../services/QuestionsService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";  
import "react-toastify/dist/ReactToastify.css"; 
import { Link } from "react-router-dom";
import Select from "react-select";

function SaveQuestions(props) {
  const [courselist, setCourseList] = useState([]);
  const [modulelist, setModuleList] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [question, setQuestion] = useState([]);
      const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const loginUser = useSelector((state) => state.user.value);
    const [selectedModule, setSelectedModule] = useState(null);
      const [getmodule, setGetModule] = useState([]);
      const[selectedLevel, setSelectedLevel] = useState(null);
      const[getLevel, setGetLevel]= useState([]);
      const [rightAnswer, setRightAnswer] = useState(null);  
      const [activeStatus, setActiveStatus] = useState(false);    

  const navigate = useNavigate();
  var courseBox = undefined;
  var moduleBox = undefined;
  var levelBox = undefined;
  var statusBox = undefined;
  var quesBox = undefined;
  var option1Box = undefined;
  var option2Box = undefined;
  var option3Box = undefined;
  var option4Box = undefined;
  var rightansBox = undefined;

  var save = (event) => {
    event.preventDefault();
    toast.dismiss();
    //  var course_id = courseBox.value
    var moduleId= selectedModule? selectedModule.value:"";
    // var moduleId = moduleBox.value;
    // var quesLevel = levelBox.value;
    var quesLevel = selectedLevel?selectedLevel.value:"";
    var active_status = statusBox?.value;
    var question = quesBox?.value;
    var option1 = option1Box?.value;
    var option2 = option2Box?.value;
    var option3 = option3Box?.value;
    var option4 = option4Box?.value;
    // var answer = rightansBox.value;
    const answer = rightAnswer ? rightAnswer?.value : ""; 

    const ob = {
      moduleId,
      quesLevel,
      active_status: activeStatus ? "1" : "0",
      question,
      option1,
      option2,
      option3,
      option4,
      answer,
    };
    // console.log(ob, loginUser.token);
    const a = AdminService.addQuestion(ob, loginUser?.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // setMsg(data?.message);
        toast.success(data?.message || "Question added successfully!");
      })
      .catch((error) => {
        // console.error("Error adding question:", error);
        toast.error("Failed to add question. Please try again.");
      });
      
    event.target.reset();
    setSelectedModule(null);  
    setSelectedLevel(null);
    setRightAnswer(null);  
    setActiveStatus(false);
  };

  let { course_id } = useParams();
  useEffect(() => {
    ModulesService.getAllModules(loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.module.module);
      
        const modules = data?.module?.module?.map((module) => ({
          value: module?.module_id,
          label: module?.module_title,
        }));
        setGetModule(modules);
   
      })
      .catch((error) => {
        // console.error("Error fetching or processing modules:", error);
      });
      
  }, []);

  const changeCourse = (event) => {
    setCourseId(event?.target?.value);
  };
  const handleModuleChange = (selectedOption) =>{
    setSelectedModule(selectedOption);
  };

  const levelOptions = [
    { value: "1", label: "Level - 1" },
    { value: "2", label: "Level - 2" },
    { value: "3", label: "Level - 3" },
  ];

  const handleLevelChange = (selectedOption) => {
    setSelectedLevel(selectedOption);
  };

  const handleActiveStatusChange = (event) => {
    setActiveStatus(event.target.checked); 
    // console.log(event.target.checked ? "Yes" : "No");
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />

      <div class="container">
        <div className="row d-flex align-items-center justify-content-between mb-3">
          <div className="col-lg-2 col-2 col-md-2 col-sm-2">
            <button
              className="btn btn-md bk-adm back-button "
              onClick={() => navigate("/user")}
            >
              Back
            </button>
          </div>

          <div className="col-lg-8 col-8 col-md-8 col-sm-8 text-center align-content-center">
            <h4 className="mb-0">Add New</h4>
          </div>

          <div className="col-lg-2 col-2 col-md-2 col-sm-2 text-end">
            {/* You can add another button or content here if needed */}
          </div>
        </div>

        <form onSubmit={save}>
       
          <div className="row">
            {/* Module Selection */}
            <div className="col-lg-4 col-md-6 mb-2 col-6 col-sm-6">
            <Select
            value={selectedModule}
            onChange={handleModuleChange
            }
            options={getmodule}
            isSearchable
            placeholder="Select a Course..."
            isClearable
            required
          />
           
            </div>

            {/* Level Selection */}
            <div className="col-lg-4 col-md-6 mb-2 col-6 col-sm-6">

<Select
            value={selectedLevel}
            onChange={handleLevelChange}
            options={levelOptions}
            isSearchable
            placeholder="Select Level"
            isClearable
            required
          />
            </div>


<div className="col-lg-4 col-md-6 mb-2">
  <div className="d-flex align-items-center">
   
 <label className="me-2 mb-0">Active Status</label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ width: '50px', height: '28px' }}
                    checked={activeStatus}
                    onChange={handleActiveStatusChange}
                  />
                  <label className="form-check-label ms-2 mb-0 mt-1">
                    {activeStatus ? "Yes" : "No"}
                  </label>
                </div>

    </div>
  </div>
</div>


          <div className="col-lg-12">
        
            <div className="form-group mb-3">
            
              <textarea
                className="form-control"
                ref={(c) => (quesBox = c)}
                id="exampleFormControlInput1"
                placeholder="Write question....."
                required
                rows="2"
              />
            </div>

      
  <div className="row">
    <div className="col-md-6 mb-3">
      <input
        type="text"
        className="form-control"
        ref={(c) => (option1Box = c)}
        id="exampleFormControlInput1"
        placeholder="a. Option A"
        required
      />
    </div>
    <div className="col-md-6 mb-3">
      <input
        type="text"
        className="form-control"
        ref={(c) => (option2Box = c)}
        id="exampleFormControlInput1"
        placeholder="b. Option B"
        required
      />
    </div>
  </div>
  <div className="row">
    <div className="col-md-6 mb-3">
      <input
        type="text"
        className="form-control"
        ref={(c) => (option3Box = c)}
        id="exampleFormControlInput1"
        placeholder="c. Option C"
        required
      />
    </div>
    <div className="col-md-6 mb-3">
      <input
        type="text"
        className="form-control"
        ref={(c) => (option4Box = c)}
        id="exampleFormControlInput1"
        placeholder="d. Option D"
        required
      />
    </div>
  </div>


{/* Correct Answer Input */}
<div className="row">
  <div className="col-md-6 mb-3">
    <div className="form-group mb-3">
   
       <Select
                value={rightAnswer}
                onChange={(selectedOption) => setRightAnswer(selectedOption)}
                options={[
                  { value: "a", label: "a. Option A" },
                  { value: "b", label: "b. Option B" },
                  { value: "c", label: "c. Option C" },
                  { value: "d", label: "d. Option D" }
                ]}
                placeholder="Select Correct Answer..."
                isClearable
                required
              />
    </div>
  </div>
</div>
            {/* Save Button */}
            <div className="row">
              <div className="col-lg-12 ">
                <button className="btn btn-md btn-green">Save</button>
              </div>
            </div>
          </div>
          &nbsp;
          <b className="text-success">{msg}</b>
        </form>
      </div>

      <br></br>
      <br></br>
    </>
  );
}
export default withRouter(SaveQuestions);


