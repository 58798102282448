import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RegisterService from "../services/RegisterService";
import "./Profile.css";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalMessage from "./GlobalMessage";

export default function Profile() {
  const loginUser = useSelector((state) => state.user.value);
  const [userProfile, setUserProfile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [education, setEducation] = useState("");
  const [contact, setContact] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (loginUser && loginUser.userId) {
          const response = await RegisterService.profile(
            loginUser.userId,
            loginUser.token
          );

          if (response.status) {
            setUserProfile(response.user);
            // Set initial values in the form fields
            setName(response.user.name);
            setEducation(response.user.education);
            setContact(response.user.contact);
          } else {
          }
        } else {
        }
      } catch (error) {}
    };

    if (loginUser?.token) {
      fetchProfile();
    }

    fetchProfile();
  }, [loginUser.token, loginUser]);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setMsg("");
  };

  const handleSaveChanges = async () => {
    try {
      toast.dismiss();
      const profileData = {
        name: name,
        education: education,
        contact: contact,
        email: userProfile.email,
      };

      const response = await RegisterService.updateProfile(
        userProfile.userid,
        loginUser.token,
        profileData
      );

      if (response.status) {
        // alert(response.message);
        toast.success(response.message);
        setMsg(response.message);
        // Update the local state with the new profile data
        // Hide message after 2 seconds
        setTimeout(() => {
          setMsg("");
        }, 2000);
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          name: profileData.name,
          education: profileData.education,
          contact: profileData.contact,
        }));
        setShowModal(false); // Close the modal after successful update
      } else {
        setMsg(response.message);
        setShowModal(true);
      }
    } catch (error) {
      // setMsg("An error occurred while updating your profile.");
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
      <div className="container pp">
        <div className="card small-profile-card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h4 className="mb-0">My Profile</h4>
            <Link to="#" onClick={handleModalOpen}>
              <FaEdit style={{ color: "#0C0C0C", fontSize: "1.2rem" }} />
            </Link>
          </div>
          <div className="card-body">
            {userProfile ? (
              <div className="profile-info">
                <p className="mb-2">
                  <strong>Name:</strong> <span>{userProfile.name}</span>
                </p>
                <p className="mb-2">
                  <strong>Email:</strong> <span>{userProfile.email}</span>
                </p>
                <p className="mb-2">
                  <strong>Contact:</strong> <span>{userProfile.contact}</span>
                </p>
                <p className="mb-2">
                  <strong>Education:</strong>{" "}
                  <span>{userProfile.education}</span>
                </p>
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>

        {/* Modal for editing profile */}
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Profile</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleModalClose}
                >
                  {/* <span aria-hidden="true">&times;</span> */}
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="education">Education</label>
                    <input
                      type="text"
                      className="form-control"
                      id="education"
                      value={education}
                      onChange={(e) => setEducation(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact">Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      id="contact"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleModalClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-green"
                  onClick={handleSaveChanges}
                >
                  Save changes
                </button>
              </div>
              <p> {msg}</p>
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
        ></div>
      </div>
    </>
  );
}
