import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Temp11 from "./Template/Temp11";
import Temp12 from "./Template/Temp12";
import Temp13 from "./Template/Temp13";
import logo from "../../Assets/Images/logo_image.png";
import "./Home.css";
import Temp14 from "./Template/Temp14";
import Temp15 from "./Template/Temp15";
import Temp16 from "./Template/Temp16";
import { useSelector } from "react-redux";
import ResumeService, { urls } from "../../services/ResumeService";
import Temp17 from "./Template/Temp17";
import Temp18 from "./Template/Temp18";
import Temp19 from "./Template/Temp19";
import { Outlet } from "react-router-dom"; // Import Outlet

const ResumeHome = () => {
  const loginUser = useSelector((state) => state.user.value);
  const isCandidate = JSON.parse(localStorage.getItem("CandidateInfo"));
  const isLoggedIn = localStorage.getItem("user");
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUserInfo = async () => {
    if (!loginUser.userId) {
    }
    try {
      setLoading(true);
      const url = `${urls.Show_Info}/${loginUser.userId}`;
      const response = await ResumeService.GetApiCallWithToken(url);
      localStorage.setItem("CandidateInfo", JSON.stringify(response));

      if (!loginUser) {
        navigate("/"); // Navigate to login if not logged in
      } else if (response.data.data == null) {
        navigate("/resumehome/addinfo"); // If user doesn't have info, navigate to Add Info page
      } else {
        navigate("/resumehome/information"); // If user has info, navigate to Info page
      }
    } catch (error) {
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCandidateDetailList = async () => {
      try {
        const uri = `${urls.Show_Info}/${loginUser.userId}`;
        const response = await ResumeService.GetApiCallWithToken(uri);
        localStorage.setItem("CandidateInfo", JSON.stringify(response));
      } catch (error) {
        setErrorMessage("Failed to fetch user information.");
      }
    };
    fetchCandidateDetailList();
  }, []);

  // Determine if we're on a nested route (e.g., /resumehome/addinfo)
  const isNestedRoute =
    location.pathname.startsWith("/resumehome/") &&
    location.pathname !== "/resumehome";

  return (
    <>
      {/* Conditionally render main content based on the route */}
      {!isNestedRoute && (
        <div className="text-center resumehome">
          <div className="row container">
            <div className="col-sm-6 col-md-6">
              <img
                className="mt-5"
                src={logo}
                alt="Logo"
                width="200"
                height="200"
              />
            </div>
            <div className="col-sm-6 col-md-6 text-justify text-start">
              <p
                className="mt-5 tm"
                style={{
                  fontSize: "xx-large",
                  color: "whitesmoke",
                  fontFamily: "Papyrus",
                  fontWeight: "bolder",
                }}
              >
                Best Resume Leads To Best Future
              </p>
              <ul className="pm ms-1">
                <li>&gt; Create Resume</li>
                <li>&gt; Fill up Your Details</li>
                <li>&gt; Get Amazing Resume In A While</li>
              </ul>
              <div className="text-start">
                <button
                  className="btn btn-primary createcv text-nowrap"
                  onClick={fetchUserInfo}
                >
                  {loading ? (
                    <div className="text-center" role="status">
                      Loading{" "}
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                  ) : isCandidate ? (
                    "EDIT YOUR CV"
                  ) : (
                    "CREATE YOUR CV NOW"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid mb-5">
            <hr />
            <h4
              className="fs-2 text-light ms-5"
              // style={{ textAlign: "left" }}
              style={{
                fontSize: "xx-large",
                color: "whitesmoke",
                fontFamily: "Papyrus",
                fontWeight: "bolder",
                textAlign: "left",
              }}
            >
              Resume Templates
            </h4>
          </div>

          {/* Templates Section */}
          <div className=" d-flex">
            <div className="row ">
              <div className="col-12 col-md-6 col-lg-4">
                <Temp11 />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Temp12 />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Temp13 />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Temp14 />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Temp15 />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Temp16 />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Temp17 />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Temp18 />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <Temp19 />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Render Nested Route Content */}
      <Outlet /> {/* This is where the child route content will be rendered */}
    </>
  );
};

export default ResumeHome;
