// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.courseitem {
    margin-top: 30px;
}

.courseitem:hover {
    cursor: pointer;
    border-style: none solid none none;
    border-color: blue;
}

.courseitem img {
    display: block;
    text-align: center;
    /* margin-left: 50px; */
}`, "",{"version":3,"sources":["webpack://./src/components/course/CourseItem.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,uBAAuB;AAC3B","sourcesContent":[".courseitem {\r\n    margin-top: 30px;\r\n}\r\n\r\n.courseitem:hover {\r\n    cursor: pointer;\r\n    border-style: none solid none none;\r\n    border-color: blue;\r\n}\r\n\r\n.courseitem img {\r\n    display: block;\r\n    text-align: center;\r\n    /* margin-left: 50px; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
