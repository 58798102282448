import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeData } from "../reduxdata/UserSlice";
import RegisterService from "../services/RegisterService";
import "./Login.css";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const loginUser = useSelector((state) => state.user.value);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();

  let emailbox = undefined;
  const [loading, setLoading] = useState(false);
  const handleForgotPassword = async (event) => {
    event.preventDefault();
    toast.dismiss();

    setLoading(true);
    setMsg("");
    const email = emailbox.value;

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setLoading(false);
      setErrorMsg("Please enter a valid email address.");
    } else {
      try {
        const response = await RegisterService.forgotPassword(email);

        const data = await response.json();
        setLoading(false);
        if (data.status == false) {
          toast.dismiss();
          setMsg(
            `The email address is not linked to an existing account. Please verify your email or create a new account.`
          );
          // toast.error(
          //   "The email address is not linked to an existing account."
          // );
        } else {
          toast.success(
            `${data.message}\nPlease check and login to continue...`
          );
          setMsg(data.message);
        }
      } catch (err) {
        setLoading(false);
        setMsg("");

        toast.error("You are offline. Please check your connection.");
      }
    }
  };
  const handleEmailChange = () => {
    // Clear the error message when the user starts typing
    setErrorMsg("");
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
        style={{ width: "1000px" }}
      />

      <br />

      <div className="container pb-5 mt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="">
              <h3 className="text-center">Forgot Password?</h3>
              <form onSubmit={handleForgotPassword}>
                <div className="">
                  <div className="col-lg-6 col-12 offset-lg-3 col-md-12 col-sm-12">
                    <p>
                      Enter the email address associated with your account and
                      we'll send you a link to reset your password.
                    </p>
                    <label className="labels mb-0">
                      <b>Email</b>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      ref={(c) => (emailbox = c)}
                      name="email"
                      placeholder="Enter your registered email"
                      id="email"
                      style={{
                        border: errorMsg.includes("valid email")
                          ? "1px solid red"
                          : "1px solid #ced4da",
                      }}
                      onChange={handleEmailChange}
                    />
                    {errorMsg.includes("valid email") && (
                      <small style={{ color: "red", fontSize: "14px" }}>
                        Please enter a valid email address.
                      </small>
                    )}
                  </div>
                  <br />
                </div>
                <br />
                <div className="col-lg-6 offset-lg-3 col-md-8 col-sm-8 text-center">
                  <button
                    type="submit"
                    className="form-control submit-bt text-center"
                  >
                    {loading ? (
                      <>
                        <span> Send link to email</span>
                        {""}
                        <span
                          className="spinner-border spinner-border-sm ms-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "   Send link to email"
                    )}
                  </button>
                  <Link to="/register" className="text-info">
                    <span>Create an account</span>&nbsp;
                  </Link>
                  or&nbsp;
                  <Link to="/login" className="text-info">
                    Login to continue
                  </Link>
                  <br></br>
                  {msg && (
                    <b
                      className={
                        msg.includes("sent") ? "text-success" : "text-danger"
                      }
                    >
                      {msg}
                    </b>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
