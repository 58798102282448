import React, { useState } from "react";
import "./QuestionItem.css";
import { useSelector } from "react-redux";

export default function QusItem({ questionKey, qus, index, submitAnswer }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const QuestionData = useSelector((state) => state.ques.quess);

  const handleOptionClick = (value) => {
    if (selectedOption === value) {
      setSelectedOption(null);
      submitAnswer(qus.ques_id, null, null, qus.totalQ); // Deselect and remove answer
    } else {
      setSelectedOption(value);
      submitAnswer(qus.ques_id, value, qus.answer, qus.totalQ); // Submit selected answer
    }
  };

  // Correct way to handle the options array
  const options = [qus.option1, qus.option2, qus.option3, qus.option4].filter(
    Boolean
  );

  return (
    <div className="box">
      <div className="bold">
        <span>{index}.&nbsp;</span>
        <span>{qus.question}</span>
      </div>
      <br />
      {options.length === 0 ? (
        <p>No options available</p>
      ) : (
        options.map((option, idx) => {
          const value = String.fromCharCode(97 + idx); // a, b, c, d...
          return (
            <div
              key={idx}
              className="ms-3"
              style={{ cursor: "pointer" }}
              onClick={() => handleOptionClick(value)}
            >
              <input
                type="radio"
                name={`ans${index}`}
                value={value}
                className="cursor-pointer"
                checked={selectedOption === value}
                readOnly={false} // Ensuring the user can select
              />
              &nbsp;{option}
            </div>
          );
        })
      )}
    </div>
  );
}
