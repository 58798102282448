const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;

class CoursesService {
  constructor() {
    // Retrieve the token from localStorage or another source
    this.token = localStorage.getItem("token"); // Adjust as needed
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  getAllCourses() {
    return fetch(`https://backend.skilledfresher.in/common/course/list`, {
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`,
      // },
    });
  }

  // all courses with their modules
  getAllCoursesList() {
    return fetch(`https://backend.skilledfresher.in/api/course/listCourse`, {
      headers: this.getHeaders(),
    });
  }

  getAllCoursesModule(token) {
    return fetch(`https://backend.skilledfresher.in/api/CourseModule/list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new CoursesService();
