import React, { useEffect, useState, useRef } from "react";
import AdminService from "../../services/AdminService";
import ModulesService from "../../services/ModulesService";
import withRouter from "../withRouter";
import "./Admin.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SaveModule(props) {
  const [modulelist, setModuleList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [mmsg, setMmsg] = useState("");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [moduleTitle, setModuleTitle] = useState("");
  const [description, setDescription] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
  const loginUser = useSelector((state) => state.user.value);

  const formRef = useRef(null);
  const navigate = useNavigate();

 
  useEffect(() => {
    setIsLoading(true);
    ModulesService.getAllModules(loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log("module list", data);
        setModuleList(data?.module?.module || []);
      })
      .catch((error) => {
        // console.error("Error fetching modules:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isDataUpdated]);
  

  useEffect(() => {
    if (isDataUpdated) {
      setMmsg("");
      setSelectedModule(null);
      setModuleTitle("");
      setDescription("");
      setActiveStatus("");
      setIsUpdating(false);
    }
  }, [isDataUpdated]);
  const save = (event) => {
    event.preventDefault();
    var module_title = event?.target?.modulebox?.value;
    var description = event?.target?.descbox?.value;
    var active_status = event?.target?.statusbox?.value;
    toast.dismiss();
    var ob = { module_title, description, active_status };
    AdminService.addModule(
      module_title,
      description,
      active_status,
      loginUser.token
    )
      .then((response) => response.json())
      .then((data) => {
        // setMmsg(data.message || "Module saved..");
        toast.success(data.message || "Module saved successfully!");
        // console.log("Module saved", data);

        setModuleList((prevModuleList) => [...prevModuleList, data]);
        setIsDataUpdated(!isDataUpdated);

        event.target.reset();
      })
      .catch((error) => {
        toast.error("Saving failed!");
      });
  };

  const delmodule = (module_id) => {
    // console.log('sv', module_id);
    const confirmDelete = window.confirm(
      "If you want to delete this Module the associated question will also delete..!"
    );

    if (confirmDelete) {
      toast.dismiss();
      AdminService.deleteModule(module_id)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          // setMmsg("Module Deleted..");
          toast.success("Module Deleted!");
          setModuleList((prevModuleList) =>
            prevModuleList.filter((module) => module._id !== module_id)
          ); 
          setIsDataUpdated(!isDataUpdated); // Trigger data update
        })
        .catch((error) => {
        });
    }
    
  };

  const updateModuleData = (event) => {
    event.preventDefault();
    if (selectedModule) {
      var updatedModule = {
        module_id: selectedModule?.module_id,
        module_title: moduleTitle,
        description: description,
        active_status: activeStatus,
      };
      toast.dismiss();
      // console.log(updatedModule);
      AdminService.updateModule(selectedModule?.module_id, updatedModule)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data)
          // setMmsg("Module Updated...");
          setModuleList((prevModuleList) =>
            prevModuleList.map((module) =>
              module.module_id === selectedModule.module_id ? data : module
            )
          );
          setIsDataUpdated(!isDataUpdated);
          setSelectedModule(null);
          setModuleTitle("");
          setDescription("");
          setActiveStatus("");
          toast.success("Module Updated Successfully!");
          data = {
            ...selectedModule,
            module_title: moduleTitle,
            description: description,
            active_status: activeStatus,
          };
          // console.log(data)
        })
        .catch((error) => {
          setMmsg("Update Failed..");
        });
    }
  };

  const cancelUpdate = () => {
    setSelectedModule(null);
    setModuleTitle("");
    setDescription("");
    setActiveStatus("");
    setIsUpdating(false);
  };

  const upModule = (module) => {
    setSelectedModule(module);
    window.scrollTo({
      top: 0, 
      behavior: 'smooth', 
  });
    setModuleTitle(module?.module_title);
    setDescription(module?.description);
    setActiveStatus(module?.active_status);
    setIsUpdating(true);

  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = modulelist?.slice(indexOfFirstItem, indexOfLastItem);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const totalPages = Math.ceil(modulelist.length / itemsPerPage);
    const range = 2;
    const startPage = Math.max(2, currentPage - range);
    const endPage = Math.min(totalPages - 1, currentPage + range);

    const pageNumbers = [];

  
    pageNumbers.push(1);

 
    if (startPage > 2) {
      pageNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }


    if (endPage < totalPages - 1) {
      pageNumbers.push("...");
    }

  
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
      <div className="container" ref={formRef}>
        <div className="row d-flex align-items-center justify-content-between mb-5">
          <div className="col-lg-2 col-2 col-md-2 col-sm-2">
            <button
              className="btn bk-adm back-button "
              onClick={() => navigate("/user")}
            >
              Back
            </button>
          </div>

          <div className="col-lg-8 col-8 col-md-8 col-sm-8 text-center align-content-center">
            <h4 className="mb-0">New Modules</h4>
          </div>

          <div className="col-lg-2 col-2 col-md-2 col-sm-2 text-end">
            {/* You can add another button or content here if needed */}
          </div>
        </div>

        <form onSubmit={isUpdating ? updateModuleData : save}>
          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Module</label>
              <div className="col-sm-4 col-lg-4 col-md-6">
                <input
                  type="text"
                  name="modulebox"
                  className="form-control"
                  id="modulebox"
                  placeholder="Enter Module"
                  value={moduleTitle}
                  onChange={(event) => setModuleTitle(event?.target?.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Description</label>
              <div className="col-sm-4 col-lg-4 col-md-6">
                <input
                  type="text"
                  name="descbox"
                  className="form-control"
                  id="descbox"
                  placeholder="Enter Description..."
                  value={description}
                  onChange={(event) => setDescription(event?.target?.value)}
                />
              </div>
            </div>
       
<div className="form-group row">
  <label className="col-sm-2 col-form-label">Active-Status</label>
  <div className="col-sm-4 col-lg-4 col-md-6">
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="statusSwitch"
        style={{ width: '50px', height: '28px' }}
        checked={activeStatus === "1"}
        onChange={(event) => setActiveStatus(event?.target?.checked ? "1" : "0")}
      />
      <label className="form-check-label  ms-2 mb-0 mt-1" htmlFor="statusSwitch">
        {activeStatus === "1" ? "Yes" : "No"}
      </label>
    </div>
  </div>
</div>




            <div className="form-group row">
              <div className="col-sm-8 offset-sm-2 text-right mt-3">
                {isUpdating && selectedModule ? (
                  <>
                    <button
                      type="submit"
                      className="btn btn-md btn-green"
                      style={{ borderRadius: "10px" }}
                    >
                      {isUpdating ? "Update" : "Save"}
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-md btn-secondary"
                      style={{ borderRadius: "10px" }}
                      onClick={cancelUpdate}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-md btn-green"
                    style={{ borderRadius: "10px" }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
          &nbsp;
          <b className="text-success">{mmsg}</b>
        </form>
      </div>

      <br />
      {isLoading ? (
              <div className="text-center">
              <span> Loading</span>{" "}
           <span
             className="spinner-border spinner-border-sm"
             role="status"
             aria-hidden="true"
           ></span>
        
         </div>
        ) : (
      <div className="container">
        <div className="table-responsive">
        {currentItems.length > 0 && (
          <table className="table table-bordered table-fixed">
         
            <thead>
              <tr className="text-center">
                <th>S.no</th>
                <th>Module Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
       
            <tbody>
              {currentItems.map((ob, index) => (
                <tr key={index} className="text-center">
                  <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                  <td>{ob?.module_title}</td>
                  <td>{ob?.description}</td>
                  <td className="text-center">
                    {/* {ob?.active_status} */}
                    {ob.active_status ? "Yes" : "No"}
                    </td>
                  <td width="200px" className="d-flex justify-content-center">
                    <button
                      className="btn btn-md btn-secondary"
                      style={{ borderRadius: "10px" }}
                      onClick={() => upModule(ob)}
                    >
                      Update
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-md btn-danger"
                      style={{ borderRadius: "10px" }}
                      onClick={() => delmodule(ob?.module_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
             )}
        </div>
        {currentItems.length > 0 && (
        <div className="container  justify-content-center d-flex pagination-container">
          <button
            type="button"
            className="btn btn-md btn-dark"
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            &larr; Previous
          </button>
          {/* <div>Page {currentPage}</div> */}
          <div>
            {getPageNumbers().map((pageNumber) => (
              <button
                key={pageNumber}
                type="button"
                className={`btn btn-md btn-dark m-1 ${
                  currentPage === pageNumber ? "active" : ""
                }`}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}
          </div>
          <button
            type="button"
            className="btn btn-md btn-dark"
            onClick={goToNextPage}
            disabled={indexOfLastItem >= modulelist.length}
          >
            Next &rarr;
          </button>
        </div>
        )}
      </div>
        )}
    </>
  );
}

export default withRouter(SaveModule);
