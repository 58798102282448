import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Home extends Component {
  render() {
    return (
      <div>
        <section
          className="about full-screen d-lg-flex justify-content-center align-items-center"
          id="about"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-12 d-flex align-items-center">
                <div className="about-text">
                  <small className="small-text">
                    Welcome to{" "}
                    <span className="mobile-block">SkilledFresher</span>
                  </small>
                  <h1 className="animated animated-text">
                    <span className="mr-2">Hey folks,Check </span>
                    <div className="animated-info">
                      <span className="animated-item"> Your Skills</span>
                      <span className="animated-item">Your Skills</span>
                      <span className="animated-item"> Your Skills</span>
                    </div>
                  </h1>

                  <div className="custom-btn-group mt-4">
                    <Link
                      to="/course"
                      className="btn custom-btn custom-btn-bg custom-btn-link"
                    >
                      Start Your Skill-Check
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-12 col-12">
                <div className="about-image svg">
                  <img
                    src="images/undraw/undraw_software_engineer_lvl5.svg"
                    className="img-fluid"
                    alt="svg image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
