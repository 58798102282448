import React, { useEffect, useState } from "react";
import QuestionsService from "../../services/QuestionsService";
import AdminService from "../../services/AdminService";
import CoursesService from "../../services/CoursesService";
import { Link } from "react-router-dom";
import ModulesService from "../../services/ModulesService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Admin.css";
import Select from "react-select";

function ViewQuestion() {
  const [question, setQuestion] = useState([]);
  const [courselist, setCourseList] = useState([]);
  const [modulelist, setModuleList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [courseId, setCourseId] = useState("");
  const [start, setStart] = useState(0); 
  const [moduleStart, setModuleStart] = useState(0); 
  const [moduleCurrentPage, setModuleCurrentPage] = useState(1); 
  const [totalModules, setTotalModules] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
   const [selectedModule, setSelectedModule] = useState(null);
      const [getmodule, setGetModule] = useState([]);
      const [selectedLevel, setSelectedLevel] = useState(null);
      const [moduleError, setModuleError] = useState("");
      const [levelError, setLevelError] = useState("");


  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedLevel, setEditedLevel] = useState("");
  const [editedModule, setEditedModule] = useState("");
  const [editedStatus, setEditedStatus] = useState(null);
  const [editedOption1, setEditedOption1] = useState("");
  const [editedOption2, setEditedOption2] = useState("");
  const [editedOption3, setEditedOption3] = useState("");
  const [editedOption4, setEditedOption4] = useState("");
  const [editedAnswer, setEditedAnswer] = useState("");
  const loginUser = useSelector((state) => state.user.value);
 

  const [showModal, setShowModal] = useState(false);

  let moduleBox;
  let levelBox;

  
  const view = (event) => {
    event.preventDefault();

  setModuleError("");
  setLevelError("");

  if (!selectedModule) {
    setModuleError("Please select a module.");
  }
  if (!selectedLevel) {
    setLevelError("Please select a level.");
  }

  if (!selectedModule || !selectedLevel) {
    return;
  }

    var module_id= selectedModule? selectedModule.value:"";
    const quesLevel = selectedLevel ? selectedLevel.value : "0";

  
    setIsLoading(true);

    QuestionsService.questionByModule(quesLevel, module_id, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Ques by module", data);
        // console.log(QuestionsService);
        // setQuestion(data?.question?.course);
        setQuestion(data?.question || []);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching questions:", error);
        setIsLoading(false);
      
      });
      
      setSelectedModule(null);  
    setSelectedLevel(null);
  };


  const changeLevel = (event) => {
    setSelectedLevel(event.target.value);
  };

  const delques = (ques_id) => {
    // console.log(ques_id)
    const confirmDelete = window.confirm(
      "Are you sure to delete this question ?"
    );
    if (confirmDelete) {
      toast.dismiss();
         setIsLoading(true);
      AdminService.deleteQues(ques_id)
        .then((response) => response.json())
   
        .then(() => {
          // console.log(data);
        
          setQuestion((prevQues) =>
            prevQues.filter((ques) => ques._id !== ques_id)
          );
          viewque();
          setIsDataUpdated(!isDataUpdated);
          toast.success("Question deleted successfully!");
        })
        .catch((error) => {
          // console.error("Error deleting question:", error);
          setIsLoading(false);
        });
        
      }
  };

  const handleUpdateClick = (ques_id) => {
    const selectedQues = question.find((ob) => ob.ques_id === ques_id);
    if (selectedQues) {
      setSelectedQuestion(selectedQues);

      setEditedQuestion(selectedQues.question);
      setEditedLevel(selectedQues.quesLevel);
      setEditedModule(selectedQues.moduleId);
      setEditedStatus(selectedQues.active_status);
   
      
      setEditedOption1(selectedQues.option1);
      setEditedOption2(selectedQues.option2);
      setEditedOption3(selectedQues.option3);
      setEditedOption4(selectedQues.option4);
      setEditedAnswer(selectedQues.answer);

      setShowModal(true);
      toast.dismiss();
    }
  };

  const saveQuestion = async (ques_id) => {
    const updatedQuestions = {
      question: editedQuestion,
      quesLevel: editedLevel,
      moduleId: editedModule,
      active_status: editedStatus,
      option1: editedOption1,
      option2: editedOption2,
      option3: editedOption3,
      option4: editedOption4,
      answer: editedAnswer,
    };

    try {
      toast.dismiss();
      setIsLoading(true);
      await AdminService.updateQuestion(ques_id, updatedQuestions);

    viewque();

      setQuestion((prevQuestions) =>
        prevQuestions.map((ques) =>
          ques.ques_id === ques_id ? { ...ques, ...updatedQuestions } : ques
        )
      );

      setSelectedQuestion(null);
      // setIsUpdating(false);
      setShowModal(false); // Close the modal
      toast.success("Question updated successfully!");
    } catch (error) {
      toast.dismiss();
      setIsLoading(false);
      // console.log(error);
      toast.error("Failed to update question.");
    }
  };

  const cancelEdit = () => {
    setSelectedQuestion(null);
    setShowModal(false); // Close modal without saving
    // setIsUpdating(false);
    setEditedQuestion(selectedQuestion?.question);
    setEditedLevel(selectedQuestion?.quesLevel);
    setEditedModule(selectedQuestion?.moduleId);
    setEditedStatus(selectedQuestion?.active_status);
    setEditedOption1(selectedQuestion?.option1);
    setEditedOption2(selectedQuestion?.option2);
    setEditedOption3(selectedQuestion?.option3);
    setEditedOption4(selectedQuestion?.option4);
    setEditedAnswer(selectedQuestion?.answer);
  };

  const viewque = () => {
    setIsLoading(true);
    QuestionsService.getAllQuestion(start, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log("API Response:", data);
        setQuestion(data?.question?.question);
        // setTotalQuestions(data?.question?.question?.length);
        setTotalQuestions(data?.totalCount);
        // console.log("total questions", data?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching questions:", error);
        setIsLoading(false);
      });
      
 
  };
// console.log(loginUser.token)
  useEffect(() => {

    ModulesService.getAllModules(loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.course);
      
        const modules = data?.module?.module.map((module) => ({
          value: module.module_id,
          label: module.module_title,
        }));
        setGetModule(modules);
        // setModuleList(data?.module?.module);
        setTotalModules(data?.module?.module?.length);
      })
      .catch((error) => {
        // console.error("Error fetching modules:", error);
        setIsLoading(false);
      });
      

    viewque();
  }, [  loginUser?.token]);

  // Log totalModules after it's updated
  useEffect(() => {
    // console.log("total Module", totalModules);
  }, [totalModules]);
  const changeCourse = (event) => {
    setCourseId(event.target.value);
  };





    const preData = (event) => {
        event.preventDefault();
        const s = start - 20;
        setCurrentPage(currentPage - 1);
        QuestionsService.getAllQuestion(s, loginUser
          .token
        )
            .then((response) => response.json())
            .then((data) => {
              setQuestion(data.question.question);
              setStart(s);
            })
            .catch((error) => {
              // console.error("Error fetching or setting questions:", error);
            });
            
    };

    const nextData = (event) => {
        event.preventDefault();
        const s = start + 20;
        setCurrentPage(currentPage + 1);
        QuestionsService.getAllQuestion(s, loginUser.token
        )
            .then((response) => response.json())
            .then((data) => {
              setQuestion(data?.question?.question);
              setStart(s);
              if (data?.question?.question?.length === 0) {
                // No more questions, disable next button
                document.getElementById("nextButton").disabled = true;
              }
            })
            .catch((error) => {
              // console.error("Error fetching questions or processing data:", error);
            });
            
    };
    const totalPages = Math.ceil(totalQuestions / 20);

    const handlePageChange = (pageNumber) => {
      const s = (pageNumber - 1) * 20; // Calculate the start index based on page number
      setCurrentPage(pageNumber); // Set the current page
      setStart(s); // Update the start index for API request
      QuestionsService.getAllQuestion(s, loginUser.token)
        .then((response) => response.json())
        .then((data) => {
          setQuestion(data?.question?.question);
        })
        .catch((error) => {
          // console.error("Error fetching questions:", error);
        });
        
    };
    


  const handleModuleChange = (selectedOption) =>{
    setSelectedModule(selectedOption);
    setModuleError("");
  };
  const handleLevelChange = (selectedOption) => {
    setSelectedLevel(selectedOption);
    setStart(0)
    setLevelError("");

  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
      <div className="container ">
        <div className="row d-flex align-items-center justify-content-lg-between justify-content-center">
          <div className="col-lg-2 col-2 col-md-2 col-sm-2">
            <button
              className="btn btn-md bk-adm back-button "
              onClick={() => navigate("/user")}
            >
              Back
            </button>
          </div>

          <div className="col-lg-8 col-8 col-md-8 col-sm-8 text-center align-content-center">
            <h4 className="mb-0">All Questions</h4>
          </div>

          <div className="col-lg-2 col-2 col-sm-2 text-end">
            {/* You can add another button or content here if needed */}
          </div>
        </div>

        <div className="row col-lg-12 col-md-12 mt-4 justify-content-lg-center justify-content-md-center justify-content-center">
          
          <div className="col-lg-4  offset-lg-2 col-md-5 col-4 ">
         
 
            <Select
            value={selectedModule}
            onChange={handleModuleChange
            }
            options={getmodule}
            isSearchable
            placeholder="Select a Course..."
            isClearable
        
            className={moduleError ? "error-border" : ""}
          />
           {moduleError && <div className="error-message text-danger">{moduleError}</div>}
          </div>
          <div className="col-lg-4 col-md-4 col-4 ">
          
              <Select
              value={selectedLevel}
              onChange={handleLevelChange}
              options={[
                { value: "0", label: "All Levels" },
                { value: "1", label: "Level 1" },
                { value: "2", label: "Level 2" },
                { value: "3", label: "Level 3" },
              ]}
              placeholder="Select Level"
              isClearable
              className={levelError ? "error-border" : ""}
            />
              {levelError && <div className="error-message text-danger">{levelError}</div>}
          </div>
       
          <div className="col-lg-2 col-md-2 col-2 ">
            <button type="button" onClick={view} className="btn btn-md btn-green">
              View
            </button>
          </div>

 
        </div>
      </div>

      <br />
      {isLoading ? (
          <div className="text-center">
             <span> Loading</span>{" "}
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
           
          </div>
        ) : (
          

      <div className="container  ">
        <div className="table-responsive">
        {question?.length > 0 ? (
          <table className="table table-bordered ">
            <thead>
              <tr className="text-center">
                <th>S.no</th>
                <th>Question</th>
                <th>Option1</th>
                <th>Option2</th>
                <th>Option3</th>
                <th>Option4</th>
                <th>Answer</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {question?.map((ob, index) => {
                const ques_id = ob?.ques_id;
                return (
          
                  <tr key={index} className="text-center">
                  <td>{index + 1}</td>
                  <td dangerouslySetInnerHTML={{ __html: ob?.question }}></td>
                  <td>{ob?.option1}</td>
                  <td>{ob?.option2}</td>
                  <td>{ob?.option3}</td>
                  <td>{ob?.option4}</td>
                  <td>{ob?.answer}</td>
                  {/* <td>{ob?.active_status}</td> */}
                  <td>{ob?.active_status === 1 ? "Yes" : "No"}</td>


                  <td width="170px" className="d-flex">
                    <button
                      className="btn btn-md btn-secondary btn-block"
                      style={{ borderRadius: "10px", marginBottom: "2px" }}
                      onClick={() => handleUpdateClick(ques_id)}
                    >
                      Update
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-md btn-danger btn-block"
                      style={{ borderRadius: "10px" }}
                      onClick={() => delques(ques_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                );
              })}
            </tbody>
          </table>
 ) : (
  <div className="text-center">
    <p>No records found.</p>
  </div>
)}
          {/* </div> */}
        </div>


{/* Modal for Editing Question */}
        {showModal && (
          <div className="modal fade show" style={{ display: "block" }} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Question</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancelEdit}   style={{ position: "absolute", right: "15px", top: "15px" }} >
                    {/* <span aria-hidden="true">&times;</span> */}
                    <span aria-hidden="true">
    <i className="fas fa-times"></i> {/* Font Awesome cross icon */}
  </span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label>Question</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editedQuestion}
                        onChange={(e) => setEditedQuestion(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Option 1</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editedOption1}
                        onChange={(e) => setEditedOption1(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Option 2</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editedOption2}
                        onChange={(e) => setEditedOption2(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Option 3</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editedOption3}
                        onChange={(e) => setEditedOption3(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Option 4</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editedOption4}
                        onChange={(e) => setEditedOption4(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Answer</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editedAnswer}
                        onChange={(e) => setEditedAnswer(e.target.value)}
                        required
                      />
                    </div>
            
                    <div className="form-group">
              <label>Status</label>
              <select
                className="form-control"
                value={editedStatus}
                onChange={(e) => setEditedStatus(e.target.value)}
              >
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
            </div>

                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-md btn-secondary" onClick={cancelEdit}>
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-md btn-green"
                    onClick={() => saveQuestion(selectedQuestion?.ques_id)}
                  >
                    Update Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
 {question?.length>0 &&(
<div className="container d-flex justify-content-center pagination-container">
  <button
    type="button"
    onClick={preData}
    className="btn btn-md btn-dark m-1"
    disabled={start === 0} // Disable the previous button when on the first page
  >
    &larr; Previous
  </button>

  {/* Page Buttons */}
  <div className="d-flex justify-content-center align-items-center">
    {/* First page button */}
    {currentPage !== 1 && (
      <button
        key={1}
        className={`btn btn-md btn-dark m-1`}
        onClick={() => handlePageChange(1)}
      >
        1
      </button>
    )}

    {/* Show '...' if there are multiple pages between 1 and current page */}
    {currentPage > 2 && <span className="btn btn-md btn-dark m-1">...</span>}

    {/* Current page */}
    <button
      key={currentPage}
      className={`btn btn-md btn-dark m-1 active`}
    >
      {currentPage}
    </button>

   

    {/* Last page button */}
    {currentPage !== totalPages && (
      <button
        key={totalPages}
        className={`btn btn-md btn-dark m-1`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    )}
  </div>

  <button
    type="button"
    onClick={nextData}
    id="nextButton"
    className="btn btn-md btn-dark m-1"
    disabled={question?.length < 20} // Disable if there are fewer than 20 questions left
  >
    Next &rarr;
  </button>
</div>

  )}

      </div>
      
)}
      
    </>
  );
}

export default ViewQuestion;


