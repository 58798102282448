// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position-relative2 {
  position: relative;
}

.clear-input-icon2 {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color:#333;
}
@media (max-width: 768px) {
.ul{
  text-align: center;
  margin-top: 6px;
}
}
.is-invalid {
  border: 1px solid #dc3545; 
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5); 
}

.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 8px rgba(220, 53, 69, 0.75); 
}
.clear-input-icon2.move-icon2 {
  top: 25%; 
}

@media (min-width: 768px) {
  .table-responsive {
      overflow: inherit;
  }
}
.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/ViewUserDetails.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;EAC3B,eAAe;EACf,UAAU;AACZ;AACA;AACA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;AACA;EACE,yBAAyB;EACzB,0CAA0C;AAC5C;;AAEA;EACE,qBAAqB;EACrB,2CAA2C;AAC7C;AACA;EACE,QAAQ;AACV;;AAEA;EACE;MACI,iBAAiB;EACrB;AACF;AACA;;;IAGI,gBAAgB;AACpB","sourcesContent":[".position-relative2 {\r\n  position: relative;\r\n}\r\n\r\n.clear-input-icon2 {\r\n  position: absolute;\r\n  right: 20px;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n  cursor: pointer;\r\n  color:#333;\r\n}\r\n@media (max-width: 768px) {\r\n.ul{\r\n  text-align: center;\r\n  margin-top: 6px;\r\n}\r\n}\r\n.is-invalid {\r\n  border: 1px solid #dc3545; \r\n  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5); \r\n}\r\n\r\n.is-invalid:focus {\r\n  border-color: #dc3545;\r\n  box-shadow: 0 0 8px rgba(220, 53, 69, 0.75); \r\n}\r\n.clear-input-icon2.move-icon2 {\r\n  top: 25%; \r\n}\r\n\r\n@media (min-width: 768px) {\r\n  .table-responsive {\r\n      overflow: inherit;\r\n  }\r\n}\r\n.table-responsive .dropdown,\r\n.table-responsive .btn-group,\r\n.table-responsive .btn-group-vertical {\r\n    position: static;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
