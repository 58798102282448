import React, { useEffect, useState } from "react";
import "./UseTemp4.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResumeService, { urls } from "../../../services/ResumeService";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UseTemp4 = () => {
const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;

  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  const downloadPDF = () => {
    const input = document.getElementById("profileContent4"); // Adjust ID if necessary

    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Scale ratio to fit canvas content within the PDF width
      const scaleRatio = pdfWidth / canvasWidth;
      const scaledHeight = canvasHeight * scaleRatio;

      // Define top and bottom padding in PDF units (e.g., mm)

      const topPadding = 0;
      const bottomPadding = 0;

      // Adjust available page height to account for top and bottom padding
      const adjustedPageHeight = pdfHeight - topPadding - bottomPadding;

      // Break down content into multiple pages if needed
      let yOffset = 0;
      while (yOffset < canvasHeight) {
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(
          canvasHeight - yOffset,
          adjustedPageHeight / scaleRatio
        );

        const context = pageCanvas.getContext("2d");
        context.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add content to the PDF with top padding on each page
        pdf.addImage(
          pageData,
          "PNG",
          0,
          yOffset === 0 ? 0 : topPadding, // No top padding for the first page
          pdfWidth,
          (pageCanvas.height * pdfWidth) / canvas.width
        );

        yOffset += pageCanvas.height;

        if (yOffset < canvasHeight) pdf.addPage(); // Add a new page if more content remains
      }

      pdf.save("profile.pdf");
    });
  };

  return (
    <>
      <div className="container">
        <div className="button-container4 row">
          <div className="col-12 col-sm-12 col-md-6">
            <button
              className="btn  bk4 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <button className="btn db4 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>
        <div className="container">
          <div className="scroll-container4">
            <div className="profile-container4">
              <div id="profileContent4" className="profile-main4">
                <div className="profile-box">
                  <div className="profile-header">
                    <div className="row">
                      <div className="col-3 clo34 ">
                        <img
                          src={
                            `https://backend.skilledfresher.in/upload/user_image/${candidate?.candidates[0]?.image}` ||
                            "/path/to/default-image.png"
                          }
                          className="Profile_img4"
                          alt="Profile"
                        />
                      </div>
                      <div className="col-6 offset-1 col7">
                        <div className="profile-info4">
                          <p className="profile-name">{candidate?.name}</p>
                          <div className="row">
                            <div className="col-6">
                              <p className="profile-detail4">
                                <FontAwesomeIcon
                                  icon={faMapMarkerAlt}
                                  className="icon"
                                />
                                {candidate?.candidates[0]?.address}
                              </p>
                              <p className="profile-detail4">
                                <FontAwesomeIcon
                                  icon={faPhone}
                                  className="icon"
                                />
                                {candidate?.contact}
                              </p>
                              <p className="profile-detail4">
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  className="icon"
                                />
                                {candidate?.email}
                              </p>
                            </div>
                            <div className="col-6">
                              {/* {dummyData.my_website && (
                        <p className="profile-detail4">
                          <FontAwesomeIcon icon={faGlobe} className="icon" />
                          {dummyData.my_website}
                        </p>
                      )} */}
                              {candidate?.candidates[0]?.linkedin_profile && (
                                <p className="profile-detail4">
                                  <FontAwesomeIcon
                                    icon={faLinkedin}
                                    className="icon"
                                  />
                                  {candidate?.candidates[0]?.linkedin_profile}
                                </p>
                              )}
                              {candidate?.candidates[0]?.github_profile && (
                                <p className="profile-detail4">
                                  <FontAwesomeIcon
                                    icon={faGithub}
                                    className="icon"
                                  />
                                  {candidate?.candidates[0]?.github_profile}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {candidate?.candidates[0]?.objective && (
                    <section>
                      <h3 className="h34">Objective</h3>
                      <hr className="hr4" />
                      <p className="pt4">
                        {" "}
                        {candidate?.candidates[0]?.objective}
                      </p>
                    </section>
                  )}
                  <section>
                    <h3 className="h34">More Information</h3>
                    <hr className="hr4" />
                    {candidate?.candidates[0]?.father_name && (
                      <p className="pt4">
                        {" "}
                        <span className="bold-title4">Father Name:</span>{" "}
                        {candidate?.candidates[0]?.father_name}
                      </p>
                    )}
                    {candidate?.candidates[0]?.date_of_birth && (
                      <p className="pt4">
                        {" "}
                        <span className="bold-title4">Date of Birth:</span>{" "}
                        {candidate?.candidates[0]?.date_of_birth}
                      </p>
                    )}
                    {candidate?.candidates[0]?.gender && (
                      <p className="pt4">
                        {" "}
                        <span className="bold-title4">Gender:</span>
                        {candidate?.candidates[0]?.gender}
                      </p>
                    )}
                    {candidate?.candidates[0]?.marital_status && (
                      <p className="pt4">
                        {" "}
                        <span className="bold-title4">
                          Marital Status:
                        </span>{" "}
                        {candidate?.candidates[0]?.marital_status}
                      </p>
                    )}
                    {candidate?.candidates[0]?.nationality && (
                      <p className="pt4">
                        {" "}
                        <span className="bold-title4">Nationality:</span>{" "}
                        {candidate?.candidates[0]?.nationality}
                      </p>
                    )}
                    {candidate?.candidates[0]?.interest && (
                      <p className="pt4">
                        {" "}
                        <span className="bold-title4">Interest:</span>{" "}
                        {candidate?.candidates[0]?.interest}
                      </p>
                    )}
                    {candidate?.candidates[0]?.language_known && (
                      <p className="pt4">
                        {" "}
                        <span className="bold-title4">
                          Language Known:
                        </span>{" "}
                        {candidate?.candidates[0]?.language_known}
                      </p>
                    )}
                  </section>
                  {candidate?.experience?.length > 0 && (
                    <section>
                      <h3 className="h34">Experience</h3>
                      <hr className="hr4" />
                      {candidate?.experience?.map((exp, index) => (
                        <div key={index}>
                          <p className="pt4">
                            <span className="bold-title4">
                              {" "}
                              {exp?.company_name}{" "}
                            </span>{" "}
                            -{exp?.start_date}
                            {exp?.end_date ? ` to ${exp?.end_date}` : ""}
                          </p>
                          <p className="pt4">
                            {exp?.designation} - {exp?.description}
                          </p>
                          <hr className="hr-4" />
                        </div>
                      ))}
                    </section>
                  )}
                  {candidate?.educational?.length > 0 && (
                    <section>
                      <h3 className="h34">Education</h3>
                      <hr className="hr4" />
                      {candidate?.educational?.map((edu, index) => (
                        <div key={index}>
                          <p className="pt4">
                            <b className="bold">Degree/Program: </b>{" "}
                            <span className="upercase ms-1">
                              {" "}
                              {edu?.education_name}
                            </span>
                          </p>
                          <p className="pt4">
                            <b className="bold">Passing Year: </b>{" "}
                            <span className="span1">{edu?.passing_year}</span>
                          </p>
                          <p className="pt4">
                            <b className="bold">Institute/University: </b>
                            <span className="span1">
                              {edu?.institute_name} / {edu?.board_or_university}
                            </span>
                          </p>
                          <p className="pt4">
                            <b className="bold">Percentage/Grade: </b>
                            <span className="span1">
                              {edu?.percentage_or_grade}
                            </span>
                          </p>
                          <hr className="hr-4" />
                        </div>
                      ))}
                    </section>
                  )}
                  {candidate?.project?.length > 0 && (
                    <section>
                      <h3 className="h34">Projects</h3>
                      <hr className="hr4" />
                      {candidate?.project?.map((project, index) => (
                        <div key={index}>
                          <p className="pt4">
                            <b className="bold">Project Title: </b>{" "}
                            <span className="bold-title4">
                              {" "}
                              {project?.project_title}{" "}
                            </span>
                            {/* - {project?.skill_apply} -{" "}
                          {project?.duration_in_month} */}
                          </p>
                          <p className="pt4">
                            <b className="bold">Skills Apply: </b>
                            {project?.skill_apply}
                          </p>
                          <p className="pt4">
                            <b className="bold">Duration in month: </b>{" "}
                            {project?.duration_in_month}
                          </p>
                          <p className="pt4">
                            <b className="bold">Description: </b>
                            {project?.description}
                          </p>
                          <hr className="hr-4" />
                        </div>
                      ))}
                    </section>
                  )}
                  {candidate?.candidates[0]?.skills && (
                    <section>
                      <h3 className="h34">Skills</h3>
                      <hr className="hr4" />
                      <p>
                        <p className="pt4">
                          {candidate?.candidates[0]?.skills}
                        </p>
                      </p>
                    </section>
                  )}
                  {candidate?.achievement?.length > 0 && (
                    <section>
                      <h3 className="h34">Achievements</h3>
                      <hr className="hr4" />
                      {candidate.achievement.map((achievement, index) => (
                        <div key={index}>
                          <p className="pt4">
                            <div className="title-section">
                              <label className="me-2 bold">Title: </label>
                              <span className="bold-title4">
                                {achievement?.title}
                              </span>
                            </div>
                            <div className="title-section">
                              <label className="me-2">Description: </label>
                              <span> {achievement?.description}</span>
                            </div>
                          </p>
                          <hr className="hr-4" />
                        </div>
                      ))}
                    </section>
                  )}
                </div>

                <div className="footer4">
                  <p className="footer-text4">
                    Powered By{" "}
                    <a
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-link4"
                    >
                      skillfresher.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseTemp4;
