// import React, {
//   createContext,
//   useState,
//   useContext,
//   useEffect,
//   useCallback,
// } from "react";
// import { toast } from "react-toastify"; // Import toast from react-toastify
// import "react-toastify/dist/ReactToastify.css";

// // Create a context for network status
// const NetworkStatusContext = createContext();

// // Custom hook to access network status context
// export const useNetworkStatus = () => useContext(NetworkStatusContext);

// // NetworkStatusProvider to provide the context to your components
// export const NetworkStatusProvider = ({ children }) => {
//   const [isOnline, setIsOnline] = useState(navigator.onLine);
//   const [networkErrorMessage, setNetworkErrorMessage] = useState(null);

//   // Update network error message
//   const updateNetworkErrorMessage = useCallback((message) => {
//     setNetworkErrorMessage(message);
//   }, []);

//   useEffect(() => {
//     const handleOnline = () => {
//       setIsOnline(true);
//       updateNetworkErrorMessage(null); // Clear the error message when online
//     };

//     const handleOffline = () => {
//       setIsOnline(false);
//       updateNetworkErrorMessage(
//         "You are offline. Please check your internet connection."
//       );
//       toast.error("You are offline. Please check your internet connection.");
//     };

//     window.addEventListener("online", handleOnline);
//     window.addEventListener("offline", handleOffline);

//     return () => {
//       window.removeEventListener("online", handleOnline);
//       window.removeEventListener("offline", handleOffline);
//     };
//   }, [updateNetworkErrorMessage]);

//   return (
//     <NetworkStatusContext.Provider
//       value={{ isOnline, networkErrorMessage, updateNetworkErrorMessage }}
//     >
//       {children}
//     </NetworkStatusContext.Provider>
//   );
// };

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { toast } from "react-toastify"; // Import toast from react-toastify
import "react-toastify/dist/ReactToastify.css";

// Create a context for network status
const NetworkStatusContext = createContext();

// Custom hook to access network status context
export const useNetworkStatus = () => useContext(NetworkStatusContext);

// NetworkStatusProvider to provide the context to your components
export const NetworkStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [networkErrorMessage, setNetworkErrorMessage] = useState(null);

  // Update network error message
  const updateNetworkErrorMessage = useCallback((message) => {
    setNetworkErrorMessage(message);
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      updateNetworkErrorMessage(null); // Clear the error message when online
    };

    const handleOffline = () => {
      setIsOnline(false);
      updateNetworkErrorMessage(
        "You are offline. Please check your internet connection."
      );
      // toast.error("You are offline. Please check your internet connection.");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [updateNetworkErrorMessage]);

  return (
    <NetworkStatusContext.Provider
      value={{ isOnline, networkErrorMessage, updateNetworkErrorMessage }}
    >
      {children}
    </NetworkStatusContext.Provider>
  );
};
