let networkStatus = {
  isOnline: navigator.onLine, // Initialize with current network status
};

// Function to update network status
export const setNetworkStatus = (status) => {
  networkStatus.isOnline = status;
};

// Function to get current network status
export const getNetworkStatus = () => {
  return networkStatus.isOnline;
};
