import React, { useState, useEffect } from "react";
import TestService from "../../services/TestService";
import RegisterService from "../../services/RegisterService";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Admin.css";
import { FaUserCircle } from "react-icons/fa";
import ResumeService, { urls } from "../../services/ResumeService";
import UseTemp1 from "../Resume/Template/UseTemp1";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ViewUserTest(props) {
const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;

  const [testByUser, setTestByUser] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const loginUser = useSelector((state) => state.user.value);
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [edu, setEdu] = useState("");
  const [msg, setMsg] = useState("");
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [candidate, setCandidate] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const { userid } = useParams();
  const navigate = useNavigate();
  // console.log(userid)
  useEffect(() => {
    TestService.getTestAnswer(userid, loginUser?.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setName(data?.username);
        setTestByUser(data?.testHistory?.test);
        setIsLoading(false);
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const renderSortArrow = (column) => {
    if (column === sortColumn) {
      return sortDirection === "asc" ? (
        <span style={{ cursor: "pointer" }}>&#x25B2;</span>
      ) : (
        <span style={{ cursor: "pointer" }}>&#x25BC;</span>
      );
    }
    return <span style={{ cursor: "pointer" }}>&#x2195;</span>;
  };

  const sortedTestByUser = testByUser.sort((a, b) => {
    const scoreA = a.totalQues.reduce(
      (x, ob) => (ob.isright === 1 ? x + 1 : x),
      0
    );
    const scoreB = b.totalQues.reduce(
      (x, ob) => (ob.isright === 1 ? x + 1 : x),
      0
    );

    if (sortColumn === "score") {
      return sortDirection === "asc" ? scoreA - scoreB : scoreB - scoreA;
    }

    if (sortColumn === "testid") {
      return sortDirection === "asc"
        ? a.testid - b.testid
        : b.testid - a.testid;
    } else if (sortColumn === "date") {
      return sortDirection === "asc"
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    } else if (sortColumn === "module_title") {
      return sortDirection === "asc"
        ? a.module_title.localeCompare(b.module_title)
        : b.module_title.localeCompare(a.module_title);
    } else if (sortColumn === "quesLevel") {
      return sortDirection === "asc"
        ? parseInt(a.quesLevel) - parseInt(b.quesLevel)
        : parseInt(b.quesLevel) - parseInt(a.quesLevel);
    }
    return 0;
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (loginUser && userid) {
          const response = await RegisterService.profile(
            userid,
            loginUser?.token
          );
          // console.log(response);
          // console.log(response?.user?.userid);
          setUid(response?.user?.userid);
          const name = response?.user?.name;
          setName(name);
          const contact = response?.user?.contact;
          setContact(contact);
          const email = response?.user?.email;
          setEmail(email);
          const Education = response?.user?.education;
          setEdu(Education);

          if (response.status) {
            setCandidateInfo(response?.candidateInfo);
            // console.log(candidateInfo.person);
          } else {
            // console.log("Profile not found");
          }
        } else {
        }
      } catch (error) {
        // console.error("Error fetching profile:", error);
      }
    };

    if (loginUser?.token && userid) {
      fetchProfile();
    }

    fetchProfile();
  }, [loginUser?.token && userid]);

  const [loading, setLoading] = useState(false);
  const logindata = useSelector((state) => state.user.value);

  const handleButtonClick = async () => {
    const candidateUserId = candidateInfo?.person;
 
    if (!candidateUserId) {
      toast.error("No Resume Found"); 
      return; 
    }

    try {
      // console.log("User resume ID:", candidateUserId);
      // console.log("Login Token:", logindata?.token);
      toast.dismiss();
      setLoading(true);

      const fetchUrl = `${urls.Candidate_Details}/${candidateUserId}`;
      const response = await ResumeService.GetApiCallWithToken(
        fetchUrl,
        logindata?.token
      );

      // console.log("Fetch URL:", fetchUrl);
     
      // console.log("API resume Response:", response);
      const candidateInfo = response?.data?.data;

      if (candidateInfo.candidates && candidateInfo.candidates.length === 0) {
        alert("No Resume Found");
    
      } else if (response?.data?.status) {
        // console.log("Candidate Info:", candidateInfo);
        setCandidate(candidateInfo);
        localStorage.setItem("candidate", JSON.stringify(candidateInfo));

        setShowProfileModal(false);

        const modal = document.querySelector(".modal");
        const backdrop = document.querySelector(".modal-backdrop");

        if (modal) {
          modal.classList.remove("show");
          modal.style.display = "none"; 
        }

        if (backdrop) {
          backdrop.classList.remove("show");
          document.body.classList.remove("modal-open"); 
          backdrop.remove();
        }

       
        document.body.style.overflow = ""; 

        if (candidateInfo.candidates[0].template === "use1") {
          navigate("/resumehome/use1");
        } else if (candidateInfo.candidates[0].template === "use2") {
          navigate("/resumehome/use2");
        } else if (candidateInfo.candidates[0].template === "use3") {
          navigate("/resumehome/use3");
        } else if (candidateInfo.candidates[0].template === "use4") {
          navigate("/resumehome/use4");
        } else if (candidateInfo.candidates[0].template === "use5") {
          navigate("/resumehome/use5");
        } else if (candidateInfo.candidates[0].template === "use6") {
          navigate("/resumehome/use6");
        } else if (candidateInfo.candidates[0].template === "use7") {
          navigate("/resumehome/use7");
        } else if (candidateInfo.candidates[0].template === "use8") {
          navigate("/resumehome/use8");
        } else if (candidateInfo.candidates[0].template === "use9") {
          navigate("/resumehome/use9");
        }
      }
    } catch (error) {
      setMsg("Error fetching candidate data. Please try again.");
      // console.error("Error fetching candidate data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
      />
      <div
        className="modal fade"
        id="profileModal"
        tabIndex="-1"
        aria-labelledby="profileModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="profileModalLabel">
                Candidate Profile
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {candidateInfo ? (
                <div className="candinfo">
                  <p>
                    <strong>Name:</strong> {name}
                  </p>
                  <p>
                    <strong>Email:</strong> {email}
                  </p>
                  <p>
                    <strong>Contact:</strong> {contact}
                  </p>
                  <p>
                    <strong>Education:</strong> {edu}
                  </p>
                  <p>
                    <strong>Address:</strong> {candidateInfo?.address || "N/A"}
                  </p>
                </div>
              ) : (
                <p>No candidate information found</p>
              )}
              <div
                className="d-flex justify-content-center m-2
              
               about-image"
              >
                {loading ? (
                      <div className="text-center">
                      <span> Loading</span>{" "}
                   <span
                     className="spinner-border spinner-border-sm"
                     role="status"
                     aria-hidden="true"
                   ></span>
                
                 </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-green"
                    onClick={handleButtonClick}
                  >
                    View Resume
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container col-lg-12 col-md-12 col-sm-12 ">
        <div className="row d-flex align-items-center justify-content-between mb-5">
          <div className="col-lg-2 col-2 col-md-2 col-sm-2">
            <button
              className="btn bk-adm back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>

          <div className="col-lg-8 col-8 col-md-8 col-sm-8 text-center align-content-center">
            <h4 className="mb-0"> Test History</h4>
          </div>

          <div className="col-lg-2 col-2 col-md-2 col-sm-2 text-end">
            <Link className="profile-link">
              <div
                className="profile-container"
                data-bs-toggle="modal"
                data-bs-target="#profileModal"
                style={{ cursor: "pointer" }}
              >
                {candidateInfo?.image ? (
                  <img
                    src={
                      candidateInfo?.image
                        ? `${SERVER}/upload/user_image/${candidateInfo?.image}`
                        : "/path/to/default-image.png" 
                    }
                
                    className="profile-icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <FaUserCircle
                    size={50}
                    color="#ccc"
                    className="default-profile-icon"
                  />
                )}
              </div>
              <div>
                <h5
                  className="profile-text justify-content-center"
                  data-bs-toggle="modal"
                  data-bs-target="#profileModal"
                >
                  View Profile
                </h5>{" "}
              </div>
            </Link>
          </div>
        </div>

        <div className="container">
          {isLoading ? (
               <div className="text-center">
               <span> Loading</span>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
         
          </div>
          ) : (
            <div className="row">
              <div className="table-responsive">
                {testByUser.length > 0 ? (
                  <table className="table col-lg-12 col-sm-12 col-md-12">
                    <thead>
                      <tr className="text-center">
                        <th>S.no</th>
                        <th onClick={() => handleSort("testid")}>
                          #Test {renderSortArrow("testid")}
                        </th>
                        <th onClick={() => handleSort("date")}>
                          Date {renderSortArrow("date")}
                        </th>
                        <th onClick={() => handleSort("module_title")}>
                          Module {renderSortArrow("module_title")}
                        </th>
                        <th onClick={() => handleSort("quesLevel")}>
                          Level {renderSortArrow("quesLevel")}
                        </th>
                        <th>Total question</th>
                        <th>Attempted</th>
                        <th onClick={() => handleSort("score")}>
                          Result {renderSortArrow("score")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedTestByUser.map((ob, index) => (
                        <tr key={index} className="text-center">
                          <td>{index + 1}</td>
                          <td>{ob.testid}</td>
                          <td>{formatDate(ob.date)}</td>
                          <td>{ob.module_title}</td>
                          <td>{ob.quesLevel}</td>
                          <td>{ob.totalQ}</td>
                          <td>{ob.totalQues.length}</td>
                          <td>
                            <Link to={"/studentDetail/History/" + ob.testid}>
                              <button
                                className="btn btn-primary"
                                style={{
                                  backgroundColor: "",
                                  height: "auto",
                                  width: "60px",
                                  borderRadius: "10px",
                                }}
                              >
                                {ob.totalQues.reduce(
                                  (x, ob) => (ob.isright === 1 ? x + 1 : x),
                                  0
                                )}
                                /{ob.totalQ}
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">
                    {/* <h4 className="text-muted fw-normal">No records found !</h4> */}
                    <h5 className="text-muted fw-normal">No records found !</h5>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
