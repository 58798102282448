// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-other td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
  text-align: justify;
}
.invalid {
  border: 1px solid red !important;
}

.invalid-feedback {
  color: red;
  font-size: 16px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Resume/Page/Other.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,UAAU;EACV,eAAe;AACjB","sourcesContent":[".table-other td {\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  text-wrap: wrap;\r\n  text-align: justify;\r\n}\r\n.invalid {\r\n  border: 1px solid red !important;\r\n}\r\n\r\n.invalid-feedback {\r\n  color: red;\r\n  font-size: 16px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
