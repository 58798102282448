import { useState } from "react";
import LoginService from "../services/LoginService";
import { Link, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeData, resumeData } from "../reduxdata/UserSlice";
import { toast, ToastContainer } from "react-toastify"; // Import toastify
import "react-toastify/dist/ReactToastify.css";

import "./Login.css";
import Contact from "./Contact";
import GlobalMessage from "./GlobalMessage";
export default function Login(props) {
  const loginUser = useSelector((state) => state.user.value);

  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  // const [emailError, setEmailError] = useState("");
  // const [passwordError, setPasswordError] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  var dispatch = useDispatch();

  var emailbox = undefined;
  var passbox = undefined;

  var login = (event) => {
    event.preventDefault();
    toast.dismiss();
    setMsg(""); // Clear the previous message
    setLoading(true);

    setEmailValid(true);

    var ob = {
      email: emailbox.value,
      password: passbox.value,
    };

    if (!navigator.onLine) {
      toast.error("You are offline. Please check your connection.");
      setLoading(false);
      return;
    }
    const d = dispatch(resumeData(ob));
    // console.log(d);

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ob.email)) {
      setMsg("Please enter your valid email and Password.");
      // setEmailError("Please enter a valid email.");
      // setEmailValid(false);
      setLoading(false);
      // } else if (ob.password.length < 6 && ob.password === "") {
      //   // Check if password is empty
      //   setPasswordError("Password is required.");
      //   setPasswordValid(false);
      //   setLoading(false);
    } else if (ob.password.length < 6) {
      setMsg("Please enter your valid email and Password.");
   
      setLoading(false);
    } else
      LoginService.LoginUser(ob)
        .then((response) => response.json())
        .then((data) => {
          // console.log("data is", data);
          setLoading(false);
          setMsg(data.message);

          // console.log(data);
          // console.log(data.email_verified)

          if (
            data.email_verified === 0 ||
            (data.email_verified === "0" && data.role == "student")
          ) {
            dispatch(
              changeData({
                islogin: false,
              })
            );

            setMsg("Your email is not verified , please verify your email .");
          } else if (data == "Incorrect Username and/or Password!") {
            dispatch(
              changeData({
                islogin: false,
              })
            );

            setMsg(
              "Incorrect username and/or password. Please check your credentials."
            );
          } else if (data.role === "blocked") {
            dispatch(
              changeData({
                islogin: false,
              })
            );
            setMsg("You are no longer able to use this account.");
          } else {
            dispatch(
              changeData({
                username: data.name,
                token: data.token,
                islogin: true,
                userId: data.id,
                testid: data.testid,
                role: data.role,
                email_verified: data.email_verified,
                email: data.email,
                contact: data.contact,
              })
            );
            localStorage.setItem("token", data.token);
          }
        })
        .catch((err) => {
          // console.log(err);
          setMsg("Please type valid email and password.");
          setLoading(false);
          // setMsg(data.message);
        });
  };

  if (loginUser.role === "blocked") {
    dispatch(
      changeData({
        islogin: false,
      })
    );
  } else if (loginUser.islogin && loginUser.role === "student") {
    return <Navigate to="/course" />;
  } else if (loginUser.islogin && loginUser.role === "admin") {
    return <Navigate to="/user" />;
  } else if (loginUser.islogin && loginUser.role === "hr") {
    return <Navigate to="/studentDetail" />;
  } else
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          closeOnClick
          pauseOnHover
          draggable
          theme="light"
        />
        <div className="container pb-5 mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="">
                <h3 className="text-center">Login</h3>
                <form onSubmit={login}>
                  <div className="">
                    <div className="col-lg-4  col-12 offset-lg-4 col-md-12 col-sm-12">
                      <label className="labels mb-0">
                        <b>Email</b>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        ref={(c) => (emailbox = c)}
                        name="email"
                        placeholder="Email"
                        id="email"
                      />
                      {/* {!emailValid && (
                        <div className="invalid-feedback">{emailError}</div>
                      )} */}
                    </div>
                    <br />
                    <div className="col-lg-4 col-12 offset-lg-4 col-md-12 col-sm-12">
                      <label className="labels mb-0">
                        <b>Password</b>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        ref={(c) => (passbox = c)}
                        name="password"
                        placeholder="Password"
                        id="password"
                      />
                      {/* {!passwordValid && (
                        <div className="invalid-feedback">{passwordError}</div>
                      )} */}
                    </div>
                    <div className="col-lg-4 col-12 offset-lg-4 col-md-12 col-sm-12">
                      <Link to="/forgotPassword" className="text-green">
                        Forgot Password ?
                      </Link>
                    </div>
                  </div>
                  <br />
                  <div className="col-lg-6 offset-lg-3 col-md-8 col-sm-8 text-center">
                    <div className="col-lg-4 offset-lg-4 col-5 col-sm-5  offset-3 offset-md-6  offset-sm-6 col-md-8 col-sm-8">
                      <div className="text-center">
                        <button
                          type="submit"
                          className="form-control submit-bt text-center"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <span>Login</span>
                              {""}
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </>
                          ) : (
                            "Login"
                          )}
                        </button>
                      </div>
                    </div>

                    {msg == "login successfully" ? (
                      <>
                        <br></br>
                        <b className="text-success text-center">{msg}</b>
                      </>
                    ) : (
                      <>
                        {" "}
                        <b className="text-danger">{msg}</b>
                      </>
                    )}
                    <div className="col-lg-4 offset-lg-4 col-5 col-sm-5  offset-3 offset-sm-6  offset-md-6 col-md-8 col-sm-8">
                      <div className="text-center mt-3">
                        <Link to="/register" className="lnkbtn">
                          <button className="form-control submit-btns ">
                            Register
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
