import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeData } from "../reduxdata/UserSlice";
import RegisterService from "../services/RegisterService";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS

export default function ForgotPassword() {
  const loginUser = useSelector((state) => state.user.value);
  const [msg, setMsg] = useState("");
  const [errorFields, setErrorFields] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let oldPasswordBox,
    newPasswordBox,
    confirmNewPasswordBox = undefined;

  const validatePassword = (password) => {
    const regex = /^(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return regex.test(password);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Clear error message for the specific field
    setErrorFields((prev) => ({ ...prev, [name]: "" }));
  };

  const handlePasswordUpdate = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    const oldPassword = oldPasswordBox.value.trim();
    const newPassword = newPasswordBox.value.trim();
    const confirmNewPassword = confirmNewPasswordBox.value.trim();
    const errors = {};

    // Validate form inputs
    if (!oldPassword) errors.oldPassword = "Old password is required.";
    if (!newPassword) errors.newPassword = "New password is required.";
    else if (!validatePassword(newPassword))
      errors.newPassword =
        "Password must be at least 6 characters long and include a special character.";
    if (!confirmNewPassword)
      errors.confirmNewPassword = "Please confirm your new password.";
    else if (newPassword !== confirmNewPassword)
      errors.confirmNewPassword =
        "Please ensure that the password and confirm password are the same.";

    // If there are validation errors, display them
    if (Object.keys(errors).length > 0) {
      setErrorFields(errors);
      setMsg("");
      return;
    }

    // Clear error fields
    setErrorFields({});
    const passwordData = {
      oldPassword,
      newPassword,
    };
    // console.log("passwordData,", passwordData);
    try {
      toast.dismiss();

      const response = await RegisterService.updatePassword(
        loginUser.userId,
        passwordData,
        loginUser.token
      );
      // console.log(response);

      if (!response.ok) {
        // Parse the error message from the backend
        const data = await response.json();
        if (response.status === 500) {
          setMsg(
            data.message ||
              "Unable to verify your old password , Please try again carefully "
          );
          setErrorFields({
            ...errorFields,
            oldPassword:
              "Your old password is incorrect ,please enter correct password .",
          });
        } else {
          setMsg("Failed to update password.");
        }
        return;
      }

      // if (!response.ok) {
      //   const errorData = await response.json(); // Parse error response
      //   if (response.status === 400) {
      //     setMsg("Please provide valid password data.");
      //     setErrorFields({
      //       ...errorFields,
      //       oldPassword:
      //         "Unable to verify your old password , Please try again carefully.",
      //     });
      //   } else if (response.status === 401) {
      //     setMsg("Unauthorized. Please check your token.");
      //   } else if (response.status === 500) {
      //     setMsg(
      //       "Server error: Unable to update password. Please try again later."
      //     );
      //   } else {
      //     setMsg(errorData.message || "Failed to update password.");
      //   }
      //   return;
      // }

      const data = await response.json(); // Parse success response
      // setMsg("Password updated successfully. Please log in again.");
      toast.success("Password updated successfully. Please log in again.");

      // Logout the user and navigate after 2 seconds
      setTimeout(() => {
        dispatch(changeData({ islogin: false }));
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("CandidateInfo");
        localStorage.removeItem("Project Data");
        localStorage.removeItem("achievementData");
        localStorage.removeItem("candidate");
        localStorage.removeItem("educationData");
        localStorage.removeItem("exp_id");
        localStorage.removeItem("experienceData");
        localStorage.removeItem("project Data");
        localStorage.removeItem("resumeData");
        navigate("/login");
      }, 2000);
    } catch (err) {
      setMsg(`An error occurred: ${err.message}`);
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
      <div className="container pb-5 ">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="">
              <h3 className="text-center">Change Password</h3>
              <form onSubmit={handlePasswordUpdate}>
                <div className="">
                  <div className="col-lg-6 col-12 offset-lg-3 col-md-12 col-sm-12">
                    <p>
                      Enter your old password and your new password twice to
                      confirm.
                    </p>
                    <label className="labels mb-0">
                      <b>Old Password</b>
                    </label>
                    <input
                      type="password"
                      name="oldPassword"
                      onChange={handleInputChange}
                      className={`form-control ${
                        errorFields.oldPassword ? "border-danger" : ""
                      }`}
                      ref={(c) => (oldPasswordBox = c)}
                      placeholder="Enter your old password"
                    />
                    {errorFields.oldPassword && (
                      <div className="text-danger mt-1">
                        {errorFields.oldPassword}
                      </div>
                    )}
                    <br />
                    <label className="labels mb-0">
                      <b>New Password</b>
                    </label>
                    <input
                      type="password"
                      name="newPassword"
                      onChange={handleInputChange}
                      className={`form-control ${
                        errorFields.newPassword ? "border-danger" : ""
                      }`}
                      ref={(c) => (newPasswordBox = c)}
                      placeholder="Create a new password"
                    />
                    {errorFields.newPassword && (
                      <div className="text-danger mt-1">
                        {errorFields.newPassword}
                      </div>
                    )}
                    <br />
                    <label className="labels mb-0">
                      <b>Confirm New Password</b>
                    </label>
                    <input
                      type="password"
                      name="confirmNewPassword"
                      onChange={handleInputChange}
                      className={`form-control ${
                        errorFields.confirmNewPassword ? "border-danger" : ""
                      }`}
                      ref={(c) => (confirmNewPasswordBox = c)}
                      placeholder="Re-enter the new password"
                    />
                    {errorFields.confirmNewPassword && (
                      <div className="text-danger mt-1">
                        {errorFields.confirmNewPassword}
                      </div>
                    )}
                  </div>
                  <br />
                </div>
                <br />
                <div className="col-lg-2 offset-lg-5 col-6 offset-3 col-md-8 col-sm-8 text-center">
                  <button
                    type="submit"
                    className="form-control btn-md submit-bt text-center"
                  >
                    Update Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
