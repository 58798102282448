
const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;


class HrService {
  constructor() {
    // Retrieve the token from localStorage or another source
    this.token = localStorage.getItem("token"); // Adjust as needed
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  addFavorite(data, token) {
    return fetch(`https://backend.skilledfresher.in/api/hr/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(data),
    });
  }

  addShortlisted(data, token) {
    return fetch(`https://backend.skilledfresher.in/api/hr/saveShortlisted
`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(data),
    });
  }

  // New updateShortlisted method
  // updateShortlisted(data, id, token) {
  //   return fetch(`https://backend.skilledfresher.in/api/hr/updateShortlist/${id}`, {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ` + token,
  //     },
  //     body: JSON.stringify(data),
  //   });
  // }


  shortlisted(emp_hr_id, token) {
    return fetch(`https://backend.skilledfresher.in/api/hr/shortlistbyhrid/${emp_hr_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    }).then((response) => response.json());
  }

  deleteShortlisted(data,id, token) {
    return fetch(
      `https://backend.skilledfresher.in/api/hr/removeShortlist/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + token,
        },
      }
    );
  }

  favourtList(hruserid, token) {
    return fetch(`https://backend.skilledfresher.in/api/hr/list/${hruserid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    }).then((response) => response.json());
  }

  dleteFav(id, candidate_id, token) {
    return fetch(
      `https://backend.skilledfresher.in/api/hr/remove/${id}/${candidate_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + token,
        },
      }
    );
  }

  searchCandidate(query, token) {
    return fetch(
      `https://backend.skilledfresher.in/api/hr/search/candidate?q=${query}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  searchFavoriteUser(query, token) {
    return fetch(
      `https://backend.skilledfresher.in/api/hr/favorites/search?q=${query}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + token,
        },
      }
    );
  }
}

export default new HrService();
