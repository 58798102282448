// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position-relative1 {
  position: relative;
}

.clear-input-icon1 {
  position: absolute;
  right: 20px;
  top: 48%;
  transform: translateY(-50%);
  cursor: pointer;
  color:#333;
}
@media (max-width: 768px) {
.srch{
margin-top: 5px;
}
}
.clear-input-icon1.move-icon1 {
  top: 25%;
}


.custom-alert {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.dropdown-menu {
  min-width: 200px;
  background-color: #f8f9fa;
  position: relative;
}

.dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  color: #495057;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #e9ecef;
}

.dropdown-item:active {
  background-color: #d6d8db;
}

.dropdown-item .me-2 {
  margin-right: 8px;
}


@media (min-width: 768px) {
  .table-responsive {
      overflow: inherit;
  }
}
.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static;
}`, "",{"version":3,"sources":["webpack://./src/components/hr/StdDtl.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;EAC3B,eAAe;EACf,UAAU;AACZ;AACA;AACA;AACA,eAAe;AACf;AACA;AACA;EACE,QAAQ;AACV;;;AAGA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;;AAGA;EACE;MACI,iBAAiB;EACrB;AACF;AACA;;;IAGI,gBAAgB;AACpB","sourcesContent":[".position-relative1 {\r\n  position: relative;\r\n}\r\n\r\n.clear-input-icon1 {\r\n  position: absolute;\r\n  right: 20px;\r\n  top: 48%;\r\n  transform: translateY(-50%);\r\n  cursor: pointer;\r\n  color:#333;\r\n}\r\n@media (max-width: 768px) {\r\n.srch{\r\nmargin-top: 5px;\r\n}\r\n}\r\n.clear-input-icon1.move-icon1 {\r\n  top: 25%;\r\n}\r\n\r\n\r\n.custom-alert {\r\n  max-width: 400px;\r\n  margin: 0 auto;\r\n  text-align: center;\r\n}\r\n\r\n.dropdown-menu {\r\n  min-width: 200px;\r\n  background-color: #f8f9fa;\r\n  position: relative;\r\n}\r\n\r\n.dropdown-item {\r\n  font-size: 14px;\r\n  padding: 10px 15px;\r\n  color: #495057;\r\n  cursor: pointer;\r\n}\r\n\r\n.dropdown-item:hover {\r\n  background-color: #e9ecef;\r\n}\r\n\r\n.dropdown-item:active {\r\n  background-color: #d6d8db;\r\n}\r\n\r\n.dropdown-item .me-2 {\r\n  margin-right: 8px;\r\n}\r\n\r\n\r\n@media (min-width: 768px) {\r\n  .table-responsive {\r\n      overflow: inherit;\r\n  }\r\n}\r\n.table-responsive .dropdown,\r\n.table-responsive .btn-group,\r\n.table-responsive .btn-group-vertical {\r\n    position: static;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
