import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import withRouter from "../withRouter";
import { Link, useNavigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import HrService from "../../services/HrService";
import UseTemp1 from "../Resume/Template/UseTemp1";
import ResumeService, { urls } from "../../services/ResumeService";
import RegisterService from "../../services/RegisterService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaHeartBroken, FaStar,FaFileAlt } from 'react-icons/fa';


import "./StdDtl.css";

function ViewStudentDetails({ props }) {
  const loginUser = useSelector((state) => state.user.value);
  const hr = useState(loginUser.userId);
  const [user, setUser] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;
  const [showFavoritesTable, setShowFavoritesTable] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [candidate, setCandidate] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [msg, setMsg] = useState("");
  const [searchFav, setSearchFav] = useState(false);
const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [edu, setEdu] = useState("");
     const [showProfileModal, setShowProfileModal] = useState(false);
      const [candidateInfo, setCandidateInfo] = useState(null);
        const { userid } = useParams();
        const [isShortlisted, setIsShortlisted] = useState(false);
  
      const fetchProfile = async (userid) => {
        // console.log("Fetching profile for user ID:", userid);
  
        try {
          if (loginUser && userid) {
            const response = await RegisterService.profile(
              userid,
              loginUser.token
            );
            // console.log("my res",response);
            // console.log(response?.user?.name)
         
            setUid(response?.user?.userid);
            const name = response?.user?.name;
            setName(name);
            const contact = response?.user?.contact;
            setContact(contact);
            const email = response?.user?.email;
            setEmail(email);
            const Education = response?.user?.education;
            setEdu(Education);
  
            if (response.status) {
              setCandidateInfo(response?.candidateInfo);
              // console.log(candidateInfo?.person);
            } else {
              // console.log("Profile not found");
            }
          } else {
          }
        } catch (error) {
          // console.error("Error fetching profile:", error);
        }
      };
    useEffect(() => {
  
      fetchProfile();
    }, [loginUser?.token && userid]);

  const result = (userid) => {
    navigate("/studentDetail/StudentTestRecord/" + userid);
  };

  const removeFav = async (favorite) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to Remove from Favorites?"
    );
    if (confirmDelete) {
      setIsLoading(true);
      setFavorites((prevFavorites) =>
        prevFavorites.filter((fav) => fav.id !== favorite.id)
      );
      setUser((prevUser) =>
        prevUser.map((user) =>
          user.userid === favorite.userid ? { ...user, favstatus: false } : user
        )
      );
      toast.dismiss();

      await HrService.dleteFav(favorite.id, favorite.userid, loginUser.token)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            // setMsg(data.message);
            toast.success(data.message);
            setTimeout(() => {
              setMsg("");
            }, 2000);
          } else {
            // setMsg(data.message);
            toast.error(data.message);

            setFavorites((prevFavorites) => [...prevFavorites, favorite]);
            setUser((prevUser) =>
              prevUser.map((user) =>
                user.userid === favorite.userid
                  ? { ...user, favstatus: true }
                  : user
              )
            );
            setTimeout(() => {
              setMsg("");
            }, 2000);
          }
        })
        .catch((error) => {
          setMsg("Error removing favorite.");
          setFavorites((prevFavorites) => [...prevFavorites, favorite]);
          setUser((prevUser) =>
            prevUser.map((user) =>
              user.userid === favorite.userid
                ? { ...user, favstatus: true }
                : user
            )
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleSearch = () => {
    if (!searchValue) {
      setShowValidationMessage(true);
      setValidationMessage("Please enter email or contact.");
      return;
    }
    setSearchFav(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (searchValue && !isNaN(searchValue)) {
      if (!phoneRegex.test(searchValue)) {
        setShowValidationMessage(true);
        setValidationMessage("Contact number must be 10 digits.");
        return;
      }
    } else if (!emailRegex.test(searchValue)) {
      setShowValidationMessage(true);
      setValidationMessage("Please enter a valid email.");
      return;
    }
    toast.dismiss();
    HrService.searchFavoriteUser(searchValue, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.user && data.user.user && data.user.user.length > 0) {
          setFavorites(data.user.user);
          setShowFavoritesTable(false);
          setMsg("");

          // console.log("my search", data.user);
        } else if (data.status == false) {
          // console.log(data);
          //   setUser([]);
          //   setFavorites([]);

          toast.error("No matching records.");
        
        }
      })
      .catch((error) => {
        setUser([]);
        setIsLoading(false);
      });
  };

  const handleCancelSearch = () => {
    setSearchValue("");
    favourts(hr);
    setUser([]); 
    setShowFavoritesTable(false); 
    setIsLoading(false); 
    setShowValidationMessage(false);
    setSearchFav(false);
  };
  const token = localStorage.getItem("token");
  const favourts = async (hr) => {
    setIsLoading(true);
    try {
      const response = await HrService.favourtList(hr[0], token);
      if (
        response &&
        response.user &&
        response.user.user &&
        response.user.user.length > 0
      ) {
        setFavorites(response.user.user);
        // console.log(" My Fav candidate list response", response);
        setUser([]);
      } else {
        setFavorites([]);
        setShowFavoritesTable(false);
      }
      setIsLoading(false);
    } catch (error) {
      // console.error("Error fetching favorites:", error);
      setFavorites([]);
      setIsLoading(false);
    }
    // console.log("Faverite", favorites);
  };

  useEffect(() => {
    setSearchValue("");
    setValidationMessage("");
    setShowValidationMessage(false);

    favourts(hr);
  }, [location]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value) {
      setShowValidationMessage(false);
    }
  };

 
  const logindata = useSelector((state) => state.user.value);


    const handleButtonClick = async () => {
      const candidateUserId = candidateInfo?.person;
      // Check if the candidateUserId is undefined or null
      if (!candidateUserId) {
        toast.error("No Resume Found"); 
        return; 
      }
  
      try {
        // console.log("User resume ID:", candidateUserId);
        // console.log("Login Token:", logindata?.token);
        toast.dismiss();
        setIsLoading(true)
  
        const fetchUrl = `${urls.Candidate_Details}/${candidateUserId}`;
        const response = await ResumeService.GetApiCallWithToken(
          fetchUrl,
          logindata.token
        );
  
        // console.log("Fetch URL:", fetchUrl);
        // console.log("my candidate response", response.data);
        // console.log("API resume Response:", response);
        const candidateInfo = response?.data?.data;
        // Check if candidates array is empty
        if (candidateInfo.candidates && candidateInfo.candidates.length === 0) {
          alert("No Resume Found");
  
        } else if (response?.data?.status) {
          // console.log("Candidate Info:", candidateInfo);
          setCandidate(candidateInfo);
          localStorage.setItem("candidate", JSON.stringify(candidateInfo));
          setShowProfileModal(false);
  
          const modal = document.querySelector(".modal");
          const backdrop = document.querySelector(".modal-backdrop");
  
          if (modal) {
            modal.classList.remove("show"); 
            modal.style.display = "none"; 
          }
  
          if (backdrop) {
            backdrop.classList.remove("show");
            document.body.classList.remove("modal-open"); 
            backdrop.remove(); 
          }
  
          document.body.style.overflow = "";
  
          if (candidateInfo.candidates[0].template === "use1") {
            navigate("/resumehome/use1");
          } else if (candidateInfo.candidates[0].template === "use2") {
            navigate("/resumehome/use2");
          } else if (candidateInfo.candidates[0].template === "use3") {
            navigate("/resumehome/use3");
          } else if (candidateInfo.candidates[0].template === "use4") {
            navigate("/resumehome/use4");
          } else if (candidateInfo.candidates[0].template === "use5") {
            navigate("/resumehome/use5");
          } else if (candidateInfo.candidates[0].template === "use6") {
            navigate("/resumehome/use6");
          } else if (candidateInfo.candidates[0].template === "use7") {
            navigate("/resumehome/use7");
          } else if (candidateInfo.candidates[0].template === "use8") {
            navigate("/resumehome/use8");
          } else if (candidateInfo.candidates[0].template === "use9") {
            navigate("/resumehome/use9");
          }
        }
      } catch (error) {
        setMsg("Error fetching candidate data. Please try again.");
        // console.error("Error fetching candidate data:", error);
      } finally {
        setIsLoading(false);
      }
    };

  const isNestedRoute =
    location.pathname.startsWith("/studentDetail/") &&
    location.pathname !== "/studentDetail";


    const handleShortlist = async (id, isCurrentlyShortlisted) => {
      if (isCurrentlyShortlisted === false) {
    setIsLoading(false);
  }
    
      const data = {
        emp_hr_id: loginUser.userId,
        candidate_id: id,
        shortlisted: !isCurrentlyShortlisted, 
      };
    
      try {
      
        const response = await HrService.addShortlisted(data, loginUser.token);
          const result = await response.json();
          if (result.success==true) {
            setIsShortlisted(!isCurrentlyShortlisted);  
            toast.success(result.message); 
            setIsLoading(false)
          } else {
            toast.error(result.message);
            setIsLoading(false)
          }
  
      } catch (error) {

      } finally {
        setIsLoading(false);
      }
    };
    
    



  return (
    <>
    
<div className="modal fade"
        id="profileModal"
        tabIndex="-1"
        aria-labelledby="profileModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="profileModalLabel">
                Candidate Profile
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
  <div className="candinfo">
    {name && (
      <p>
        <strong>Name:</strong> {name}
      </p>
    )}
    {email && (
      <p>
        <strong>Email:</strong> {email}
      </p>
    )}
    {contact && (
      <p>
        <strong>Contact:</strong> {contact}
      </p>
    )}
    {edu && (
      <p>
        <strong>Education:</strong> {edu}
      </p>
    )}
    {candidateInfo?.address && (
      <p>
        <strong>Address:</strong> {candidateInfo?.address}
      </p>
    )}
  </div>

  <div className="d-flex justify-content-center m-2 about-image">
    {isLoading ? (
      <div className="text-center">
         <span> Loading</span>{" "}
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
     
    </div>
    ) : (
      <button
        type="button"
        className="btn btn-green"
        onClick={handleButtonClick}
      >
        View Resume
      </button>
    )}
  </div>
</div>

          </div>
        </div>
      </div>
      
      {!isNestedRoute && (
        <div className="container ">
          <ToastContainer position="top-center" autoClose={2000} />
          {!(user?.length === 0 && favorites?.length === 0 && !isLoading) && (
            <div className="form-group row justify-content-center ">
              <div className="col-sm-3 col-6  col-lg-2 col-md-3 text-right position-relative1 order-lg-1 order-2 order-md-1 order-sm-2">
                <input
                  type="text"
                  className={`form-control position-relative ${
                    showValidationMessage ? "is-invalid" : ""
                  }`}
                  placeholder="Email Or Contact"
                  value={searchValue}
                  onChange={handleSearchChange}
                />
                {showValidationMessage && (
                  <div className="invalid-feedback">{validationMessage}</div>
                )}

                {searchValue && (
                  <span
                    className={`clear-input-icon1 ${
                      showValidationMessage ? "move-icon1" : ""
                    }`}
                    onClick={handleCancelSearch}
                  >
                    <i className="fa fa-times" />
                  </span>
                )}
              </div>

              <div className="col-sm-2 col-2 col-lg-2 col-md-2 p-lg-1 p-md-1 order-lg-1 order-2 order-md-1 order-sm-2 ">
                <button
                  className="btn btn-green  srch"
                  onClick={handleSearch}
                >
                  Search
                </button>
                &nbsp;
              </div>

              <div className="col-sm-4 col-lg-5 col-md-4 align-content-center order-lg-2 order-1 order-md-2 order-sm-1">
                <h4 className="no-wrap text-center">
                  My Favorite Candidate's List
                </h4>
              </div>
              <div className="col-sm-3 col-lg-3 col-md-3 order-3">
                <Link to="/studentDetail/addFavorite">
                  <div className="button-container">
                    <button className="btn btn-green bt-l">Add New</button>
                  </div>
                </Link>
              </div>
            </div>
          )}

          {/* Heading will always be displayed */}
          {user?.length === 0 && favorites?.length === 0 && !isLoading && (
            <div className="row mt-5">
              <div className="col-sm-12 col-lg-12 col-md-12 text-center">
                <h4 className="no-wrap">My Favorite Candidate's List</h4>
              </div>
            </div>
          )}

          <div>{msg}</div>

          {favorites?.length > 0 && (
            <div className="table-responsive mt-5">
              <table className="table table-bordered">
                <thead>
                  <tr className="text-center">
                    <th style={{ whiteSpace: "nowrap" }}>S.no</th>
                    <th style={{ whiteSpace: "nowrap" }}>Name</th>
                    <th style={{ whiteSpace: "nowrap" }}>Education</th>
                    <th style={{ whiteSpace: "nowrap" }}>Contact</th>
                    <th style={{ whiteSpace: "nowrap" }}>Email</th>
                    {/* <th style={{ whiteSpace: "nowrap" }}>Test</th> */}
                    <th style={{ whiteSpace: "nowrap" }}>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {favorites?.map((fav, index) => (
                    <tr key={index} className="text-center">
                      <td style={{ whiteSpace: "nowrap" }}>{index + 1}</td>
                      <td style={{ whiteSpace: "nowrap" }} className="profile-text justify-content-center"
                      data-bs-toggle="modal"
                       data-bs-target="#profileModal">
                        <span
                        style={{
                          cursor: "pointer",
                          color: "#1ABC9C",
                          textDecoration: "underline",
                        }}
                    
                        onClick={() => {
                          fetchProfile(fav.userid);  
                        }}
                        >
                          {fav.name}
                        </span>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>{fav.education}</td>
                      <td style={{ whiteSpace: "nowrap" }}>{fav.contact}</td>
                      <td style={{ whiteSpace: "nowrap" }}>{fav.email}</td>
                   

<td style={{ whiteSpace: "nowrap"}}>
  <div className="dropdown">
    <button
      className="btn btn-light dropdown-toggle"
      type="button"
      id={`dropdownMenuButton${index}`}
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
      }}
    >
      Actions
      <i className="bi bi-three-dots"></i>
    </button>
    <ul
      className="dropdown-menu dropdown-menu-end text-center"
      aria-labelledby={`dropdownMenuButton${index}`}
     
    >
      {/* Remove Favorite Option */}
      <li className="my-1">
        <button
          className="dropdown-item d-flex align-items-center  btn-sm text-center"
          onClick={() => removeFav(fav)}
          style={{
            backgroundColor: "#6c757d",
            color: "white",
            borderRadius: "10px",
            width: "90%",
            margin: "auto",
          }}
          disabled={isLoading}
        >
          <FaHeartBroken className="me-2" />
          Remove Favorite
        </button>
      </li>

 
<li className="my-1">
  <button
    className="dropdown-item d-flex align-items-center btn-sm text-center"
    onClick={() => handleShortlist(fav.userid, isShortlisted)}
    style={{
      backgroundColor: "#1ABC9C",
      color: "white",
      borderRadius: "10px",
      width: "90%",
      margin: "auto",
    }}
  >
    <FaStar className="me-2" />
  Shortlist 
  </button>
</li>

       <li className="my-1">
                                        <button
                                          className="dropdown-item d-flex align-items-center  btn-sm text-center"
                                          onClick={() => result(fav.userid)}
                                          style={{
                                            backgroundColor: "#28a745",
                                            color: "white",
                                            borderRadius: "10px",
                                            width: "90%",
                                            margin: "auto",
      
                                          }}
                                        >
                                          <FaFileAlt className="me-2" /> Test History
                                        </button>
                                      </li>
      
    </ul>
  </div>
</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {user?.length === 0 && favorites?.length === 0 && !isLoading && (
            <div className="text-center mt-4">
              <p>
                Your favorites list is currently empty. &nbsp;
                <Link
                  to="/studentDetail/addFavorite"
                  style={{ color: "#1ABC9C", fontWeight: "bold" }}
                >
                  <button className="btn btn-green ">Add New</button>
                </Link>{" "}
                candidates to your list to get started.
              </p>
            </div>
          )}

          {isLoading && (
              <div className="text-center">
              <span> Loading</span>{" "}
           <span
             className=" spinner-border spinner-border-sm"
             role="status"
             aria-hidden="true"
           ></span>
          
         </div>
          )}
        </div>
      )}

      <Outlet />
    </>
  );
}

export default withRouter(ViewStudentDetails);
