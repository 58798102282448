import cv6 from "../../../Assets/Images/format4.png";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import "./Template.css";

const Temp17 = () => {
  const loginUser = useSelector((state) => state.user.value);
  const [showModal, setShowModal] = useState(false);
  const isUser = JSON.parse(localStorage.getItem("user"));
  const [infoform, setInfoForm] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchUserInfo = async () => {
    if (!loginUser?.userId) {
    }

    try {
      setLoading(true);
      const url = `${urls.Show_Info}/${loginUser.userId}`;
      const response = await ResumeService.GetApiCallWithToken(url);
      localStorage.setItem("CandidateInfo", JSON.stringify(response));
      if (!loginUser) {
        navigate("/"); // Navigate to login if not logged in
      } else if (response.data.data == null) {
        navigate("/resumehome/addinfo"); // If user doesn't have info, navigate to Add Info page
      } else {
        navigate("/resumehome/information"); // If user has info, navigate to Info page
      }
    } catch (error) {
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleTemplateClick = () => {
    if (isUser?.islogin) {
      handleShowModal();
    } else {
      // Redirect to the login page if the user is not logged in
      window.location.href = "/";
    }
  };

  return (
    <>
      <div className="container hover-area">
        <img
          src={cv6}
          alt=""
          width="350"
          height="450"
          style={{ borderRadius: "10px", cursor:"pointer" }}
          className="image_template"
          onClick={handleTemplateClick}
        />
        <div style={{ position: "relative" }}>
          {isUser?.islogin ? (
            <button
              className="button1  rounded-corners use"
              style={{
                borderRadius: "10px",
                position: "absolute",
                bottom: "26rem",
                left: "75%",
               transform: "translateX(-50%)",
              }}
              onClick={fetchUserInfo}
              disabled={loading}
            >
              {loading ? (
                <div className="text-center" role="status">
                  Loading{" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "USE THIS"
              )}
            </button>
          ) : (
            <button
              className="button1  rounded-corners use"
              style={{
                borderRadius: "10px",
                position: "absolute",
                bottom: "26rem",
                left: "75%",
               transform: "translateX(-50%)",
              }}
            >
              <Link to="/">
                {loading ? (
                  <div className="text-center" role="status">
                    Loading{" "}
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  "USE THIS"
                )}
              </Link>
            </button>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Resume sample</Modal.Title>
          <div className="ms-5">
            {isUser?.islogin ? (
              <Button
                variant="primary"
                className="btn-md button1  rounded-corners "
                onClick={fetchUserInfo}
                disabled={loading}
                style={{ backgroundColor: "#1ABC9C", borderColor: "#16A085" }}
              >
                {loading ? (
                  <>
                    Loading{" "}
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Use This Template"
                )}
              </Button>
            ) : (
              <Button
                variant="secondary"
                className="btn-md button1  rounded-corners"
                onClick={() => (window.location.href = "/")}
                style={{ backgroundColor: "#E74C3C", borderColor: "#C0392B" }}
              >
                {loading ? (
                  <>
                    Loading{" "}
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Use This Template"
                )}
              </Button>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <img
            src={cv6}
            alt="Template 1"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button> */}
          {/* <Button variant="primary">
            <Link to="/LoginForm" style={{ color: 'white', textDecoration: 'none' }}>USE THIS TEMPLATE</Link>
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Temp17;
