// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position-relative {
  position: relative;
}

.clear-input-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color:#333;
}
.table-container {
  margin-top: 20px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 60%;
  max-width: 600px; 
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  z-index: 1060; 
}

.close-popup-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #aaa;
}

.close-popup-btn:hover {
  color: #333;
}

.close-popup-btn i {
  font-size: 24px; 
}
.clear-input-icon.move-icon {
  top: 17%;
  right: 9px;
}



`, "",{"version":3,"sources":["webpack://./src/components/hr/AddFav.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,eAAe;EACf,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,2CAA2C;EAC3C,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;AACA;EACE,QAAQ;EACR,UAAU;AACZ","sourcesContent":[".position-relative {\r\n  position: relative;\r\n}\r\n\r\n.clear-input-icon {\r\n  position: absolute;\r\n  right: 8px;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n  cursor: pointer;\r\n  color:#333;\r\n}\r\n.table-container {\r\n  margin-top: 20px;\r\n}\r\n\r\n.popup-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background: rgba(0, 0, 0, 0.5);\r\n  z-index: 1050;\r\n}\r\n\r\n.popup-content {\r\n  background-color: white;\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n  width: 60%;\r\n  max-width: 600px; \r\n  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);\r\n  text-align: center;\r\n  position: relative;\r\n  z-index: 1060; \r\n}\r\n\r\n.close-popup-btn {\r\n  position: absolute;\r\n  top: 10px;\r\n  right: 10px;\r\n  background: none;\r\n  border: none;\r\n  font-size: 18px;\r\n  cursor: pointer;\r\n  color: #aaa;\r\n}\r\n\r\n.close-popup-btn:hover {\r\n  color: #333;\r\n}\r\n\r\n.close-popup-btn i {\r\n  font-size: 24px; \r\n}\r\n.clear-input-icon.move-icon {\r\n  top: 17%;\r\n  right: 9px;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
