import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import withRouter from "../withRouter";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HrService from "../../services/HrService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import "./AddFav.css";

function AddFavorite({ props }) {
  const loginUser = useSelector((state) => state.user.value);
  const [user, setUser] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const itemsPerPage = 10;
  const [showFavoritesTable, setShowFavoritesTable] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  const result = (userid) => {
    navigate("/StudentTestRecord/" + userid);
  };

  const addFavorite = (hruserid, candidate_id) => {
    setIsLoading(true);
    setUser((prevUser) =>
      prevUser.map((user) =>
        user.userid === candidate_id ? { ...user, favstatus: true } : user
      )
    );

    HrService.addFavorite({ hruserid, candidate_id }, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        toast.dismiss();
        if (data.success) {
          toast.success(data.message, { autoClose: 2000 });

          navigate("/studentDetail");
        } else {
          toast.error(data.message || "Already added to Favourites.", {
            autoClose: 2000,
          });
          setUser((prevUser) =>
            prevUser.map((user) =>
              user.userid === candidate_id
                ? { ...user, favstatus: false }
                : user
            )
          );
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Error adding favorite.", { autoClose: 2000 });

        setUser((prevUser) =>
          prevUser.map((user) =>
            user.userid === candidate_id ? { ...user, favstatus: false } : user
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = () => {
    if (!searchValue) {
      setShowValidationMessage(true);
      setValidationMessage("Please enter email or contact.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    if (searchValue && !isNaN(searchValue)) {
      if (!phoneRegex.test(searchValue)) {
        setShowValidationMessage(true);
        setValidationMessage("Contact number must be 10 digits.");
        return;
      }
    } else if (!emailRegex.test(searchValue)) {
      setShowValidationMessage(true);
      setValidationMessage("Please enter a valid email.");
      return;
    }
    setIsLoading(true);
    setSearchExecuted(true);

    HrService.searchCandidate(searchValue, loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.user && data.user.user && data.user.user.length > 0) {
          setUser(data.user.user);

          setShowFavoritesTable(false);
        } else {
          setUser([]);
        }
        setIsLoading(false);
        setSearchValue("");
      })
      .catch((error) => {
        // console.error("Error searching users:", error);
        setUser([]);
        setIsLoading(false);
      });
  };

  const handleCancelSearch = () => {
    setSearchValue("");
    setUser([]); 
    setShowFavoritesTable(false);
    setIsLoading(false);
    setShowValidationMessage(false);
    setValidationMessage(false);
    setSearchExecuted(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value) {
      setShowValidationMessage(false);
      setValidationMessage(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-3 col-md-3 col-2 col-sm-2">
            <button
              className="btn bk-hr back-button"
              onClick={() => {
                navigate(-1);
                window.scrollTo(0, 0);
              }}
            >
              Back
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-10 col-sm-10 text-center align-content-center">
            <h4>Search and Add Candidates</h4>
          </div>
          <div className="col-sm-2 col-lg-3 col-8 col-md-2 text-right d-flex justify-content-end">
            <Link to="/studentDetail">
              <button className="btn btn-green  ">My Candidates</button>
            </Link>
          </div>
        </div>

        <br />

        <div className="form-group row d-flex ">
          <div className=" col-lg-4 col-md-4 col-2 col-sm-2 "></div>

          <div className="col-sm-6 col-6 col-lg-3 col-md-3 position-relative justify-content-center">
            <div className="position-relative">
              <input
                type="text"
                className={`form-control ${
                  showValidationMessage ? "is-invalid" : ""
                }`}
                placeholder="Email Or Contact"
                value={searchValue}
                onChange={handleSearchChange}
              />
              {showValidationMessage && (
                <div className="invalid-feedback">{validationMessage}</div>
              )}
              {searchValue && (
                <span
                  className={`clear-input-icon ${
                    showValidationMessage ? "move-icon" : ""
                  }`}
                  onClick={handleCancelSearch}
                >
                  <i className="fa fa-times" />
                </span>
              )}
            </div>
          </div>

          <div className="col-sm-3 col-3 col-lg-2 col-md-3 col-3 p-1">
            <button className="btn btn-md btn-green" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>

        {isLoading ? (
            <div className="text-center">
            <span> Loading</span>{" "}
         <span
           className="spinner-border spinner-border-sm"
           role="status"
           aria-hidden="true"
         ></span>
        
       </div>
        ) : (
          <>
            {user?.length > 0 && !showFavoritesTable && (
              <div className="table-responsive mt-5 mb-5">
                <table className="table table-bordered">
                  <thead>
                    <tr className="text-center">
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Education</th>
                      <th>Contact</th>
                      <th>Email</th>
                      {/* <th>Test</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user?.map((ob, index) => (
                      <tr key={index} className="text-center">
                        <td>{index + 1}</td>
                        <td>{ob.name}</td>
                        <td>{ob.education}</td>
                        <td>{ob.contact}</td>
                        <td>{ob.email}</td>

                        <td className="text-center">
                          <button
                            className="btn btn-md btn-secondary text-center"
                            style={{ borderRadius: "10px" }}
                            onClick={() =>
                              addFavorite(loginUser.userId, ob.userid)
                            }
                            disabled={isLoading}
                          >
                            Make Favourite
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {searchExecuted && user?.length === 0 && (
              <div className="text-center mt-5">
                <p>No Matching Records</p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default withRouter(AddFavorite);
