import React, { useState, useEffect } from "react";
import TestService from "../../services/TestService";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify"; // Import toastify
import "react-toastify/dist/ReactToastify.css";

export default function TestHistory(props) {
  const [testByUser, setTestByUser] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const loginUser = useSelector((state) => state?.user?.value);

  useEffect(() => {
    const userid = loginUser.userId;

    TestService.getTestAnswer(userid, loginUser.token)
      .then((response) => {
        if (!response.ok) {
          // throw new Error("Failed to fetch data: " + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setTestByUser(data?.testHistory?.test);
        // console.log("zsdfgh", data?.testHistory?.test);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching test data:", error);
        // toast.error("You are offline. Please check your connection.");
        setIsLoading(false);
      });
  }, [loginUser]); // Make sure this effect re-runs when loginUser changes

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const renderSortArrow = (column) => {
    if (column === sortColumn) {
      return sortDirection === "asc" ? (
        <span style={{ cursor: "pointer" }}>&#x25B2;</span>
      ) : (
        <span style={{ cursor: "pointer" }}>&#x25BC;</span>
      );
    }
    return <span style={{ cursor: "pointer" }}>&#x2195;</span>; // Double arrow icon indicating sortable column
  };

  const sortedTestByUser = testByUser?.sort((a, b) => {
    // Sorting by test score (highest score first)
    const scoreA = a.totalQues.reduce(
      (x, ob) => (ob.isright === 1 ? x + 1 : x),
      0
    );
    const scoreB = b.totalQues.reduce(
      (x, ob) => (ob.isright === 1 ? x + 1 : x),
      0
    );

    if (sortColumn === "score") {
      return sortDirection === "asc" ? scoreA - scoreB : scoreB - scoreA;
    }

    if (sortColumn === "testid") {
      return sortDirection === "asc"
        ? a.testid - b.testid
        : b.testid - a.testid;
    } else if (sortColumn === "date") {
      return sortDirection === "asc"
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    } else if (sortColumn === "module_title") {
      return sortDirection === "asc"
        ? a.module_title.localeCompare(b.module_title)
        : b.module_title.localeCompare(a.module_title);
    } else if (sortColumn === "quesLevel") {
      return sortDirection === "asc"
        ? parseInt(a.quesLevel) - parseInt(b.quesLevel)
        : parseInt(b.quesLevel) - parseInt(a.quesLevel);
    }
    return 0;
  });

  return (
    <>
      <div className="container col-lg-12 col-md-12 col-sm-12">
        <div className="row d-flex align-items-center justify-content-between mt-5 mb-5">
          <div className="col-lg-2 col-2 col-md-2 col-sm-2col-lg-2">
            <button
              className="btn bk-adm back-button"
              onClick={() => navigate("/course")}
            >
              Back
            </button>
          </div>

          <div className="col-lg-8 col-8 col-md-8 col-sm-8 text-center">
            <h3 className="mb-0">Your Test History</h3>
          </div>

          <div className="col-lg-2 col-2 col-md-2 col-sm-2 text-end">
            {/* You can add another button or content here if needed */}
          </div>
        </div>

        <div className="container" >
          {isLoading ? (
            <div className="text-center" role="status">
              Loading{" "}
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          ) : (
            <div className="row">
              <div className="table-responsive" >
                {/* Conditional Rendering: Check if there are no tests */}
                {testByUser?.length === 0 ? (
                  <p className="text-center" >
                    You haven't taken any tests yet. Please attend the{" "}
                    <Link
                      to="/course"
                      style={{
                        color: "#1ABC9C",
                        fontWeight: "bold",
                      }}
                    >
                      <u>Skill Test</u>
                    </Link>{" "}
                    and check your test history.
                  </p>
                ) : (
                  <table className="table col-lg-12 col-sm-12 col-md-12">
                    <thead>
                      <tr>
                        <th>S.no</th>
                        <th onClick={() => handleSort("date")}>
                          Date {renderSortArrow("date")}
                        </th>
                        <th onClick={() => handleSort("module_title")}>
                          Module {renderSortArrow("module_title")}
                        </th>
                        <th onClick={() => handleSort("quesLevel")}>
                          Level {renderSortArrow("quesLevel")}
                        </th>

                        <th>Total question</th>
                        <th>Attempted</th>
                        <th onClick={() => handleSort("score")}>
                          Result {renderSortArrow("score")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedTestByUser?.map((ob, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{formatDate(ob?.date)}</td>
                          <td>{ob?.module_title}</td>
                          <td>{ob?.quesLevel}</td>

                          <td>{ob?.totalQ}</td>
                          <td>{ob?.totalQues.length}</td>

                          {/* <td>
                          {loginUser.role === "student" ? (
                            <button
                              className="btn btn-primary"
                              style={{
                                backgroundColor: "",
                                height: "auto",
                                width: "60px",
                                borderRadius: "10px",
                              }}
                            >
                              {ob.totalQues.reduce(
                                (x, ob) => (ob.isright === 1 ? x + 1 : x),
                                0
                              )}
                              /{ob?.totalQues.length}
                            </button>
                          ) : (
                            <Link to={"/History/" + ob.testid}>
                              <button
                                className="btn btn-secondary"
                                style={{
                                  backgroundColor: "",
                                  height: "auto",
                                  width: "60px",
                                  borderRadius: "10px",
                                }}
                              >
                                {ob.totalQues.reduce(
                                  (x, ob) => (ob.isright === 1 ? x + 1 : x),
                                  0
                                )}
                                /{ob?.totalQues.length}
                              </button>
                            </Link>
                          )}
                        </td> */}
                          <td>
                            <Link to={"/studentDetail/History/" + ob.testid}>
                              <button
                                className="btn btn-secondary"
                                style={{
                                  backgroundColor: "",
                                  height: "auto",
                                  width: "60px",
                                  borderRadius: "10px",
                                }}
                              >
                                {ob.totalQues.reduce(
                                  (x, ob) => (ob.isright === 1 ? x + 1 : x),
                                  0
                                )}
                                /{/* {ob?.totalQues.length} */}
                                {ob?.totalQ}
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
