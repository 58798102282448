// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your CSS file or inside a <style> tag */

.pp {
    max-width: 400px;
}

.small-profile-card {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}



.card-header {
    background-color: #1ABC9C;
    color: white;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    padding: 10px;
}

.card-body {
    padding: 15px;
}

.profile-info p {
    margin: 10px 0;
    font-size: 14px;
}

.profile-info p strong {
    font-weight: 600;
}

.profile-info p {
    display: flex;

  }
  .profile-info strong {
    min-width: 100px; 
  }
`, "",{"version":3,"sources":["webpack://./src/components/Profile.css"],"names":[],"mappings":"AAAA,sDAAsD;;AAEtD;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;AAC5C;;;;AAIA;IACI,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,4BAA4B;IAC5B,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;;EAEf;EACA;IACE,gBAAgB;EAClB","sourcesContent":["/* Add this to your CSS file or inside a <style> tag */\r\n\r\n.pp {\r\n    max-width: 400px;\r\n}\r\n\r\n.small-profile-card {\r\n    margin-top: 20px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 10px;\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n\r\n\r\n.card-header {\r\n    background-color: #1ABC9C;\r\n    color: white;\r\n    border-bottom: none;\r\n    border-radius: 10px 10px 0 0;\r\n    padding: 10px;\r\n}\r\n\r\n.card-body {\r\n    padding: 15px;\r\n}\r\n\r\n.profile-info p {\r\n    margin: 10px 0;\r\n    font-size: 14px;\r\n}\r\n\r\n.profile-info p strong {\r\n    font-weight: 600;\r\n}\r\n\r\n.profile-info p {\r\n    display: flex;\r\n\r\n  }\r\n  .profile-info strong {\r\n    min-width: 100px; \r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
