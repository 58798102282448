import React, { useEffect, useState } from "react";
import "./UseTemp6.css";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";

const UseTemp6 = () => {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  const downloadPDF = () => {
    const input = document.getElementById("resume-content6"); // Adjust ID if necessary

    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Scale ratio to fit canvas content within the PDF width
      const scaleRatio = pdfWidth / canvasWidth;
      const scaledHeight = canvasHeight * scaleRatio;

      const topPadding = 0;
      const bottomPadding = 0;

      // Adjust available page height to account for top and bottom padding
      const adjustedPageHeight = pdfHeight - topPadding - bottomPadding;

      // Break down content into multiple pages if needed
      let yOffset = 0;
      while (yOffset < canvasHeight) {
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(
          canvasHeight - yOffset,
          adjustedPageHeight / scaleRatio
        );

        const context = pageCanvas.getContext("2d");
        context.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add content to the PDF with top padding on each page
        pdf.addImage(
          pageData,
          "PNG",
          0,
          yOffset === 0 ? 0 : topPadding, // No top padding for the first page
          pdfWidth,
          (pageCanvas.height * pdfWidth) / canvas.width
        );

        yOffset += pageCanvas.height;

        if (yOffset < canvasHeight) pdf.addPage(); // Add a new page if more content remains
      }

      pdf.save("profile.pdf");
    });
  };
  return (
    <>
      <div className="container">
        <div className="button-container-6 row">
          <div className="col-12 col-sm-12 col-md-6">
            <button
              className="btn bk6 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <button className="btn db6 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>
        <div className="scroll-container6">
          <div className="container my-4 resume-6">
            <div id="resume-content6" className=" mainbox6 mx-auto shadow p-4">
              <section className="header6 text-center mb-4">
                <h2 className="upercase">{candidate?.name}</h2>
                <h5 className=" capitalize6">
                  {candidate?.candidates[0]?.job_title}
                </h5>
                <h5 className="tw">
                  <FontAwesomeIcon icon={faEnvelope} className="me-1" />{" "}
                  {candidate?.email} &nbsp;
                  <FontAwesomeIcon icon={faPhone} className="me-1 ms-1" />{" "}
                  {candidate?.contact} &nbsp;
                  {candidate?.candidates[0]?.linkedin_profile && (
                    <>
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className="me-1 ms-1"
                      />{" "}
                      {candidate?.candidates[0]?.linkedin_profile}&nbsp;
                    </>
                  )}
                  {candidate?.candidates[0]?.linkedin_profile && (
                    <>
                      <FontAwesomeIcon icon={faGithub} className="me-1 ms-1" />{" "}
                      {candidate?.candidates[0]?.github_profile}&nbsp;
                    </>
                  )}
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="me-1 ms-1"
                  />{" "}
                  {candidate?.candidates[0]?.address} &nbsp;
                </h5>
              </section>
              <hr className="hr6" />

              <div className="content6 row">
                <div className="col-md-8 temp6">
                  {candidate?.experience?.length > 0 && (
                    <section>
                      <h4 className="title text-info6">Experience</h4>
                      {candidate?.experience?.map((exp, index) => (
                        <div key={index}>
                          <h6 className="bold6">
                            {" "}
                            <b className="me-1">Designation:</b>
                            {exp?.designation}
                          </h6>
                          <h6>
                            {" "}
                            <b className="me-1">Company Name:</b>
                            {exp?.company_name}
                          </h6>
                          <h6>
                            {" "}
                            <b className="me-1">Duration:</b>
                            {exp.start_date} to {exp?.end_date || "Present"}
                          </h6>
                          <p className="pt6">
                            {" "}
                            <b className="me-1 bold">Description:</b>
                            {exp?.description}
                          </p>
                        </div>
                      ))}
                    </section>
                  )}

                  {candidate?.project?.length > 0 && (
                    <section>
                      <h4 className="title text-info6">Projects</h4>
                      {candidate?.project?.map((proj, index) => (
                        <div key={index}>
                          <h4>Project {index + 1}</h4>
                          <p className="subtitle6">
                            <b className="bold">Project Title: </b>{" "}
                            {proj?.project_title}
                          </p>
                          <p className="pt6">
                            <b className="bold">Description: </b>
                            {proj?.description}
                          </p>
                          <p className="pt6">
                            <b className="bold me-1">Duration in month:</b>{" "}
                            {proj?.duration_in_months} months
                          </p>
                          <p className="pt6">
                            {" "}
                            <b className="bold me-1">Skills Apply:</b>{" "}
                            {proj?.skill_apply}{" "}
                          </p>
                        </div>
                      ))}
                    </section>
                  )}
                </div>

                <div className="col-md-4 temp6">
                  {candidate?.candidates[0]?.skills && (
                    <section>
                      <h4 className="title text-info6">Skills</h4>
                      <ul className="ul6">
                        <li>{candidate?.candidates[0]?.skills}</li>
                      </ul>
                    </section>
                  )}
                  {candidate?.educational?.length > 0 && (
                    <section>
                      <h4 className="title text-info6">Education</h4>
                      {candidate?.educational?.map((edu, index) => (
                        <ul key={index} className="ul6">
                          <li>
                            <p className="pt6">
                              {" "}
                              <b className="me-1">Degree/Program: </b>{" "}
                              <span className="span6">
                                {edu?.education_name}
                              </span>
                            </p>

                            <p className="pt6">
                              <b className="me-1">Institute/University: </b>
                              <span className="span6">
                                {edu?.institute_name} /{" "}
                                {edu?.board_or_university}
                              </span>
                            </p>
                            <p className="pt6">
                              {" "}
                              <b className="me-1">Passing Year: </b>
                              <span className="span6">
                                {" "}
                                {edu?.passing_year}
                              </span>
                            </p>
                            <p className="pt6">
                              <b className="me-1">Grade:</b>
                              <span className="span6">
                                {edu?.percentage_or_grade}
                              </span>
                            </p>
                          </li>
                        </ul>
                      ))}
                    </section>
                  )}
                  {candidate?.candidates[0]?.language_known && (
                    <section>
                      <h4 className="title text-info6">Languages</h4>
                      <ul className="ul6">
                        <li>{candidate?.candidates[0]?.language_known}</li>
                      </ul>
                    </section>
                  )}
                  {candidate?.achievement?.length > 0 && (
                    <section>
                      <h4 className="title text-info6">Achievements</h4>
                      {candidate?.achievement?.map((ach, index) => (
                        <ul key={index} className="ul6">
                          <li>
                            {" "}
                            <b className="me-1">Title:</b>
                            {ach?.title}
                          </li>
                          <li>
                            {" "}
                            <b className="me-1">Description:</b>
                            {ach?.description}
                          </li>
                        </ul>
                      ))}
                    </section>
                  )}
                  {candidate?.candidates[0]?.interest && (
                    <section>
                      <h4 className="title text-info6">Interests</h4>
                      <ul className="ul6">
                        <li>{candidate?.candidates[0]?.interest}</li>
                      </ul>
                    </section>
                  )}

                  <section>
                    <h4 className="title text-info6">Personal Details</h4>
                    <ul className="ul6">
                      {candidate?.name && (
                        <li>
                          <b>Name:</b> {candidate?.name}
                        </li>
                      )}

                      {candidate?.candidates[0]?.father_name && (
                        <li>
                          <b>Father's Name:</b>{" "}
                          {candidate?.candidates[0]?.father_name}
                        </li>
                      )}

                      {candidate?.candidates[0]?.date_of_birth && (
                        <li>
                          <b>DOB:</b> {candidate?.candidates[0]?.date_of_birth}
                        </li>
                      )}

                      {candidate?.candidates[0]?.gender && (
                        <li>
                          <b>Gender:</b> {candidate?.candidates[0]?.gender}
                        </li>
                      )}

                      {candidate?.candidates[0]?.marital_status && (
                        <li>
                          <b>Marital Status:</b>{" "}
                          {candidate?.candidates[0]?.marital_status}
                        </li>
                      )}

                      {candidate?.candidates[0]?.nationality && (
                        <li>
                          <b>Nationality:</b>{" "}
                          {candidate?.candidates[0]?.nationality}
                        </li>
                      )}
                    </ul>
                  </section>
                </div>
              </div>

              <div className="footer6 mt-4">
                <p className="footer-text6">
                  Powered By{" "}
                  <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link6"
                  >
                    skillfresher.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseTemp6;
