// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.moduleitem {
  margin-top: 30px;
}

.courseitem:hover {
  cursor: pointer;
  border-style: none solid none none;
  border-color: blue;
}

.moduleitem .modal-header {
  text-align: center;
}

.btn-green {
  background-color: #1ABC9C !important;
  color: white !important;
}

.btn-green:hover{
  background-color: #2dceae !important;
  color: white !important;
}


`, "",{"version":3,"sources":["webpack://./src/components/module/ModuleItem.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;AACzB","sourcesContent":[".moduleitem {\r\n  margin-top: 30px;\r\n}\r\n\r\n.courseitem:hover {\r\n  cursor: pointer;\r\n  border-style: none solid none none;\r\n  border-color: blue;\r\n}\r\n\r\n.moduleitem .modal-header {\r\n  text-align: center;\r\n}\r\n\r\n.btn-green {\r\n  background-color: #1ABC9C !important;\r\n  color: white !important;\r\n}\r\n\r\n.btn-green:hover{\r\n  background-color: #2dceae !important;\r\n  color: white !important;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
