import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import "./UseTemp1.css";
import ResumeService, { urls } from "../../../services/ResumeService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UseTemp1 = () => {
const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;

  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const candidate = JSON.parse(localStorage.getItem("candidate"));

  const downloadPDF = () => {
    const input = document.getElementById("resume8");

    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      const scaleRatio = pdfWidth / canvasWidth;
      const scaledHeight = canvasHeight * scaleRatio;

      const topPadding = 6;
      const bottomPadding = 6;

      // Adjust available page height to account for top and bottom padding
      const adjustedPageHeight = pdfHeight - topPadding - bottomPadding;

      // Break down content into multiple pages if needed
      let yOffset = 0;
      while (yOffset < canvasHeight) {
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = Math.min(
          canvasHeight - yOffset,
          adjustedPageHeight / scaleRatio
        );

        const context = pageCanvas.getContext("2d");
        context.drawImage(
          canvas,
          0,
          yOffset,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          canvas.width,
          pageCanvas.height
        );

        const pageData = pageCanvas.toDataURL("image/png");

        // Add content to the PDF with top padding on each page
        pdf.addImage(
          pageData,
          "PNG",
          0,
          yOffset === 0 ? 0 : topPadding, // No top padding for the first page
          pdfWidth,
          (pageCanvas.height * pdfWidth) / canvas.width
        );

        yOffset += pageCanvas.height;

        if (yOffset < canvasHeight) pdf.addPage(); // Add a new page if more content remains
      }

      pdf.save("profile.pdf");
    });
  };

  return (
    <>

      <div className="container">
        <div className="button-container1 row">
          <div className="col-12 col-sm-12 col-md-6">
            <button
              className="btn bk8 back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <button className="btn  db8 download-button" onClick={downloadPDF}>
              Download as PDF
            </button>
          </div>
        </div>
        <div className="scroll-container8">
          <div className="container-fluid resume-container-8">
            <div className="row resume8" id="resume8">
              <div className="col-md-6 col-6 col-sm-6 resume-left8">
                <div className="profile-image8">
                  <img
                    src={
                      `https://backend.skilledfresher.in/upload/user_image/${candidate?.candidates[0]?.image}` ||
                      "/path/to/default-image.png"
                    }
                  />
                </div>
                <h2 className="name8">{candidate?.name}</h2>
                {candidate?.candidates?.[0]?.job_title && (
                  <h5 className="text-center tc8">
                    {candidate?.candidates[0]?.job_title}
                  </h5>
                )}
                <hr className="hr8" />
                <div className="resume-section8">
                  <h2 className="section-title6">Contact</h2>
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faLocationDot} className="icon8" />
                      <span> {candidate?.candidates[0]?.address}</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faPhone} className="icon8" />
                      <span>{candidate?.contact}</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faEnvelope} className="icon8" />
                      <span>{candidate?.email}</span>
                    </li>
                    {candidate?.candidates?.[0]?.linkedin_profile && (
                      <li>
                        <FontAwesomeIcon icon={faLinkedin} className="icon8" />
                        <span>
                          {" "}
                          {candidate?.candidates[0]?.linkedin_profile}
                        </span>
                      </li>
                    )}
                    {candidate?.candidates?.[0]?.github_profile && (
                      <li>
                        <FontAwesomeIcon icon={faGithub} className="icon8" />
                        <span> {candidate?.candidates[0]?.github_profile}</span>
                      </li>
                    )}
                  </ul>
                </div>

                <div className="resume-section8">
                  <h2 className="section-title6">Personal Info</h2>
                  <div className="pp">
                    {candidate?.candidates[0]?.father_name && (
                      <p className="p1 me-1 subheading8">
                        Father Name -{" "}
                        <span className="span1">
                          {candidate?.candidates[0]?.father_name}
                        </span>
                      </p>
                    )}
                    {candidate?.candidates[0]?.date_of_birth && (
                      <p className="p1 me-1 subheading8">
                        Date of Birth -{" "}
                        <span className="span1">
                          {candidate?.candidates[0]?.date_of_birth}
                        </span>
                      </p>
                    )}
                    {candidate?.candidates[0]?.gender && (
                      <p className="p1 me-1 subheading8">
                        Gender -{" "}
                        <span className="span1">
                          {candidate?.candidates[0]?.gender}
                        </span>
                      </p>
                    )}
                    {candidate?.candidates[0]?.marital_status && (
                      <p className="p1 me-1 subheading8">
                        Marital Status -{" "}
                        <span className="span1">
                          {candidate?.candidates[0]?.marital_status}
                        </span>
                      </p>
                    )}
                    {candidate?.candidates[0]?.nationality && (
                      <p className="p1 me-1 subheading8">
                        Nationality -{" "}
                        <span className="span1">
                          {candidate?.candidates[0]?.nationality}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                {candidate?.candidates[0]?.skills && (
                  <div className="resume-section8">
                    <h2 className="section-title6">Skills</h2>
                    <ul>
                      <li>{candidate?.candidates[0]?.skills}</li>
                    </ul>
                  </div>
                )}
                {candidate?.achievement?.length > 0 && (
                  <>
                    <div className="resume-section8">
                      <h2 className="section-title6">Achievements</h2>
                      <div className="pp">
                        <ul>
                          {candidate?.achievement?.map((item, index) => (
                            <li key={index} className="p1">
                              <div className="achievement-item">
                                <p>
                                  <b className="p1 me-1 subheading8">Title:</b>
                                  <span className="span1">{item.title}</span>
                                  <br />
                                  <b className="p1 me-1 subheading8">
                                    Description:
                                  </b>
                                  <span className="span1">
                                    {item.description}
                                  </span>
                                </p>
                                <hr className="hr3" />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                {candidate?.candidates[0]?.language_known && (
                  <div className="resume-section8">
                    <h2 className="section-title6">Language Known</h2>
                    <div className="pp">
                      <p className="p1">
                        <div className="title-section">
                          <label className="p1 subheading8">
                            language Known:
                          </label>
                          <span className="span1">
                            {" "}
                            {candidate?.candidates[0]?.language_known}
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>
                )}
                {candidate?.candidates[0]?.interest && (
                  <div className="resume-section8">
                    <h2 className="section-title6">Interest</h2>
                    <div className="pp">
                      <p className="p1">
                        <div className="title-section">
                          <label className="p1 subheading8">Interest:</label>
                          <span className="span1">
                            {" "}
                            {candidate?.candidates[0]?.interest}
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-6 col-6 col-sm-6 resume-right8">
                {candidate?.candidates[0]?.objective && (
                  <div className="resume-section8">
                    <h2 className="section-title8">Objective</h2>
                    <p className="p8"> {candidate?.candidates[0]?.objective}</p>
                  </div>
                )}
                {candidate?.experience?.length > 0 && (
                  <>
                    <div className="resume-section8">
                      <h2 className="section-title8">Experience</h2>
                      <div className="pp">
                        {candidate?.experience?.map((exp, index) => (
                          <div key={index}>
                            <p className="p1">
                              <b className="subheading8">
                                {exp?.start_date} to{" "}
                                {exp?.end_date || "Present"} ({exp.duration})
                              </b>
                            </p>
                            <p className="p1">
                              <b className="subheading8">{exp?.company_name}</b>{" "}
                              -{" "}
                              <span className="span1">{exp?.designation}</span>
                            </p>
                            <p className="p1">
                              <span className="span1">{exp?.description}</span>
                            </p>
                            <hr className="hr3" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {candidate?.educational?.length > 0 && (
                  <>
                    <div className="resume-section8">
                      <h2 className="section-title8">Education</h2>
                      <div className="pp pm1">
                        {candidate?.educational?.map((edu, index) => (
                          <div key={index}>
                            <p className="p1">
                              <b className="me-1 subheading8">
                                Degree/Program:{" "}
                              </b>{" "}
                              <span className="upercase ms-1 subheading8">
                                {" "}
                                {edu?.education_name}
                              </span>
                            </p>
                            <p className="p1">
                              <b className="me-1 subheading8">Passing Year: </b>{" "}
                              <span className="span1">{edu?.passing_year}</span>
                            </p>
                            <p className="p1">
                              <b className="me-1 subheading8">
                                Institute/University:{" "}
                              </b>
                              <span className="span1">
                                {edu?.institute_name} /{" "}
                                {edu?.board_or_university}
                              </span>
                            </p>
                            <p className="p1">
                              <b className="me-1 subheading8">
                                Percentage/Grade:{" "}
                              </b>
                              <span className="span1">
                                {edu?.percentage_or_grade}
                              </span>
                            </p>
                            <hr className="hr3" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {candidate?.project?.length > 0 && (
                  <>
                    <div className="resume-section8">
                      <h2 className="section-title8">Project</h2>
                      <div className="pp">
                        {candidate?.project?.map((project, index) => (
                          <div key={index}>
                            <p className="p1">
                              <b className="bold me-1 subheading8">
                                Project Title:{" "}
                              </b>{" "}
                              <b className="subheading8">
                                {project?.project_title}
                              </b>
                            </p>
                            <p className="p1">
                              <b className="bold me-1 subheading8">
                                Description:{" "}
                              </b>
                              <span className="span1">
                                {project.description}
                              </span>
                            </p>
                            <p className="p1">
                              <b className="bold me-1 subheading8">
                                Skills Apply:
                              </b>
                              <span className="span1">
                                {project?.skill_apply}
                              </span>
                            </p>
                            <p className="p1">
                              <b className="bold me- subheading8">
                                Duration in month:
                              </b>
                              <span className="span1">
                                {project?.duration_in_month} Months
                              </span>
                            </p>
                            <hr className="hr3" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="footer8 mt-4">
                <p className="footer-text8">
                  Powered By{" "}
                  <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link8"
                  >
                    skillfresher.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseTemp1;
