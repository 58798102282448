
import React, { useEffect, useState } from "react";
import AdminService from "../../services/AdminService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle, FaBan, FaTrashAlt } from "react-icons/fa";
import "./ViewUserDetails.css";

const HRUsersList = () => {
  const [hrUsers, setHrUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 const [searchValue, setSearchValue] = useState(""); 
  const [searchError, setSearchError] = useState(""); 
  const [showValidationMessage, setShowValidationMessage] = useState(false); 
  const loginUser = useSelector((state) => state.user.value);
    const navigate = useNavigate();

  useEffect(() => {
    fetchHRUsers();
  }, []);

  const fetchHRUsers = () => {
    setIsLoading(true);
    AdminService.getAllUser(loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        if (data?.user?.user) {
          const hrUsers = data.user.user.filter((user) => user.role === "hr");
          setHrUsers(hrUsers);
          setFilteredUsers(hrUsers);
        } else {
          setHrUsers([]);
          toast.error("Failed to fetch HR users.");
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setHrUsers([]);
        toast.error("Failed to fetch HR users.");
      });
  };


  const blockUser = (userid) => {
    const confirmBlock = window.confirm("Are you sure you want to block this user?");
    if (confirmBlock) {
      setIsLoading(true);
      const updatedRole = { isactive: 0 };
      AdminService.updateUserRole(updatedRole, userid, loginUser.token)
        .then((response) => response.json())
        .then(() => {
          setHrUsers((prevUsers) =>
            prevUsers.filter((user) => user.userid !== userid)
          );
          toast.success("User blocked successfully!");
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toast.error("Failed to block user.");
        });
    }
  };

  const delUser = (userid) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (confirmDelete) {
      setIsLoading(true);
      AdminService.dleteUser(userid, loginUser.token)
        .then((response) => {
          if (response.ok) {
            setHrUsers((prevUsers) =>
              prevUsers.filter((user) => user.userid !== userid)
            );
            toast.success("User deleted successfully!");
          } else {
            toast.error("Failed to delete user.");
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toast.error("Failed to delete user.");
        });
    }
  };


  const handleSearch = () => {
    if (!searchValue) {
      setSearchError("Please enter a valid name or email.");
      setFilteredUsers(hrUsers); 
      setShowValidationMessage(true);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z ]+$/;

    if (nameRegex.test(searchValue)) {
      setSearchError("");
      setShowValidationMessage(false);
    } else if (!emailRegex.test(searchValue)) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid email.");
      return;
    }


    setSearchError("");
    setShowValidationMessage(false);
    const filtered = hrUsers.filter(
      (user) =>
        user.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        user.email.toLowerCase().includes(searchValue.toLowerCase())
    );

    setFilteredUsers(filtered);
  };

 
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setSearchError("");
    setShowValidationMessage(false); 
  };
  const handleCancelSearch = () => {
    setSearchValue("");
    setIsLoading(true);
fetchHRUsers();

  };

  return (
    <div className="container">
      <ToastContainer position="top-center" autoClose={2000} />

      <div className="form-group row">
      <div className="row d-flex align-items-center justify-content-md-between justify-content-lg-between justify-content-center ">
        <div className="col-lg-2 col-2 col-md-2 col-sm-2">
          <button
            className="btn btn-md bk-adm back-button"
            onClick={() => navigate("/user")}
          >
            Back
          </button>
        </div>

        <div className="col-sm-10 col-10 col-lg-4 col-md-4  text-lg-center text-center">
          <h4 className="mb-0">Employer List</h4>
        </div>
        <div className="col-6 col-sm-6 col-lg-4 col-md-4 justify-content-center justify-content-sm-center justify-content-lg-end justify-content-md-end d-flex position-relative4 ">
        <div className="d-flex flex-column">
    <input
      type="text"
      className={`form-control ${searchError ? "is-invalid" : ""}`} 
      placeholder="Name or Email"
      value={searchValue}
      onChange={handleSearchChange}
    />
       {searchValue && (
              <span
                className={`clear-input-icon4 ${searchError ? "move-icon4" : ""
                  }`}
                onClick={handleCancelSearch}
              >
                <i className="fa fa-times" />
              </span>
            )}
   {showValidationMessage && searchError && (
    <small className="text-danger mt-1 validation-message text-center ">{searchError}</small>
  )}
    
    </div>
    <div className="col-sm-2 col-2 col-lg-2 col-md-2 p-1 ms-2">
<button
      className="btn btn-md btn-green"
      onClick={handleSearch}
    >
      Search
    </button>
</div>

</div>

      </div>
      </div>


      {isLoading ? (
        <div className="text-center">
          <span>Loading</span>{" "}
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
      ) : (
        <>
          {filteredUsers.length > 0 ? (
            <div className="table-responsive mt-5">
              <table className="table table-bordered text-center">
                <thead>
                  <tr className="text-center">
                    <th>S.no</th>
                    <th>Name</th>
                    <th>Education</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Role</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, index) => (
                    <tr key={user.userid}>
                      <td>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.education}</td>
                      <td>{user.contact}</td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      {/* <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-light dropdown-toggle"
                            type="button"
                            id={`dropdownMenuButton${index}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: 0,
                            }}
                          >
                            Action <i className="bi bi-three-dots"></i>
                          </button>
                          <ul
                            className="dropdown-menu dropdown-menu-end text-center"
                            aria-labelledby={`dropdownMenuButton${index}`}
                          >
                            <li className="my-1">
                              <button
                                className="dropdown-item d-flex align-items-center btn-sm text-center"
                                onClick={() => blockUser(user.userid)}
                                style={{
                                  backgroundColor: "#6c757d",
                                  color: "white",
                                  borderRadius: "10px",
                                  width: "90%",
                                  margin: "auto",
                                }}
                              >
                                <FaBan className="me-2" /> Block
                              </button>
                            </li>
                            <li className="my-1">
                              <button
                                className="dropdown-item d-flex align-items-center btn-sm text-center"
                                onClick={() => delUser(user.userid)}
                                style={{
                                  backgroundColor: "#dc3545",
                                  color: "white",
                                  borderRadius: "10px",
                                  width: "90%",
                                  margin: "auto",
                                }}
                              >
                                <FaTrashAlt className="me-2" /> Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-center">No HR users found.</p>
          )}
        </>
      )}
    </div>
  );
};

export default HRUsersList;

