import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Menu from "./components/Menu";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Course from "./components/course/Course";
import Module from "./components/module/Module";
import Question from "./components/question/Question";
import Success from "./components/Success";
import Register from "./components/register/Register";
import Login from "./components/Login";
import TestHistory from "./components/History/TestHistory";
import History from "./components/History/History";
import SaveCourse from "./components/admin/SaveCourse";
import SaveModules from "./components/admin/SaveModules";
import SaveQuestions from "./components/admin/SaveQuestions";
import SaveCourseModule from "./components/admin/SaveCourseModule";
import ViewQuestion from "./components/admin/ViewQuestion";
import ViewUserDetails from "./components/admin/ViewUserDetails";
import EmailVerify from "./components/register/EmailVerify";
import ViewUserTest from "./components/admin/ViewUserTest";
import ForgotPassword from "./components/ForgotPassword";
import UpdatePasword from "./components/UpdatePassword";
import Profile from "./components/Profile";
import ViewStudentDetails from "./components/hr/ViewStudentDetails";
import ViewStudentTest from "./components/hr/ViewStudentTest";
import AddFavorite from "./components/hr/AddFavorite";
import "./App.css";
import ResumeHome from "./components/Resume/ResumeHome";
import AddInfo from "./components/Resume/Page/AddInfo";
import ShowInfo from "./components/Resume/Page/Information";
import Education from "./components/Resume/Page/Education";
import Experience from "./components/Resume/Page/Experience";
import Templates from "./components/Resume/Page/Templates";
import ProjectsDetails from "./components/Resume/Page/Projects";
import Achievement from "./components/Resume/Page/Achievements";
import SOthers from "./components/Resume/Page/Others";
import UseTemp1 from "./components/Resume/Template/UseTemp1";
import UseTemp2 from "./components/Resume/Template/UseTemp2";
import UseTemp3 from "./components/Resume/Template/UseTemp3";
import UseTemp4 from "./components/Resume/Template/UseTemp4";
import UseTemp5 from "./components/Resume/Template/UseTemp5";
import UseTemp6 from "./components/Resume/Template/UseTemp6";
import UseTemp7 from "./components/Resume/Template/UseTemp7";
import UseTemp9 from "./components/Resume/Template/UseTemp9";
import UseTemp10 from "./components/Resume/Template/UseTemp10";
import { NetworkStatusProvider } from "./components/NetworkContext";
import BlockedUsers from "./components/admin/BlockedUser";
import ShortlistedUsers from "./components/admin/ShortlistedUsers";
import HrRegister from "./components/register/HrRegister";
import HRUsersList from "./components/admin/HRUsersList";
import HrShortlistedCandidates from "./components/hr/HrShortlistedCandidates";


// Custom PrivateRoute component

const App = () => {
  const isUserAuthenticated = useSelector((state) => state.user.value.islogin);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top whenever the location changes
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling
    });
  }, [location]);
  return (
    <div >
      <NetworkStatusProvider>
        <Menu />
        {/* <GlobalMessage></GlobalMessage> */}
        <div
        // style={{ marginTop: "80px" }}
        >
          
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/register" element={<Register />} />
            <Route path="/emailverify" element={<EmailVerify />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />

            <Route path="/course" element={<Course />}>
              {isUserAuthenticated && (
                <>
                  <Route path="module/:id" element={<Module />} />
                  <Route
                    path="question/:moduleId/:quesLevel"
                    element={<Question />}
                  />
                  <Route path="saveCourse" element={<SaveCourse />} />
                  <Route path="saveModules" element={<SaveModules />} />
                  <Route path="saveQuestions" element={<SaveQuestions />} />
                  <Route
                    path="saveCourseModules"
                    element={<SaveCourseModule />}
                  />
                  <Route path="questionsList" element={<ViewQuestion />} />
                  <Route
                    path="saveQuestions/:ques_id"
                    element={<SaveQuestions />}
                  />
                   <Route path="addhr" element={<HrRegister/>}/>
                  <Route path="success" element={<Success />} />
               
                </>
                
              )}
            </Route>

            {isUserAuthenticated && (
              <>

                <Route path="/testhistory" element={<TestHistory />} />
                <Route path="/usertest/:userid" element={<ViewUserTest />} />
                <Route path="/user" element={<ViewUserDetails />} >
                </Route>
                <Route path="/changePassword" element={<UpdatePasword />} />
                <Route path="/myProfile" element={<Profile />} />
                <Route path="/block" element={<BlockedUsers/>}/>
                  <Route path="/shortlist" element={<ShortlistedUsers/>}/>
                  <Route path="/hrlist" element={<HRUsersList/>}/>

  
              </>
            )}
            {isUserAuthenticated && (
              <>
                <Route path="/studentDetail" element={<ViewStudentDetails />}>
                  <Route
                    path="StudentTestRecord/:userid"
                    element={<ViewStudentTest />}
                  />
                  <Route path="addFavorite" element={<AddFavorite />} />
                  <Route path="history/:testid" element={<History />} />
              
                  
                </Route>{" "}
                <Route path="hrshortlist" element={<HrShortlistedCandidates />} />
              </>
            )}
            <Route path="/resumehome" element={<ResumeHome />}>
              <Route path="addinfo" element={<AddInfo />} />
              <Route path="information" element={<ShowInfo />} />
              <Route path="education" element={<Education />} />
              <Route path="experience" element={<Experience />} />
              <Route path="project" element={<ProjectsDetails />} />
              <Route path="templates" element={<Templates />} />
              <Route path="achievements" element={<Achievement />} />
              <Route path="others" element={<SOthers />} />
              <Route path="use1" element={<UseTemp1 />} />
              <Route path="use2" element={<UseTemp2 />} />
              <Route path="use3" element={<UseTemp3 />} />
              <Route path="use4" element={<UseTemp4 />} />
              <Route path="use5" element={<UseTemp5 />} />
              <Route path="use6" element={<UseTemp6 />} />
              <Route path="use7" element={<UseTemp7 />} />
              <Route path="use8" element={<UseTemp9 />} />
              <Route path="use9" element={<UseTemp10 />} />
            </Route>
          </Routes>
        </div>
    
      </NetworkStatusProvider>
    </div>
  );
};

export default App;
