// import axios from 'axios';
// import { useNetworkStatus } from '../components/NetworkContext'; // Adjust the path if needed

// // Create Axios instance
// const axiosInstance = axios.create({
//   baseURL: 'http://46.28.44.188:7074/', // Replace with your base API URL
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// // Axios request interceptor to check network status before API call
// axiosInstance.interceptors.request.use(
//     async (config) => {
//       const { isOnline, setNetworkErrorMessage } = useNetworkStatus();

//       if (!isOnline) {
//         // If offline, show network error and prevent request from being sent
//         setNetworkErrorMessage('You are offline. Please check your internet connection.');
//         return Promise.reject(new Error('No internet connection'));
//       }

//       // Continue with the request if online
//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );

// // Axios response interceptor to handle any network errors after the API call
// axiosInstance.interceptors.response.use(
//   (response) => {
//     // Return response if the request is successful
//     return response;
//   },
//   (error) => {
//     const { setNetworkErrorMessage } = useNetworkStatus();

//     if (error.message === 'No internet connection') {
//       // If it's an offline error, display the message
//       setNetworkErrorMessage('You are offline. Please check your internet connection.');
//     } else {
//       // Handle other errors (e.g., server errors, etc.)
//       setNetworkErrorMessage('There was a problem with the network request. Please try again.');
//     }

//     return Promise.reject(error);
//   }
// );

// // Export axios instance for use in your components/services
// export default axiosInstance;

import axios from "axios";
import { getNetworkStatus } from "../components/NetworkStatus"; // Import the utility to get the network status

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: "http://46.28.44.188:7074/", // Replace with your base API URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Axios request interceptor to check network status before API call
axiosInstance.interceptors.request.use(
  async (config) => {
    const isOnline = getNetworkStatus(); // Get network status from global utility

    if (!isOnline) {
      // If offline, show network error and prevent request from being sent
      // Handle network error (could show a global error message here)
      return Promise.reject(new Error("No internet connection"));
    }

    // Continue with the request if online
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios response interceptor
axiosInstance.interceptors.response.use(
  (response) => response, // Return the response if successful
  (error) => {
    // You can add additional error handling here if needed
    return Promise.reject(error);
  }
);

// Export axios instance for use in your components/services
export default axiosInstance;
