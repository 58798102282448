import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Contact extends Component {
  render() {
    return (
      <div>
        <section className="contact py-5 mt-5" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mr-lg-5 col-12">
                <div className="google-map w-100">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3679.2740175592903!2d75.89682!3d22.75521!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302af403406fb%3A0x5b50834b117f8bab!2sVijay%20Nagar%2C%20Scheme%20No%2054%2C%20Indore%2C%20Madhya%20Pradesh%20452010!5e0!3m2!1sen!2sin!4v1735030873288!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    title="CodeBetter"
                  ></iframe>
                </div>

                <div className="contact-info d-flex justify-content-between align-items-center py-4 px-lg-5">
                  <div className="contact-info-item">
                    <h3 className="mb-3 text-white">Say hello</h3>
                    <p className="footer-text mb-0">010 020 0960</p>
                    <p>
                      <Link to="mailto:contact@codebetter.in">
                        SkilledFresher.in
                      </Link>
                    </p>
                  </div>

                  <ul className="social-links">
                    <li>
                      <Link
                        to="#"
                        className="uil uil-dribbble"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Dribbble"
                      ></Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="uil uil-instagram"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Instagram"
                      ></Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="uil uil-youtube"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Youtube"
                      ></Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="contact-form">
                  <h2 className="mb-4">
                    Interested to work together? Let's talk
                  </h2>

                  <form action="" method="get">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Your Name"
                          id="name"
                        />
                      </div>

                      <div className="col-lg-6 col-12">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          id="email"
                        />
                      </div>

                      <div className="col-12">
                        <textarea
                          name="message"
                          rows="6"
                          className="form-control"
                          id="message"
                          placeholder="Message"
                        ></textarea>
                      </div>

                      <div className="ml-lg-auto col-lg-5 col-12">
                        <input
                          type="submit"
                          className="form-control submit-btn"
                          value="Send Button"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
