import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import ResumeService, { urls } from "../../../services/ResumeService";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Achievement() {
  const logindata = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [achievementList, setAchievementList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [joiningDate, setJoiningDate] = useState("");
  const [achievementId, setAchievementId] = useState(undefined);

  const titleRef = useRef();
  const descriptionRef = useRef();
  const fetchAchievementList = async () => {
    try {
      setLoading(true);
      const fetchUrl = `${urls.Achievement_List}`;

      const response = await ResumeService.GetApiCallWithToken(
        fetchUrl,
        logindata.token
      );

      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data.length > 0
      ) {
      } else {
      }

      if (response?.data?.data) {
        const achievements = response?.data?.data;
        setAchievementList(achievements);

        achievements.forEach((achievement) => {});
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAchievementList();
  }, [logindata.token]);

  const handleAchievementSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    const achievementDetails = {
      person: logindata.userId,
      title: titleRef.current?.value || "",
      description: descriptionRef.current?.value || "",
    };

    if (!achievementDetails.title.trim())
      errors.title = "Title name field is required";

    if (!achievementDetails.description.trim())
      errors.description = "Description field is required";

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        toast.dismiss();
        let response;
        let updateData = [...achievementList];
        if (isEditMode && editIndex !== null) {
          response = await ResumeService.PutApiCall(
            `${urls.Achievement_Update}/${achievementId}`,
            achievementDetails,
            logindata.token
          );
          fetchAchievementList();
          updateData[editIndex] = achievementDetails;
        } else {
          response = await ResumeService.PostApiCallWithToken(
            urls.Achievement_Add,
            achievementDetails,
            logindata.token
          );
          updateData = [...updateData, response?.data?.achievement];
          fetchAchievementList();
        }
        setAchievementList(updateData);
        toast.success(
          response?.data?.message || "Achievement details saved successfully"
        );
        titleRef.current.value = "";
        descriptionRef.current.value = "";
        setIsEditMode(false);
        setEditIndex(null);

        localStorage.setItem(
          "Achievement Data",
          JSON.stringify(achievementDetails)
        );

        setShowForm(false);

        setTimeout(() => {
          setShowForm(true);
        }, 2000);
      } catch (error) {
      } finally {
        setLoading(false);
        setFormErrors({});
        setIsFormSubmitted(true);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleEdit = (index) => {
    setIsEditMode(true);
    setEditIndex(index);
    const selectedAchievement = achievementList[index];
    titleRef.current.value = selectedAchievement.title;
    descriptionRef.current.value = selectedAchievement.description;
    setAchievementId(selectedAchievement.achievements_id);
  };

  const handleDelete = async (achievements_id) => {
    if (!achievements_id) {
      return;
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this achievement entry?"
    );

    if (isConfirmed) {
      toast.dismiss();
      try {
        setLoading(true);
        const deleteUrl = `${urls.Achievement_Delete}/${achievements_id}`;
        const response = await ResumeService.DelApiCall(
          deleteUrl,
          logindata.token
        );

        if (response?.status === 200) {
          const updatedList = achievementList.filter(
            (achievement) => achievement.achievements_id !== achievements_id
          );
          setAchievementList(updatedList);
          localStorage.setItem("achievementData", JSON.stringify(updatedList));

          toast.success(
            response.data.message || "Achievement entry deleted successfully"
          );
          titleRef.current.value = "";
          descriptionRef.current.value = "";
          setIsEditMode(false);
          setEditIndex(null);
          setFormErrors({});
          setAchievementId(undefined);

          setTimeout(() => {
            setMsg("");
          }, 2000);
        } else {
          toast.error(
            response.data.message ||
              "Error deleting Achievement. Please try again."
          );
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnHover
        draggable
        theme="light"
      />
      <div className="container-fluid">
        <div className="row">
          {/* Sidebar */}
          <div className="col-lg-2 col-2 col-sm-2 col-md-2 mb-3">
            <Sidebar />
          </div>

          {/* Main Content */}
          <div className="col-lg-8 col-8 col-sm-8 offset-3 col-md-10">
            <div className="row">
              {/* Table Section */}
              {achievementList.length > 0 && (
                <div className="col-lg-5 col-md-5">
                  <div className="border border-success rounded p-4 bg-light shadow">
                    {/* <h2 className="text-center">Saved Achievement</h2> */}
                    {/* <hr /> */}
                    <div className="table-responsive table-responsive-resume">
                      <table className="table table-striped">
                        <tbody>
                          {achievementList.map((achievement, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <th className="text-left">Title</th>
                                <td className="text-left">
                                  {achievement.title}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Description</th>
                                <td className="text-left">
                                  {achievement.description}
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={2}>
                                  <div className="d-flex justify-content-between">
                                    <button
                                      className="btn btn-md  btn-danger  mt-3"
                                      onClick={() =>
                                        handleDelete(
                                          achievement.achievements_id
                                        )
                                      }
                                    >
                                      Delete
                                    </button>
                                    <button
                                      className="btn btn-md  btn-success mt-3"
                                      onClick={() => handleEdit(index)}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr className="spacer-row" />
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {/* Form Section */}
              <div 
              // className="col-lg-6 offset-lg-1 col-md-5 mi"
              className={`col-lg-${
                achievementList.length > 0 ? "6" : "6"
              } col-md-${achievementList.length > 0 ? "5" : "6"} offset-lg-${
                achievementList.length > 0 ? "1" : "3"
              } mi`}
              >
                <div className="border border-success rounded p-4 bg-light shadow mb-4">
                  <h3 className="text-center">
                    {isEditMode ? "Edit Achievement" : "Add New Achivement"}
                  </h3>
                  <hr />

                  <form onSubmit={handleAchievementSubmit}>
                    <div className="form-group">
                      <label htmlFor="company Name">
                        Title: <span className="text-danger">*</span>
                      </label>
                      <input
                        name="company Name"
                        className="form-control form-control-bg offwhite-input"
                        ref={titleRef}
                        maxLength={100}
                        required
                        placeholder="ex. - Developed an AI-based chatbot"
                      />
                      <small className="text-danger">{formErrors.title}</small>
                    </div>

                    <div className="form-group">
                      <label htmlFor="designation">
                        Description: <span className="text-danger">*</span>
                      </label>
                      <textarea
                        name="designation"
                        className="form-control form-control-bg offwhite-input"
                        ref={descriptionRef}
                        maxLength={5000}
                        required
                        placeholder="ex - Developed a Python chatbot that boosted efficiency."
                      />
                      <small className="text-danger">
                        {formErrors.description}
                      </small>
                    </div>

                    <button className="btn btn-md  btn-success mt-3">
                      {isEditMode ? "Update" : "Save"}
                    </button>
                    {isEditMode && (
                      <button
                        className="btn btn-md btn-secondary mt-3 ms-2"
                        type="button"
                        onClick={() => {
                          // Clear form fields
                          titleRef.current.value = "";
                          descriptionRef.current.value = "";
                          // Reset states
                          setIsEditMode(false);
                          setEditIndex(null);
                          setAchievementId(undefined);
                          setFormErrors({});
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Achievement;
