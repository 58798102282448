import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quess: [{ ques: undefined }], // Initial state for ques
  answers: {},
};

const quesSlice = createSlice({
  name: "ques",
  initialState,
  reducers: {
    setQues: (state, action) => {
      state.quess = action.payload;
    },
  },
});

export const { setQues } = quesSlice.actions;
export default quesSlice.reducer;
