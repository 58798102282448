
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminService from "../../services/AdminService"; 
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import ResumeService, { urls } from "../../services/ResumeService";
import SearchInput from "react-search-input";
import "./Admin.css";

const ShortlistedUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [shortlistedCandidates, setShortlistedCandidates] = useState([]);
  const [searchValue, setSearchValue] = useState(""); 
  const [searchError, setSearchError] = useState(""); 
  const [showValidationMessage, setShowValidationMessage] = useState(false); 
  const loginUser = useSelector((state) => state.user.value);
  const navigate = useNavigate();

  const fetchUsers = () => {
    setIsLoading(true);
    AdminService.getAllUser(loginUser.token)
      .then((response) => response.json())
      .then((data) => {
        // console.log("API Response: ", data);
    
        const shortlistedUsers = data?.user?.user
          .filter((user) => user?.Candidateinfo?.shortlisted === true) 
          .map((user) => user);

        // console.log("Shortlisted users:", shortlistedUsers);
        setShortlistedCandidates(shortlistedUsers);
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching users:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers(); 
  }, [loginUser.token]);

  const unshortlistUser = async (candidate) => {
    // console.log("candidate", candidate);
    let updatedStatus;
    const confm = window.confirm("Are you sure you want to unshortlist this candidate?");
    if (confm) {
      if (!candidate) {
        toast.error("No information found for this user.");
      } else {
        updatedStatus = { shortlisted: false };
      }
  
      // console.log("update status", updatedStatus);
      setIsLoading(true);
      // console.log(`Updating shortlisted status for userId: ${candidate.userid}`);
  
      try {
        const api = `${urls.Update_ShortListed}/${candidate.userid}`;
        const response = await ResumeService.PutApiCallWithJson(api, updatedStatus, loginUser.token);
        
        if (response.status === 200) {
          setShortlistedCandidates((prevCandidates) =>
            prevCandidates.filter((item) => item.userid !== candidate.userid)
          );
          toast.success("User has been unshortlisted!");
        } else {
          toast.error("Failed to unshortlist the user.");
        }
      } catch (error) {
        // console.error("Error unshortlisting user:", error);
        toast.error("Error unshortlisting the user.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSearch = () => {
    if (!searchValue) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid name or email.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z ]+$/;

    if (nameRegex.test(searchValue)) {
      setSearchError("");
    } else if (!emailRegex.test(searchValue)) {
      setShowValidationMessage(true);
      setSearchError("Please enter a valid email.");
      return;
    }

    setSearchError(""); 

    const searchResults = shortlistedCandidates.filter(
      (candidate) =>
        candidate?.role === "student" &&
        (candidate?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          candidate?.email.toLowerCase().includes(searchValue.toLowerCase()))
    );

    if (searchResults.length === 0) {
      toast.error("No records found.");
    }
 
  };

  const handleCancelSearch = () => {
    setSearchValue("");
    setIsLoading(true);
    fetchUsers();

  };

  return (
    <div className="container">
      <ToastContainer position="top-center" autoClose={2000} />
      <div className="row d-flex align-items-center justify-content-md-between justify-content-lg-between justify-content-center mb-5">
        <div className="col-lg-2 col-2 col-md-2 col-sm-2">
          <button
            className="btn btn-md bk-adm back-button"
            onClick={() => navigate("/user")}
          >
            Back
          </button>
        </div>

        <div className="col-sm-10 col-10 col-lg-5 col-md-4  text-lg-end text-md-end">
          <h4 className="mb-0">My Shortlisted Candidates</h4>
        </div>
        <div className="col-6 col-sm-6 col-lg-5 col-md-5 justify-content-center justify-content-sm-center justify-content-lg-end justify-content-md-end d-flex position-relative4 mt-3">
        <div className="d-flex flex-column">
    <input
      type="text"
      className={`form-control ${searchError ? "is-invalid" : ""}`} 
      placeholder="Name or Email"
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
        if (searchError) {
          setSearchError("");
          setShowValidationMessage(false);
        }
      }}
    />
       {searchValue && (
              <span
                className={`clear-input-icon4 ${searchError ? "move-icon4" : ""
                  }`}
                onClick={handleCancelSearch}
              >
                <i className="fa fa-times" />
              </span>
            )}
   {showValidationMessage && searchError && (
    <small className="text-danger mt-1 validation-message text-center ">{searchError}</small>
  )}
    
    </div>
    <div className="col-sm-3 col-2 col-lg-2 col-md-3 p-1 ms-2">
<button
      className="btn btn-md btn-green"
      onClick={handleSearch}
    >
      Search
    </button>
</div>

</div>

      </div>

      {isLoading ? (
        <div className="text-center">
          <span>Loading</span>{" "}
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
      ) : shortlistedCandidates?.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="text-center">
              <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Education</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {shortlistedCandidates.map((candidate, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{candidate?.name}</td>
                  <td>{candidate?.education}</td>
                  <td>{candidate?.contact}</td>
                  <td>{candidate?.email}</td>
                  <td>{candidate?.role}</td>
                  <td className="d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-md text-white d-flex align-items-center rounded-3 p-2 shadow-sm justify-content-center"
                      style={{
                        backgroundColor: "#1ABC9C",
                      }}
                      onClick={() => unshortlistUser(candidate)}
                    >
                      <FaStar className="me-2" />
                      UnShortlist
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center">No shortlisted candidates found.</div>
      )}
    </div>
  );
};

export default ShortlistedUsers;


