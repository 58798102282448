
import axios from "axios";
import axiosInstance from "./AxiosService";

class Apiservice {
  // Retrieves token from localStorage
  getToken() {
    return localStorage.getItem("token"); // Replace 'yourTokenKey' with the actual key used in localStorage
  }

  PostApiCall(url, data) {
    return axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  PostApiCallWithToken(url, data) {
    const token = this.getToken();
    // console.log(token);
    return axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  GetApiCallWithToken(url) {
    const token = this.getToken();
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }

  DelApiCall(url) {
    const token = this.getToken();
    return axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  PutApiCall(url, data, token) {
    // const token = this.getToken();
    return axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }
  PutApiCallWithJson(url, data, token) {
    // const token = this.getToken();
    return axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  PatchApiCall(url, data, token) {
    // const token = this.getToken();
    return axios.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }

}

const SERVER = `https://backend.skilledfresher.in`;


export const urls = {
  Add_Info: `${SERVER}/api/candidate/addinfo`,
  Show_Info: `${SERVER}/api/candidate/list`,
  Update_Info: `${SERVER}/api/candidate/updateinfo`,
  Add_Education: `${SERVER}/api/education/add`,
  Education_list: `${SERVER}/api/education/mylist`,
  Del_Education: `${SERVER}/api/education/delete`,
  Update_Education: `${SERVER}/api/education/update`,
  Experience_Add: `${SERVER}/api/experience/add`,
  Experience_List: `${SERVER}/api/experience/mylist`,
  Experience_Update: `${SERVER}/api/experience/update`,
  Experience_Delete: `${SERVER}/api/experience/delete`,
  Project_Add: `${SERVER}/api/project/add`,
  Project_List: `${SERVER}/api/project/mylist`,
  Project_Update: `${SERVER}/api/project/update`,
  Project_Delete: `${SERVER}/api/project/del`,
  Achievement_Add: `${SERVER}/api/achieve/add`,
  Achievement_List: `${SERVER}/api/achieve/mylist`,
  Achievement_Update: `${SERVER}/api/achieve/update`,
  Achievement_Delete: `${SERVER}/api/achieve/del`,
  Candidate_Details: `${SERVER}/api/candidate/details`,
  Update_ShortListed:`${SERVER}/api/admin/update-shortlisted`,
  Update_template:`${SERVER}/api/candidate/updateTemplate`,
  Update_Others: `${SERVER}/api/candidate/updateOthers`,
};

export default new Apiservice();
