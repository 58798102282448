// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg h1 {
    text-align: center;
}

.msg h2 {
    text-align: center;
}

.msg p {
    text-align: center;
}

a {
    font-weight: normal;
    text-decoration: none !important;
    transition: all 0.4s ease;
    color: red
}
`, "",{"version":3,"sources":["webpack://./src/components/Success.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,gCAAgC;IAChC,yBAAyB;IACzB;AACJ","sourcesContent":[".msg h1 {\r\n    text-align: center;\r\n}\r\n\r\n.msg h2 {\r\n    text-align: center;\r\n}\r\n\r\n.msg p {\r\n    text-align: center;\r\n}\r\n\r\na {\r\n    font-weight: normal;\r\n    text-decoration: none !important;\r\n    transition: all 0.4s ease;\r\n    color: red\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
