import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Sidebar.css";
import { FaUserCircle } from "react-icons/fa";
import {
  CDBSidebar,
  CDBSidebarHeader,
  CDBSidebarMenuItem,
  CDBSidebarContent,
  CDBSidebarMenu,
} from "cdbreact";
import {
  FaArrowRight,
  FaBars,
  FaArrowLeft,
  FaInfoCircle,
  FaGraduationCap,
  FaBriefcase,
  FaProjectDiagram,
  FaTrophy,
  FaEllipsisH,
  FaFileAlt,
} from "react-icons/fa";
import { useSelector } from "react-redux";

const Sidebar = ({ setActiveMenu }) => {
const SERVER = process.env.REACT_APP_SKILLEDFRESHER_SERVER;

  const candidateInfo = JSON.parse(localStorage.getItem("CandidateInfo"));
  const profileImage = useSelector((state) => state.profile.profileImage);
  const loginUser = useSelector((state) => state.user.value);

  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="sticky-sidebar">
      <CDBSidebar
        textColor="#FFFFFF"
        backgroundColor="#1ABC9C"
        collapsed={!sidebarOpen}
      >
        <CDBSidebarHeader
          prefix={
            <i
              className={sidebarOpen ? "fa fa-arrow-left" : "fa fa-arrow-right"}
              onClick={toggleSidebar}
            />
          }
        >
          <div className="sidebar-header-content">
            {profileImage || candidateInfo?.data?.data?.image ? (
              <img
                src={
                  profileImage
                    ? profileImage
                    : `https://backend.skilledfresher.in/upload/user_image/${candidateInfo?.data?.data?.image}`
                }
                // alt="User Profile"
                className="user-image"
              />
            ) : (
              <FaUserCircle className="user-image" />
            )}
            <b className="username">{loginUser.username}</b>
          </div>
        </CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            <NavLink
              to="/resumehome/Information"
              className="sidebar-link"
              activeClassName="activeClicked active"
            >
              <CDBSidebarMenuItem icon="info-circle">
                Information
              </CDBSidebarMenuItem>
              {/* <FaArrowRight className="arrow-icon" /> */}
            </NavLink>
            <NavLink
              to="/resumehome/education"
              className="sidebar-link"
              activeClassName="activeClicked active"
            >
              <CDBSidebarMenuItem icon="graduation-cap">
                Education
              </CDBSidebarMenuItem>
              {/* <FaArrowRight className="arrow-icon" /> */}
            </NavLink>
            <NavLink
              to="/resumehome/experience"
              className="sidebar-link"
              activeClassName="activeClicked active"
            >
              <CDBSidebarMenuItem icon="briefcase">
                Experience
              </CDBSidebarMenuItem>
              {/* <FaArrowRight className="arrow-icon" /> */}
            </NavLink>
            <NavLink
              to="/resumehome/project"
              className="sidebar-link"
              activeClassName="activeClicked active"
            >
              <CDBSidebarMenuItem icon="laptop-code">
                Projects
              </CDBSidebarMenuItem>
              {/* <FaArrowRight className="arrow-icon" /> */}
            </NavLink>
            <NavLink
              to="/resumehome/achievements"
              className="sidebar-link"
              activeClassName="activeClicked active"
            >
              <CDBSidebarMenuItem icon="trophy">
                Achievements
              </CDBSidebarMenuItem>
              {/* <FaArrowRight className="arrow-icon" /> */}
            </NavLink>
            <NavLink
              to="/resumehome/others"
              className="sidebar-link"
              activeClassName="activeClicked active"
            >
              <CDBSidebarMenuItem icon="folder">Others</CDBSidebarMenuItem>
              {/* <FaArrowRight className="arrow-icon" /> */}
            </NavLink>
            <hr />
            <NavLink
              to="/resumehome/templates"
              className="sidebar-link"
              activeClassName="activeClicked active"
            >
              <CDBSidebarMenuItem icon="file-alt">Templates</CDBSidebarMenuItem>
              {/* <FaArrowRight className="arrow-icon" /> */}
            </NavLink>
          </CDBSidebarMenu>

          {/* Add your sidebar content here */}
          <div className="sidebar-content">
            <div style={{ height: "50px" }}></div>
          </div>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
